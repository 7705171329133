import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardFlex,
  CardHeader,
  Title,
} from "../../components/Card/Card.style";
import { Button } from "@mui/material";
import ViewTable from "../../components/ViewTable/ViewTable";
import AddIcon from "@mui/icons-material/Add";
import SearchBar from "../../components/SearchBar/SearchBar";
import Form from "./Form/Form";
import {
  getCompanies,
  deleteCompany,
  updateCompanyAdvertisment,
} from "../../../redux/actions/company";
import { useDispatch, useSelector } from "react-redux";
import { getServices } from "../../../redux/actions/service";
import { confirmation, generalConfirmation } from "../../../utils/notification";
import { getEquipmentBrands } from "../../../redux/actions/equipmentBrand";
import CsvUpload from "./CSVUpload/CsvUpload";
import {
  MagnifyingGlassIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { CiBullhorn } from "react-icons/ci";
import Table from "../../components/Table/Table";
// import { updateCompanyAdvertisment } from "../../../redux/api";

const Companies = () => {
  const initialSort = {
    name: 0,
    entityName: 0,
    description: 0,
    contacts: 0,
    offices: 0,
    services: 0,
  };
  const initialFilter = {
    searchText: "",
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { companies, count, loading } = useSelector(
    (state) => state.companyReducer
  );

  const columns = [
    {
      header: "Name",
      fieldName: "name",
      render: (company) => company.name,
      sortable: true,
    },
    {
      header: "Entity Name",
      fieldName: "entityName",
      render: (company) => company.entityName,
      sortable: true,
    },
    {
      header: "Description",
      fieldName: "description",
      render: (company) => company.description,
      sortable: true,
    },
    {
      header: "Actions",
      render: (company) => (
        <div className="flex justify-center gap-3">
          <button
            onClick={() => {
              generalConfirmation(
                "Advertise",
                `Are you sure you want to ${
                  company.advertise ? "remove" : "advertise"
                } this company?`,
                `${company.advertise ? "Yes, remove" : "Yes, advertise"}`
              ).then(async (result) => {
                if (result.isConfirmed) {
                  const payload = { advertise: !company.advertise };
                  // const updatedCompany = await updateCompanyAdvertisment(
                  //   company._id,
                  //   payload
                  // );
                  // if (updatedCompany.status === 200) {
                  //   company.advertise = updatedCompany.data.advertise;
                  // }
                  dispatch(updateCompanyAdvertisment(company._id, payload));
                }
              });
            }}
          >
            <CiBullhorn
              className={`h-5 w-5 text-gray-400 ${
                company.advertise ? "text-green-600 bg-slate-200" : ""
              }`}
              aria-hidden="true"
            />
          </button>

          <button
            onClick={() => {
              navigate(`/companies/update/${company._id}`);
            }}
          >
            <PencilIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
          <button onClick={() => handleDelete(company._id)}>
            <TrashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
        </div>
      ),
    },
  ];

  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setID] = useState(null);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState(initialSort);
  const [filter, setFilter] = useState(initialFilter);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [advertise, setAdvertise] = useState({});
  const [itemOffset, setItemOffset] = React.useState(0);

  const pageCount = Math.ceil(count / itemsPerPage);
  const toggleAdvertise = (id) => {
    setAdvertise((prev) => ({ ...prev, [id]: !prev[id] }));
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (id) => {
    confirmation().then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCompany(id));
      }
    });
  };

  useEffect(() => {
    dispatch(getServices());
    dispatch(getEquipmentBrands());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getCompanies(filter, sort, {
        skip: itemOffset,
        limit: itemsPerPage,
      })
    );
  }, [dispatch, itemOffset, itemsPerPage, sort, filter]);

  return (
    <CardFlex wrap="wrap" align="flex-start">
      <CardFlex flex="25rem" direction="column">
        <Form id={id} data={data} handleClose={handleClose} open={open} />
        <Card>
          <CardHeader>
            <Title>Companies</Title>
            <div>
              <Button
                variant="contained"
                onClick={() => navigate("/companies/new-company")}
                endIcon={<AddIcon />}
              >
                Add Company
              </Button>
              <Button
                component="label"
                variant="contained"
                onClick={() => navigate("/merge-companies")}
              >
                Merge Companies
              </Button>
            </div>
          </CardHeader>
          <div className="min-w-0 flex-1 my-4">
            <div className="relative rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="search"
                name="nameFilter"
                id="nameFilter"
                value={filter?.searchText}
                onChange={(e) => {
                  setFilter({ ...filter, searchText: e.target.value });
                }}
                className="block w-full rounded-md border-gray-300 border pl-10 focus:border-primary-600 focus:ring-primary-600 sm:text-sm py-2.5 px-3"
                placeholder="Search by name (any)"
              />
            </div>
          </div>
          <div>
            <Table
              columns={columns}
              pageCount={pageCount}
              setItemOffset={setItemOffset}
              data={companies}
              dataCount={count}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              sort={sort}
              setSort={setSort}
              initialSort={initialSort}
              itemOffset={itemOffset}
              loading={loading}
            />
          </div>
        </Card>
      </CardFlex>
    </CardFlex>
  );
};

export default Companies;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardFlex,
  CardHeader,
  Title,
} from "../../components/Card/Card.style";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Form from "./Form/Form";
import {
  getEquipmentBrands,
  deleteEquipmentBrand,
} from "../../../redux/actions/equipmentBrand";
import { useDispatch, useSelector } from "react-redux";
import { getServices } from "../../../redux/actions/service";
import { confirmation } from "../../../utils/notification";
import { getEquipmentTypes } from "../../../redux/actions/equipmentType";
import {
  MagnifyingGlassIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import Table from "../../components/Table/Table";

const EquipmentBrands = () => {
  const initialSort = {
    name: 0,
    entityName: 0,
    contacts: 0,
    offices: 0,
    services: 0,
  };
  const initialFilter = {
    name: "",
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { equipmentBrands, count } = useSelector(
    (state) => state.equipmentBrandReducer
  );

  const columns = [
    {
      header: "Name",
      fieldName: "name",
      render: (brand) => brand.name,
      sortable: true,
    },
    {
      header: "Entity Name",
      fieldName: "entityName",
      render: (brand) => brand.entityName,
      sortable: true,
    },
    {
      header: "Actions",
      render: (brand) => (
        <div className="flex justify-center gap-3">
          <button
            onClick={() => {
              navigate(`/equipment-brands/update/${brand._id}`);
            }}
          >
            <PencilIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
          <button onClick={() => handleDelete(brand._id)}>
            <TrashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
        </div>
      ),
    },
  ];

  const [open, setOpen] = useState(false);
  const [id, setID] = useState(null);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState(initialSort);
  const [filter, setFilter] = useState(initialFilter);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [itemOffset, setItemOffset] = React.useState(0);

  const pageCount = Math.ceil(count / itemsPerPage);

  const handleClickOpen = (id = null, data = []) => {
    setID(id);
    setData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (id) => {
    confirmation().then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteEquipmentBrand(id));
      }
    });
  };

  useEffect(() => {
    dispatch(getEquipmentTypes());
    dispatch(getServices());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getEquipmentBrands(filter, sort, {
        skip: itemOffset,
        limit: itemsPerPage,
      })
    );
  }, [dispatch, filter, sort, itemsPerPage, itemOffset]);

  return (
    <CardFlex wrap="wrap" align="flex-start">
      <CardFlex flex="25rem" direction="column">
        <Form id={id} data={data} handleClose={handleClose} open={open} />
        <Card>
          <CardHeader>
            <Title>Equipment Brands</Title>
            <div>
              <Button
                variant="contained"
                onClick={() => navigate("/equipment-brands/add")}
                endIcon={<AddIcon />}
              >
                Add Equipment Brand
              </Button>
              <Button
                component="label"
                variant="contained"
                onClick={() => navigate("/merge-equipment-brands")}
              >
                Merge Conflicts
              </Button>
            </div>
          </CardHeader>
          <div className="min-w-0 flex-1 my-4">
            <div className="relative rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="search"
                name="nameFilter"
                id="nameFilter"
                value={filter?.name}
                onChange={(e) => {
                  setFilter({ ...filter, name: e.target.value });
                }}
                className="block w-full rounded-md border-gray-300 border pl-10 focus:border-primary-600 focus:ring-primary-600 sm:text-sm py-2.5 px-3"
                placeholder="Search by name (any)"
              />
            </div>
          </div>
          <div>
            <Table
              columns={columns}
              pageCount={pageCount}
              setItemOffset={setItemOffset}
              data={equipmentBrands}
              dataCount={count}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              initialSort={initialSort}
              sort={sort}
              setSort={setSort}
              itemOffset={itemOffset}
              loading={false}
            />
            {/* <ViewTable
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              emptyRows={emptyRows}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rows={filteredEquipmentBrands}
              columnsName={columnsName}
            >
              {filteredEquipmentBrands
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map(
                  (
                    {
                      _id,
                      name,
                      entityName,
                      logo,
                      services,
                      offices,
                      contacts,
                      users,
                    },
                    index
                  ) => (
                    <tr key={_id}>
                      <td>{index + 1}</td>
                      <td>{name}</td>
                      <td>{services?.length || 0}</td>
                      <td>{offices?.length || 0}</td>
                      <td>{contacts?.length || 0}</td>
                      <td>
                        <CardFlex justify="center">
                          <Button
                            onClick={() => handleDelete(_id)}
                            variant="contained"
                          >
                            Delete
                          </Button>
                          <Button
                            onClick={() =>
                              handleClickOpen(_id, {
                                name,
                                entityName,
                                logo,
                                services,
                                offices,
                                contacts,
                              })
                            }
                            variant="contained"
                          >
                            Update
                          </Button>
                        </CardFlex>
                      </td>
                    </tr>
                  )
                )}
            </ViewTable> */}
          </div>
        </Card>
      </CardFlex>
    </CardFlex>
  );
};

export default EquipmentBrands;

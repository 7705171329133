import { API } from "./api";

export const createLandingPage = (preset) => {
  console.log("LP: ", Object.fromEntries(preset));
  return API.post("/landingPages/", preset, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateLandingPage = (id, preset) =>
  API.put(`/landingPages/${id}`, preset);

export const updateEnableMapLocation = (id, enableMapLocation) =>
  API.put(`/landingPages/${id}/enableMapLocation`, { enableMapLocation });

export const updateShowProjectCost = (id, showProjectCost) =>
  API.put(`/landingPages/${id}/showProjectCost`, { showProjectCost });

export const updateAllowMapZoom = (id, allowMapZoom) =>
  API.put(`/landingPages/${id}/allowMapZoom`, { allowMapZoom });

export const updateAllowMapPan = (id, allowMapPan) =>
  API.put(`/landingPages/${id}/allowMapPan`, { allowMapPan });
export const toggleAdvertise = (id, advertise) =>
  API.put(`/landingPages/${id}/advertise`, { advertise });

export const getLandingPagesByCompany = (id) =>
  API.get(`/landingPages/company/${id}`);

export const getLandingPagesByEquipmentBrand = (id) =>
  API.get(`/landingPages/equipmentBrand/${id}`);

export const getLandingPageById = (filterId) =>
  API.get(`/landingPages/${filterId}`);

export const deleteLandingPage = (id) => API.delete(`/landingPages/${id}`);

import React, { useEffect, useState } from "react";
// import { Autocomplete } from "@mui/material";
import { Autocomplete } from "@react-google-maps/api";

import ViewFields from "../../Services/Form/Field/ViewFields";
import { useDispatch, useSelector } from "react-redux";
import {
  getServiceFieldOptions,
  getServicesByUserId,
} from "../../../../redux/api/service.api";
import { getUsers } from "../../../../redux/actions/user";
import Select from "react-select";
import {
  getAllCompanies,
  getCompaniesAssociatedToUser,
  getServicesByCompanyId,
} from "../../../../redux/api/company.api";
import { failure } from "../../../../utils/notification";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { getProjectById } from "../../../../redux/api/project.api";
import { getOptionSets } from "../../../../redux/actions/optionSet";
import {
  createProject,
  updateProject,
} from "../../../../redux/actions/project";
import { getAllIndividualUsers } from "../../../../redux/api/user.api";

const initialData = {
  company: "",
  workType: "",
  address: "",
  service: "",
};

let projectInitialData = {};
function AddProject({ id, data }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.userReducer);
  const services = useSelector((state) => state.serviceReducer);
  const optionSets = useSelector((state) => state.optionSetReducer);
  const [serviceSelectedValue, setServiceSelectedValue] = useState(null);
  const [userTypeSelectedValue, setUserTypeSelectedValue] = useState(null);
  const [userSelectedValue, setUserSelectedValue] = useState(null);
  const [individualUsers, setIndividualUsers] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [userServices, setUserServices] = useState([]);
  const [companyServices, setCompanyServices] = useState([]);
  const [companySelectedValue, setCompanySelectedValue] = useState(null);

  const [newOptions, setNewOptions] = useState([]);
  const [fieldsLoaded, setFieldsLoaded] = useState(false);
  const [project, setProject] = useState(initialData);
  const [fields, setFields] = useState([]);
  const [projectData, setProjectData] = useState(projectInitialData);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedWorktype, setSelectedWorktype] = useState(null);
  const [costLabel, setCostLabel] = useState("Cost");
  const [autoComplete, setAutoComplete] = useState(null);

  useEffect(() => {
    getAllCompanies()
      .then((res) => {
        setAllCompanies(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });

    getAllIndividualUsers()
      .then((res) => {
        setIndividualUsers(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);
  useEffect(() => {
    dispatch(getOptionSets());
  }, [dispatch]);

  useEffect(() => {
    if (selectedUserId) {
      getServicesByUserId(selectedUserId)
        .then((res) => {
          setUserServices(res?.data);
        })
        .catch((err) => {
          console.log("error is", err);
        });
      getCompaniesAssociatedToUser(selectedUserId)
        .then((comp) => {
          setCompanies(comp.data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [selectedUserId]);

  useEffect(() => {
    if (companySelectedValue) {
      getServicesByCompanyId(companySelectedValue.value)
        .then((res) => {
          setCompanyServices(res?.data);
        })
        .catch((err) => {
          console.log("error is", err);
        });
    }
  }, [companySelectedValue]);
  // useEffect(() => {
  //   if (selectedUserId) {

  //   }
  // }, [selectedUserId]);

  const handleServiceSelect = async (service, workType) => {
    if (service === "" || workType === "") {
      setFields([]);
      setFieldsLoaded(false);
      return;
    }
    let fields = [];
    let fieldsArr = [];

    let i;
    if (project?.userType === "individual") {
      i = userServices.findIndex((s) => s?.serviceId._id === service._id);
      fields.push(
        userServices[i]?.serviceId?.fields
          ?.filter((field) => field?.workTypes?.includes(workType))
          ?.map((field) => {
            if (
              field.type === "select" &&
              field?.module !== "equipmentModels"
            ) {
              fieldsArr.push(
                fetchModule(field.module, field?.filter, field?.optionSet)
              );
              return {
                ...field,
                options: [],
              };
            } else if (field.type === "measurement") {
              fieldsArr.push(
                fetchModule("optionSets", field?.filter, field?.optionSet)
              );
              return {
                ...field,
                options: [],
              };
            } else {
              return field;
            }
          })
      );
    } else {
      i = companyServices.findIndex((s) => s?.serviceId._id === service._id);
      fields.push(
        companyServices[i]?.serviceId?.fields
          ?.filter((field) => field?.workTypes?.includes(workType))
          ?.map((field) => {
            if (
              field.type === "select" &&
              field?.module !== "equipmentModels"
            ) {
              fieldsArr.push(
                fetchModule(field.module, field?.filter, field?.optionSet)
              );
              return {
                ...field,
                options: [],
              };
            } else if (field.type === "measurement") {
              fieldsArr.push(
                fetchModule("optionSets", field?.filter, field?.optionSet)
              );
              return {
                ...field,
                options: [],
              };
            } else {
              return field;
            }
          })
      );
    }

    const allOptions = await Promise.all(fieldsArr);

    var optionsArrCount = 0;
    // const allOptionsForMeasurement = await Promise.all(fieldsArr);
    // var optionsArrMeasurement = 0;

    fields[0]?.map((field) => {
      if (field.type === "select" && field?.module !== "equipmentModels") {
        field.options = allOptions[optionsArrCount];
        optionsArrCount++;
      } else if (field.type === "measurement") {
        field.options = allOptions[optionsArrCount];
        optionsArrCount++;
      } else {
        field.options = [];
      }
      field.query = "";
      return field;
    });

    setFields(fields[0]);
    setFieldsLoaded(true);
    setProject({ ...project, date: project.date?.split("T")[0], service });
    // fields[0] is an array of all fields.
    // set project data to an object with attributes as field names and values as empty strings
    if (id === undefined) {
      let data = {};
      fields[0]?.map((field) => {
        data[field.name] = "";
        return field;
      });
      setProjectData(data);
    }
  };
  useEffect(() => {
    handleServiceSelect(project?.service, project?.workType);
  }, [project?.service, project?.workType]);

  useEffect(() => {
    if (project?.workType !== "") {
      setCostLabel(
        userServices
          ?.find((x) => x?.serviceId?._id === project?.service?._id)
          ?.workTypes.find((wt) => wt._id === project?.workType)?.costLabel
      );
    } else {
      setCostLabel("Cost");
    }
  }, [project?.workType]);

  const filterServiceWorkTypes = (selectedService) => {
    if (!selectedService) return [];
    const foundService =
      project?.userType === "individual"
        ? userServices?.find((x) => x?.serviceId?._id === selectedService?._id)
            ?.workTypes
        : project?.userType === "business" &&
          companyServices?.find(
            (x) => x?.serviceId?._id === selectedService?._id
          )?.workTypes;

    return foundService;
  };

  const onLoadAutoComplete = (autoComplete) => {
    setAutoComplete(autoComplete);
  };

  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      const p = autoComplete.getPlace();
      const latlng = [
        p?.geometry?.location?.lng(),
        p?.geometry?.location?.lat(),
      ];
      setProject({
        ...project,
        address: p.formatted_address,
        coordinates: latlng,
        state: p.address_components.find(
          (c) => c.types[0] === "administrative_area_level_1"
        )?.short_name,
      });
      // infoWin?.close();
    }
  };
  const fetchModule = async (module, filter = null, optionSet = null) => {
    let options = [];
    if (module === "optionSets") {
      options = optionSets?.find((os) => os._id === optionSet)?.options;
    } else {
      const { data } = await getServiceFieldOptions(module, filter);
      options = data;
    }
    return options;
  };

  useEffect(() => {
    if (id !== undefined && services?.length > 0 && optionSets?.length > 0) {
      getProjectById(id).then((res) => {
        console.log("Project is: ", res.data);
        setProject({
          ...project,
          date: res.data.date?.split("T")[0],
          service: {
            _id: res.data?.service?._id,
            name: res.data?.service?.name,
            workTypes: res.data?.service?.workTypes,
          },
          workType: res.data?.workType,
          address: res.data?.address,
          cost: res.data?.cost,
          company: res.data?.company?._id,
        });
        setProjectData(transformProjectData(res.data.projectData));
        // getServiceFields(res.data.service?._id, id).then((res) => {
        //   setFields(res.data);
        // });

        setServiceSelectedValue({
          value: res.data.service?._id,
          label: res.data.service?.name,
          workTypes: res.data.service?.workTypes,
        });
        setSelectedWorktype({
          value: res.data.workType,
          label: res?.data?.service?.workTypes?.find(
            (wt) => wt._id === res.data.workType
          )?.name,
        });
        setCompanySelectedValue({
          value: res.data.company?._id,
          label: res.data.company?.name,
        });
      });
    }
  }, [id, services, optionSets]);

  function transformProjectData(data) {
    // Iterate over each property in the object
    for (const key in data) {
      const property = data[key];
      // Check if the property is an object and has both 'unit' and 'value' keys
      if (
        property &&
        typeof property === "object" &&
        "unit" in property &&
        "value" in property
      ) {
        // Create new properties at the top level for unit and value
        data[`${key}_unit`] = property.unit;
        data[`${key}_value`] = property.value;

        // Delete the original property object
        delete data[key];
      }
    }
    return data;
  }

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const { index, projectData, ...d } = data;
    }
  }, [data, services]);

  const handleImageChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const newImages = [...project.images];

      const loadImage = (file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();

          reader.onloadend = () => {
            resolve({
              file: file,
              dataURL: reader.result,
            });
          };

          reader.readAsDataURL(file);
        });
      };

      const loadImages = async () => {
        const imagePromises = Array.from(files).map((file) => loadImage(file));
        const loadedImages = await Promise.all(imagePromises);
        newImages.push(...loadedImages);
        setProject({ ...project, images: newImages });
      };

      loadImages();
    }
  };

  const handleRemoveImage = (index) => {
    const newImages = [...project.images];
    newImages.splice(index, 1);
    setProject({ ...project, images: newImages });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedProjectData = { ...projectData };

    fields?.forEach((field) => {
      if (field.type === "measurement") {
        updatedProjectData[field.name] = {
          unit: projectData[`${field.name}_unit`],
          value: projectData[`${field.name}_value`],
        };
        delete updatedProjectData[`${field.name}_unit`];
        delete updatedProjectData[`${field.name}_value`];
      }
    });
    if (project?.date === "") {
      failure("Please enter date");
      return;
    }
    if (project?.service === "") {
      failure("Please select service");
      return;
    }
    if (project?.address === "") {
      failure("Please enter address");
      return;
    }

    console.log("Created by", selectedUserId);
    console.log("Type of created by", typeof selectedUserId);

    const data = new FormData();
    data.append("date", project?.date);
    data.append("service", project?.service?._id);
    data.append("address", project?.address);
    data.append("coordinates", JSON.stringify(project?.coordinates));
    data.append("state", project?.state);
    data.append("workType", project?.workType);
    data.append("cost", project?.cost);
    data.append("company", project?.company);
    data.append("userType", project?.userType);
    data.append("createdBy", selectedUserId === null ? "" : selectedUserId);
    data.append("projectData", JSON.stringify(updatedProjectData));
    data.append("newOptions", JSON.stringify(newOptions));

    project?.images?.forEach((img) => {
      data.append("images", img?.file);
    });
    if (id !== undefined) {
      dispatch(updateProject(id, data));
      navigate("/projects");
      return;
    }

    dispatch(createProject(data));
    navigate("/projects");
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="bg-white rounded-lg shadow">
          <div className="border-b border-gray-200 rounded-t-lg bg-white px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-2">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Add Project
                </h3>
              </div>
            </div>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <div className="grid grid-cols-12 lg:gap-6 md:gap-y-3 md:gap-x-6 gap-3">
              {/* Input for company name */}
              <div className="md:col-span-6 col-span-12 lg:col-span-4">
                <div className="">
                  <label
                    htmlFor="userType"
                    className=" block text-sm font-medium text-gray-700"
                  >
                    User Type
                  </label>
                  <div className="mt-1">
                    <Select
                      id="userType"
                      name="userType"
                      className="bg-gray-50 block w-full shadow-sm focus:ring-primary focus:border-primary sm:text-sm border-gray-300 rounded-md"
                      options={[
                        { value: "individual", label: "Individual" },
                        { value: "business", label: "Business" },
                      ]}
                      value={userTypeSelectedValue}
                      onChange={(selectedOption) => {
                        setProject({
                          ...project,
                          userType: selectedOption?.value,
                          user: "",
                          company: "",
                          service: "",
                          workType: "",
                        });
                        setUserTypeSelectedValue(selectedOption);
                        setCompanySelectedValue(null);
                        setServiceSelectedValue(null);
                        setSelectedWorktype(null);
                      }}
                      required
                      placeholder="Select User Type"
                    />
                  </div>
                </div>
              </div>
              {project?.userType === "individual" ? (
                <>
                  <div className="md:col-span-6 col-span-12 lg:col-span-4">
                    <div className="">
                      <label
                        htmlFor="name"
                        className=" block text-sm font-medium text-gray-700"
                      >
                        User
                      </label>
                      <div className="mt-1">
                        <Select
                          id="user"
                          name="user"
                          className="bg-gray-50 block w-full shadow-sm focus:ring-primary focus:border-primary sm:text-sm border-gray-300 rounded-md"
                          options={individualUsers.map((user) => ({
                            value: user._id,
                            label: `${user.firstName} ${user.lastName}`,
                          }))}
                          value={userSelectedValue}
                          onChange={(selectedOption) => {
                            setProject({
                              ...project,
                              user: selectedOption,
                              company: "",
                              service: "",
                              workType: "",
                            });
                            setUserSelectedValue(selectedOption);
                            setSelectedUserId(selectedOption.value);
                            setCompanySelectedValue(null);
                            setServiceSelectedValue(null);
                          }}
                          placeholder="Select User"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:col-span-6 col-span-12 lg:col-span-4">
                    <div className="">
                      <label
                        htmlFor="company"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Company
                      </label>
                      <div className="mt-1">
                        <Select
                          id="company"
                          name="company"
                          className="bg-gray-50 block w-full shadow-sm focus:ring-primary focus:border-primary sm:text-sm border-gray-300 rounded-md"
                          value={companySelectedValue}
                          onChange={(selectedOption) => {
                            setProject({
                              ...project,
                              company: selectedOption.value,
                            });
                            setCompanySelectedValue(selectedOption);
                          }}
                          options={companies.map((company) => ({
                            value: company._id,
                            label: company.name,
                          }))}
                          placeholder="Select Company"
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                project?.userType === "business" && (
                  <div className="md:col-span-6 col-span-12 lg:col-span-4">
                    <div className="">
                      <label
                        htmlFor="company"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Company
                      </label>
                      <div className="mt-1">
                        <Select
                          id="company"
                          name="company"
                          className="bg-gray-50 block w-full shadow-sm focus:ring-primary focus:border-primary sm:text-sm border-gray-300 rounded-md"
                          value={companySelectedValue}
                          onChange={(selectedOption) => {
                            setProject({
                              ...project,
                              company: selectedOption.value,
                              service: "",
                              workType: "",
                            });
                            setCompanySelectedValue(selectedOption);
                            setServiceSelectedValue(null);
                            setSelectedWorktype(null);
                          }}
                          options={allCompanies.map((company) => ({
                            value: company._id,
                            label: company.name,
                          }))}
                          placeholder="Select Company"
                        />
                      </div>
                    </div>
                  </div>
                )
              )}
              <div className="md:col-span-6 col-span-12 lg:col-span-4">
                <div className="">
                  <label
                    htmlFor="name"
                    className=" block text-sm font-medium text-gray-700"
                  >
                    Service
                  </label>
                  <div className="mt-1">
                    <Select
                      id="service"
                      name="service"
                      isMulti={false} // Change to true if you want to allow multiple selections
                      className="bg-gray-50 block w-full shadow-sm focus:ring-primary focus:border-primary sm:text-sm border-gray-300 rounded-md"
                      value={serviceSelectedValue}
                      onChange={(selectedOption) => {
                        setProject({
                          ...project,
                          service: {
                            _id: selectedOption.value,
                            name: selectedOption.label,
                            workTypes: selectedOption.workTypes,
                          },
                          workType: "",
                        });
                        setServiceSelectedValue(selectedOption);
                        setSelectedWorktype(null);
                      }}
                      options={
                        project?.userType === "individual"
                          ? userServices.map((user) => ({
                              value: user?.serviceId?._id,
                              label: user?.serviceId?.name,
                              workTypes: user?.service?.workTypes,
                            }))
                          : project?.userType === "business" &&
                            companyServices.map((company) => ({
                              value: company?.serviceId?._id,
                              label: company?.serviceId?.name,
                              workTypes: company?.service?.workTypes,
                            }))
                      }
                      placeholder="Select Service"
                    />
                  </div>
                </div>
              </div>
              <div className="md:col-span-6 col-span-12 lg:col-span-4">
                <div className="">
                  <label
                    htmlFor="workType"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Project Type
                  </label>
                  <div className="mt-1">
                    <Select
                      id="workType"
                      name="workType"
                      className=" bg-gray-50 block w-full shadow-sm focus:ring-green-600 focus:border-green-600 !important sm:text-sm border-gray-300 rounded-md"
                      value={selectedWorktype}
                      onChange={(selectedOption) => {
                        setProject({
                          ...project,
                          workType: selectedOption.value,
                        });
                        setSelectedWorktype(selectedOption);
                      }}
                      options={filterServiceWorkTypes(
                        project?.service,
                        userServices
                      )?.map((workType, index) => ({
                        value: workType._id,
                        label: workType.name,
                      }))}
                      placeholder="Select Project Type"
                    />
                  </div>
                </div>
              </div>
              <div className="md:col-span-6 col-span-12 lg:col-span-4">
                <div className="">
                  <label
                    htmlFor="date"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Date Project Completed
                  </label>
                  <div className="mt-1">
                    <input
                      type="date"
                      name="date"
                      id="date"
                      className=" bg-gray-50 block w-full shadow-sm focus:ring-primary focus:border-primary sm:text-sm border-gray-300 rounded-md"
                      value={project?.date}
                      onChange={(e) =>
                        setProject({ ...project, date: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="md:col-span-6 col-span-12 lg:col-span-4">
                <div className="">
                  <label
                    htmlFor="cost"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {costLabel || "Cost"}
                  </label>
                  <div className="mt-1">
                    <input
                      type="number"
                      name="cost"
                      id="cost"
                      className=" bg-gray-50 block w-full shadow-sm focus:ring-primary focus:border-primary sm:text-sm border-gray-300 rounded-md"
                      value={project?.cost}
                      onChange={(e) =>
                        setProject({ ...project, cost: e.target.value })
                      }
                      placeholder="Cost"
                    />
                  </div>
                </div>
              </div>
              <div className="md:col-span-6 col-span-12 lg:col-span-4">
                <div className="">
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Address
                  </label>
                  <div className="mt-1">
                    <Autocomplete
                      onLoad={onLoadAutoComplete}
                      onPlaceChanged={onPlaceChanged}
                    >
                      <input
                        type="text"
                        defaultValue={project?.address}
                        onChange={(e) =>
                          setProject({ ...project, address: e.target.value })
                        }
                        placeholder="Address"
                        className="bg-gray-50 block w-full shadow-sm focus:ring-primary focus:border-primary sm:text-sm border-gray-300 rounded-md"
                      />
                    </Autocomplete>
                    {/* <Autocomplete
                      onLoad={onLoadAutoComplete}
                      onPlaceChanged={onPlaceChanged}
                      renderInput={(props) => (
                        <input
                          {...props}
                          type="text"
                          defaultValue={project?.address}
                          onChange={(e) =>
                            setProject({ ...project, address: e.target.value })
                          }
                          placeholder="Address"
                          className="bg-gray-50 block w-full shadow-sm focus:ring-primary focus:border-primary sm:text-sm border-gray-300 rounded-md"
                        />
                      )}
                    ></Autocomplete> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ViewFields
            newOptions={newOptions}
            setNewOptions={setNewOptions}
            fields={fields}
            setFields={setFields}
            projectData={projectData}
            setProjectData={setProjectData}
            fieldsLoaded={fieldsLoaded}
          />
          <div className="px-4 py-5 sm:p-6">
            <div className="grid grid-cols-12 lg:gap-6 md:gap-y-3 md:gap-x-6 gap-3">
              <div className=" col-span-12">
                <div className="">
                  <label
                    htmlFor="name"
                    className=" block text-sm font-medium text-gray-700"
                  >
                    Images
                  </label>

                  <div className="p-5 my-4 rounded border-gray-300 border grid grid-cols-12 lg:gap-3 md:gap-y-3 md:gap-x-6 gap-3">
                    <div className="md:col-span-3 col-span-4 lg:col-span-2">
                      <div className="border-2 border-dashed border-gray-200 flex items-center h-28 justify-center">
                        <input
                          id="images"
                          name="images"
                          type="file"
                          className="sr-only"
                          onChange={handleImageChange}
                          multiple
                        />
                        <label
                          htmlFor="images"
                          className="text-white cursor-pointer flex items-center justify-center w-12 h-12 bg-green-600 rounded-full hover:bg-green-700 focus:outline-none focus:ring focus:border-green-300"
                        >
                          <FaPlus />
                        </label>
                      </div>
                    </div>
                    {project?.images?.map((image, index) => (
                      <div
                        key={index}
                        className="md:col-span-3 col-span-4 lg:col-span-2"
                      >
                        <div className="relative group">
                          <div
                            onClick={() => handleRemoveImage(index)}
                            className="absolute top-2 right-2 bg-red-600 rounded-full h-5 w-5 text-white flex items-center justify-center opacity-0 transition-opacity group-hover:opacity-100 cursor-pointer"
                          >
                            <MdClose />
                          </div>
                          <img
                            className="w-full h-28 border border-gray-300 rounded"
                            src={image.dataURL}
                            alt={image.file.name}
                          />
                          <div className="absolute bottom-2 left-2 text-white font-semibold">
                            {image.file.name}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="border-t border-gray-200 flex justify-end px-4 py-5 sm:px-6">
              <button
                type="submit"
                className="relative inline-flex items-center rounded-md bg-green-600 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {id ? "Update" : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddProject;

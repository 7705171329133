import { failure, success } from "../../utils/notification";
import * as api from "../api";
import * as type from "../types";

export const getOffices = () => async (dispatch) => {
  try {
    const { data } = await api.getOffices();
    dispatch({ type: type.GET_OFFICES, payload: data });
  } catch (error) {
    failure(error.message);
  }
};

export const createOffice = (office) => async (dispatch) => {
  try {
    const { data, status } = await api.createOffice(office);
    if (status === 200) {
      dispatch({ type: type.CREATE_OFFICE, payload: data });
      success("Saved");
    }
  } catch (error) {
    failure(error.message);
  }
};

export const updateOffice = (id, office) => async (dispatch) => {
  try {
    const { data, status } = await api.updateOffice(id, office);
    if (status === 200) {
      dispatch({ type: type.UPDATE_OFFICE, payload: data });
      success("Updated");
    }
  } catch (error) {
    failure(error.message);
  }
};

export const deleteOffice = (id) => async (dispatch) => {
  try {
    const { status } = await api.deleteOffice(id);
    if (status === 200) {
      dispatch({ type: type.DELETE_OFFICE, payload: id });
      success("Deleted");
    }
  } catch (error) {
    failure(error.message);
  }
};

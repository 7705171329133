import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { BiCloudUpload } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import {
  createEquipmentBrand,
  updateEquipmentBrand,
} from "../../../../redux/actions/equipmentBrand";
import { getServices } from "../../../../redux/actions/service";
import { API, baseURL } from "../../../../redux/api/api";
import {
  getEquipmentBrand,
  getServicesByEquipmentBrand,
} from "../../../../redux/api/equipmentBrand.api";
import {
  deleteContact,
  deleteContactFromEquipmentBrand,
} from "../../../../redux/api/contact.api";
import {
  deleteOffice,
  deleteOfficeInBrand,
} from "../../../../redux/api/office.api";
import { confirmation } from "../../../../utils/notification";
import CustomDropDown from "../../../components/CustomDropDown/CustomDropDown";
import AddContactPopup from "./add-contact-popup";
import AddOfficePopup from "./add-office-popup";
import UpdateServicesPopup from "./UpdateServicesPopup";
import ProjectsMap from "./ProjectsMap";
import MapPages from "./MapPages";
import AppearanceSettings from "./AppearanceSettings";
import { getEquipmentTypes } from "../../../../redux/api";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CreateEquipmentBrand = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const tabs = [
    { name: "Offices" },
    { name: "Services" },
    { name: "Projects Map" },
    { name: "Map Pages" },
    { name: "Appearance Settings" },
    { name: "Contacts" },
  ];
  const initialData = {
    name: "",
    entityName: "",
    logo: "",
    website: "",
    description: "",
    equipmentTypes: [],
    services: [],
    contacts: [],
    offices: [],
    users: [],
  };

  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [editData, setEditData] = useState(null);
  const [officePopupOpen, setOfficePopupOpen] = useState(false);
  const [updateServicesPopupOpen, setUpdateServicesPopupOpen] = useState(false);
  const [contactPopupOpen, setContactPopupOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Offices");
  const [equipmentBrand, setEquipmentBrand] = useState(initialData);
  const [logoPreview, setLogoPreview] = useState(null);
  const [services, setServices] = useState([]);

  useEffect(() => {
    if (id != undefined) {
      getEquipmentBrand(id).then((res) => {
        setEquipmentBrand(res.data);
        // setLogoPreview(res.data.logo);
        getEquipmentBrandServices();
      });
    }
  }, [id]);

  useEffect(() => {
    getEquipmentTypes()
      .then((res) => {
        setEquipmentTypes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getEquipmentBrandServices = () => {
    getServicesByEquipmentBrand(id)
      .then((res) => {
        setServices(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "logo") {
      setEquipmentBrand({ ...equipmentBrand, [name]: e.target.files[0] });
      setLogoPreview(URL.createObjectURL(e.target.files[0]));
      return;
    }
    setEquipmentBrand({ ...equipmentBrand, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", equipmentBrand?.name);
    formData.append("entityName", equipmentBrand?.entityName || "");
    formData.append("website", equipmentBrand?.website || "");
    formData.append("logo", equipmentBrand?.logo || "");
    formData.append(
      "equipmentTypes",
      JSON.stringify(
        equipmentBrand?.equipmentTypes?.map((type) => type?._id) || []
      )
    );
    formData.append(
      "contacts",
      JSON.stringify(
        equipmentBrand?.contacts?.map((contact) => contact?._id) || []
      )
    );
    formData.append(
      "offices",
      JSON.stringify(
        equipmentBrand?.offices?.map((office) => office?._id) || []
      )
    );
    if (equipmentBrand?._id) {
      dispatch(updateEquipmentBrand(equipmentBrand?._id, formData));
    } else {
      dispatch(createEquipmentBrand(formData));
      navigate("/equipment-brands");
    }
  };

  const handleDeleteContact = async (id) => {
    confirmation().then((res) => {
      if (res.isConfirmed) {
        if (equipmentBrand?._id) {
          deleteContactFromEquipmentBrand(equipmentBrand?._id, id).then(
            (res) => {
              setEquipmentBrand({
                ...equipmentBrand,
                contacts: equipmentBrand?.contacts?.filter(
                  (contact) => contact._id !== id
                ),
              });
            }
          );
        } else {
          deleteContact(id).then((res) => {
            setEquipmentBrand({
              ...equipmentBrand,
              contacts: equipmentBrand?.contacts?.filter(
                (contact) => contact._id !== id
              ),
            });
          });
        }
      }
    });
  };

  const handleDeleteOffice = async (id) => {
    confirmation().then((res) => {
      if (res.isConfirmed) {
        deleteOfficeInBrand(id, equipmentBrand?._id).then((res) => {
          setEquipmentBrand({
            ...equipmentBrand,
            offices: equipmentBrand?.offices?.filter(
              (office) => office._id !== id
            ),
          });
        });
      }
    });
  };

  return (
    <>
      <AddOfficePopup
        data={editData}
        open={officePopupOpen}
        setOpen={setOfficePopupOpen}
        equipmentBrand={equipmentBrand}
        setEquipmentBrand={setEquipmentBrand}
        editing={equipmentBrand?._id}
      />
      <AddContactPopup
        data={editData}
        open={contactPopupOpen}
        setOpen={setContactPopupOpen}
        equipmentBrand={equipmentBrand}
        setEquipmentBrand={setEquipmentBrand}
        offices={equipmentBrand?.offices}
        editing={equipmentBrand?._id}
      />
      <UpdateServicesPopup
        equipmentBrand={equipmentBrand}
        open={updateServicesPopupOpen}
        setOpen={setUpdateServicesPopupOpen}
        equipmentBrandServices={services?.map((service) => service._id)}
        getEquipmentBrandServices={getEquipmentBrandServices}
      />
      <div className="bg-white rounded-lg shadow">
        <form onSubmit={handleSubmit}>
          <div className="border-b border-gray-200 rounded-t-lg bg-white px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-2">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  {id ? "Update Brand" : "Create New Brand"}
                </h3>
              </div>
              <div className="ml-4 mt-2 flex-shrink-0">
                <button
                  type="submit"
                  className="relative inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                >
                  {id ? "Update" : "Create"}
                </button>
              </div>
            </div>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-3">
                <div className="relative w-full h-full bg-gray-100 hover:bg-gray-200 rounded-md cursor-pointer group">
                  {equipmentBrand?.logo ? (
                    <>
                      <img
                        src={
                          logoPreview
                            ? logoPreview
                            : `${baseURL}s3/images/${equipmentBrand?.logo}`
                        }
                        alt="logo"
                        className="absolute inset-0 w-full h-full object-contain rounded-md"
                      />
                      <input
                        type="file"
                        name="logo"
                        id="logo"
                        accept="image/*"
                        onChange={handleChange}
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                      />
                    </>
                  ) : (
                    <>
                      <input
                        type="file"
                        name="logo"
                        id="logo"
                        accept="image/*"
                        onChange={handleChange}
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                      />
                      <label
                        htmlFor="logo"
                        className="absolute inset-0 w-full h-full cursor-pointer flex items-center justify-center"
                      >
                        <BiCloudUpload className="w-20 h-20 text-gray-400 group-hover:text-gray-500" />
                      </label>
                    </>
                  )}
                </div>
              </div>
              {/* Input for equipmentBrand name */}
              <div className="col-span-9 grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Brand Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={equipmentBrand?.name}
                      onChange={handleChange}
                      className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Brand Name"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="entityName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Entity Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="entityName"
                      id="entityName"
                      value={equipmentBrand?.entityName}
                      onChange={handleChange}
                      className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Entity Name"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Website
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="website"
                      id="website"
                      value={equipmentBrand?.website}
                      onChange={handleChange}
                      className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Website"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="equipmentTypes"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Equipment Types
                  </label>
                  <div className="mt-1">
                    <Select
                      isMulti
                      options={equipmentTypes?.map((type) => ({
                        value: type?._id,
                        label: type?.name,
                      }))}
                      value={equipmentBrand?.equipmentTypes?.map((type) => ({
                        value: type?._id,
                        label: type?.name,
                      }))}
                      onChange={(selected) => {
                        setEquipmentBrand({
                          ...equipmentBrand,
                          equipmentTypes: selected.map((type) => ({
                            _id: type.value,
                            name: type.label,
                          })),
                        });
                      }}
                      placeholder="Select Equipment Types"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-10">
              <div>
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                  <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                    defaultValue={selectedTab}
                  >
                    {tabs.map((tab) => (
                      <option key={tab.name}>{tab.name}</option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                      {tabs.map((tab) => (
                        <button
                          type="button"
                          key={tab.name}
                          className={classNames(
                            selectedTab === tab.name
                              ? "border-green-500 text-green-600"
                              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                            "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                          )}
                          aria-current={tab.current ? "page" : undefined}
                          onClick={() => setSelectedTab(tab.name)}
                        >
                          {tab.name}
                        </button>
                      ))}
                    </nav>
                  </div>
                </div>
              </div>
              <div>
                {selectedTab === "Offices" && (
                  <div className="mt-6">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">
                          Offices
                        </h1>
                        {/* <p className="mt-2 text-sm text-gray-700">
                    A list of all the users in your account including their
                    name, title, email and role.
                  </p> */}
                      </div>
                      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                          type="button"
                          onClick={() => {
                            setEditData(null);
                            setOfficePopupOpen(true);
                          }}
                          className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                          Add Office
                        </button>
                      </div>
                    </div>
                    <div className="mt-8 flow-root">
                      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  {/* Check boxes to select rows */}
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  ></th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Phone No
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Website
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Address
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {equipmentBrand?.offices?.length === 0 ? (
                                  <tr>
                                    <td
                                      colSpan="6"
                                      className="py-4 pl-4 pr-3 text-2xl font-medium text-gray-900 sm:pl-6 text-center h-32"
                                    >
                                      Add your first office
                                    </td>
                                  </tr>
                                ) : (
                                  equipmentBrand?.offices?.map((office, i) => (
                                    <tr key={i}>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start"></td>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                        {office.name}
                                      </td>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                        {office.phoneNo}
                                      </td>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                        {office.address}
                                      </td>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setEditData(office);
                                            setOfficePopupOpen(true);
                                          }}
                                        >
                                          <PencilIcon
                                            className="h-5 w-5 text-gray-400 hover:text-gray-500"
                                            aria-hidden="true"
                                          />
                                        </button>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            handleDeleteOffice(office?._id);
                                          }}
                                        >
                                          <TrashIcon
                                            className="h-5 w-5 text-gray-400 hover:text-gray-500"
                                            aria-hidden="true"
                                          />
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {selectedTab === "Services" && (
                  <div className="mt-6">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">
                          Services
                        </h1>
                      </div>
                      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                          type="button"
                          onClick={() => setUpdateServicesPopupOpen(true)}
                          className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                          Edit Services
                        </button>
                      </div>
                    </div>
                    <div className="px-4 py-5 sm:p-6">
                      {services?.length === 0 ? (
                        <div className="mt-6">
                          <p className="text-gray-500 text-center">
                            No services added to this company profile
                          </p>
                        </div>
                      ) : (
                        services?.length > 0 && (
                          <ul
                            role="list"
                            className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
                          >
                            {services?.map((service, i) => (
                              <li
                                key={i}
                                className="col-span-1 flex rounded-md shadow-sm cursor-pointer border rounded-r-md border-gray-200 p-4"
                              >
                                <div
                                  className={classNames(
                                    service.bgColor,
                                    "bg-green-600 flex w-12 h-12 flex-shrink-0 items-center justify-center rounded-full text-xl font-medium text-white"
                                  )}
                                >
                                  {service?.name?.charAt(0)}
                                </div>
                                <div className="flex flex-1 justify-between truncate bg-white">
                                  <div className="flex-1 truncate px-4 py-2 text-sm">
                                    <h2 className="font-medium text-gray-900 hover:text-gray-600">
                                      {service?.name}
                                    </h2>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )
                      )}
                    </div>
                  </div>
                )}
                {selectedTab === "Projects Map" && (
                  <ProjectsMap
                    equipmentBrand={equipmentBrand}
                    servicesUsed={services}
                  />
                )}
                {selectedTab === "Map Pages" && (
                  <MapPages equipmentBrand={equipmentBrand} />
                )}
                {selectedTab === "Appearance Settings" && (
                  <AppearanceSettings equipmentBrand={equipmentBrand} />
                )}
                {selectedTab === "Contacts" && (
                  <div className="mt-6">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">
                          Contacts
                        </h1>
                      </div>
                      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                          type="button"
                          onClick={() => setContactPopupOpen(true)}
                          className="block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                          Add Contact
                        </button>
                      </div>
                    </div>
                    <div className="mt-8 flow-root">
                      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  {/* Check boxes to select rows */}
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  ></th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Phone No
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Email
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Offices
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {equipmentBrand?.contacts?.length === 0 ? (
                                  <tr>
                                    <td
                                      colSpan="6"
                                      className="py-4 pl-4 pr-3 text-2xl font-medium text-gray-900 sm:pl-6 text-center h-32"
                                    >
                                      Add your first contact
                                    </td>
                                  </tr>
                                ) : (
                                  equipmentBrand?.contacts?.map(
                                    (contact, i) => (
                                      <tr key={i}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start"></td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                          {`${contact?.firstName} ${contact?.lastName}`}
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                          {contact.phoneNo}
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                          {contact.email}
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                          <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                            {contact?.offices?.map(
                                              (office, i) => (
                                                <li
                                                  key={i}
                                                  className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                                                >
                                                  <div className="w-0 flex-1 flex items-center">
                                                    <span className="ml-2 flex-1 w-0 truncate">
                                                      {office?.name}
                                                    </span>
                                                  </div>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                          <button
                                            type="button"
                                            onClick={() => {
                                              setEditData(contact);
                                              setContactPopupOpen(true);
                                            }}
                                          >
                                            <PencilIcon
                                              className="h-5 w-5 text-gray-400 hover:text-gray-500"
                                              aria-hidden="true"
                                            />
                                          </button>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              handleDeleteContact(contact?._id);
                                            }}
                                          >
                                            <TrashIcon
                                              className="h-5 w-5 text-gray-400 hover:text-gray-500"
                                              aria-hidden="true"
                                            />
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateEquipmentBrand;

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import People1 from "../../../assets/images/People1.jpg";
import People2 from "../../../assets/images/People2.jpg";
import People3 from "../../../assets/images/People3.jpg";
import RoofingSectionBackground from "../../../assets/images/RoofingSectionBackground.jpg";
import heroMap from "../../../assets/images/roofingLandingPageHeroMap.png";
import heroImg from "../../../assets/images/roofingLandingPageHeroImg.png";
import logo from "../../../assets/images/logo.png";
import { FaCircleArrowRight } from "react-icons/fa6";
import { PiMedalFill } from "react-icons/pi";
import { FaRankingStar } from "react-icons/fa6";
import { GoogleMap } from "@react-google-maps/api";
import { baseURL } from "../../../../redux/api/api";

const containerStyle = {
  width: "100%",
  height: "100%",
};
const options = {
  disableDefaultUI: true,
  zoomControl: true,
  minZoom: 2,
  scaleControl: true,
  mapId: "370dabf190608ead",
  restriction: {
    latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
    strictBounds: true,
  },
};

const RoofingLandingPageSample = ({ company, appearanceSettings }) => {
  const [map, setMap] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 40.727207,
    lng: -74.018186,
  });

  return (
    <>
      <div className="">
        <header className="relative mx-auto max-w-7xl">
          {" "}
          {/* Changed absolute to relative */}
          <nav
            aria-label="Global"
            className="flex items-center justify-between p-2 lg:px-8"
          >
            <div className="flex lg:flex-1">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">{company?.name}</span>
                <img
                  alt=""
                  src={
                    company?.logo ? `${baseURL}s3/images/${company?.logo}` : ""
                  }
                  className="h-12 w-auto"
                />
              </a>
            </div>
            <div className="lg:flex lg:flex-1 lg:justify-end">
              <div className="bg-slate-300 bg-opacity-60 px-1 py-1 rounded-md flex">
                <p className="pt-1 px-2 lg:px-3 text-gray-900 text-xs font-medium">
                  Powered by
                </p>
                <img src={logo} alt="Logo" className="h-6" />
              </div>
            </div>
          </nav>
        </header>

        <div
          className="relative isolate overflow-hidden"
          style={{ background: appearanceSettings?.headerBGColour }}
        >
          <svg
            aria-hidden="true"
            className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          >
            <defs>
              <pattern
                x="50%"
                y={-1}
                id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                width={100}
                height={100}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-600">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
              width="100%"
              height="100%"
              strokeWidth={0}
            />
          </svg>
          <div className="mx-auto max-w-7xl px-6 py-12 flex flex-col gap-y-8 items-start lg:grid lg:grid-cols-2 lg:items-center lg:gap-x-10 lg:px-8 lg:py-24">
            <div className="relative w-full mt-8 sm:mt-12 lg:mt-0 flex-shrink-0 flex-grow">
              <img
                className="h-[26rem] w-full object-cover rounded-lg"
                src={heroMap}
                alt="Roof Area"
              />
              <div className="absolute bg-gray-100 h-20 rounded-md -top-5 -left-4 shadow-lg py-4 px-2 grid grid-cols-3 gap-2">
                <div className="flex justify-center items-center rounded-full bg-red-200 w-12 h-12 col-span-1">
                  <PiMedalFill className="text-orange-600 w-6 h-6" />
                </div>
                <div className="col-span-2 flex flex-col">
                  <h3 className="font-extrabold text-xl">12+</h3>
                  <p className="font-normal text-gray-500 text-sm">
                    Years Experience
                  </p>
                </div>
              </div>
              <div className="absolute bg-gray-100 rounded-md bottom-3 right-3 shadow-lg h-14 px-1 grid grid-cols-3 gap-2">
                <div className="flex flex-col justify-center items-center col-span-1">
                  <div className="w-10 h-10 rounded-md bg-sky-500 flex justify-center items-center">
                    <FaRankingStar className="text-white w-6 h-6" />
                  </div>
                </div>
                <div className="col-span-2 flex flex-col justify-center">
                  <h3 className="font-bold text-xl">98%</h3>
                  <p className="font-normal text-gray-500 text-sm">
                    Satisfaction Rate
                  </p>
                </div>
              </div>
              <div className="absolute top-16 right-5">
                <div
                  className={`rounded-2xl p-1`}
                  style={{
                    background: `linear-gradient(to right, ${appearanceSettings?.primaryColour}, transparent)`,
                  }}
                >
                  <div className="bg-white rounded-[calc(1.5rem-1px)]">
                    <img
                      className="w-80 rounded-2xl object-cover border-4 border-white"
                      src={heroImg}
                      alt="Map"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:flex lg:items-center lg:h-full w-full flex">
              <div className="flex flex-col gap-y-6">
                <h1 className="text-4xl font-bold text-white sm:text-6xl">
                  AI Powered Roof Calculator
                </h1>
                <p className="text-lg font-normal text-white sm:text-xl">
                  See projects in your local area
                </p>
                <form className="md:flex md:items-center md:gap-x-4">
                  <div>
                    <input
                      id="zipCode"
                      name="zipCode"
                      type="text"
                      placeholder="Enter your zip"
                      className="block w-72 rounded-full border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm"
                    />
                  </div>
                  <button
                    type="button"
                    className={`md:mt-0 mt-4 flex items-center gap-x-2 rounded-full px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-400 cursor-pointer text-nowrap`}
                    style={{ background: appearanceSettings?.primaryColour }}
                  >
                    Find Projects
                    <FaCircleArrowRight className="w-5" />
                  </button>
                </form>
                <div className="md:flex md:gap-6">
                  <div className="flex -space-x-4 rtl:space-x-reverse">
                    <img
                      className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
                      src={People1}
                      alt=""
                    />
                    <img
                      className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
                      src={People2}
                      alt=""
                    />
                    <img
                      className="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
                      src={People3}
                      alt=""
                    />
                    <a
                      className="flex items-center justify-center w-10 h-10 text-xs font-medium text-white bg-gray-700 border-2 border-white rounded-full hover:bg-gray-600 dark:border-gray-800"
                      href="#"
                    >
                      1k+
                    </a>
                  </div>
                  <div className="flex flex-col gap-y-2 md:mt-0 mt-4">
                    <div className="flex">
                      <svg
                        className="w-4 h-4 text-yellow-300 me-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                      <svg
                        className="w-4 h-4 text-yellow-300 me-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                      <svg
                        className="w-4 h-4 text-yellow-300 me-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                      <svg
                        className="w-4 h-4 text-yellow-300 me-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                      <svg
                        className="w-4 h-4 text-yellow-300 me-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                    </div>
                    <div className="flex text-white">
                      <p className="ms-1 text-sm font-medium">4.95/5</p>
                      <p className="ms-1 text-sm font-medium">out of</p>
                      <p className="ms-1 text-sm font-medium">450+ reviews</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-full relative overflow-hidden">
          <div
            className="w-full h-full bg-no-repeat bg-cover z-0 py-16"
            style={{ backgroundImage: `url(${RoofingSectionBackground})` }}
          >
            <div className="mx-auto max-w-7xl h-full flex items-center">
              <div className="flex flex-col gap-5 max-w-xl px-6 lg:px-0">
                <h2
                  className="text-3xl font-bold"
                  style={{ color: appearanceSettings?.headingTextColour }}
                >
                  Roofing Replacement & Installation Cost Data Colorado
                </h2>
                <h2
                  className="text-3xl font-bold"
                  style={{ color: appearanceSettings?.headingTextColour }}
                >
                  Last updated:{" "}
                  <span
                    className="font-medium"
                    style={{ color: appearanceSettings?.secondaryColour }}
                  >
                    2024 Data
                  </span>
                </h2>
                <h2
                  className="text-xl font-bold"
                  style={{ color: appearanceSettings?.headingTextColour }}
                >
                  Normal Range:{" "}
                  <span
                    className="font-medium"
                    style={{ color: appearanceSettings?.secondaryColour }}
                  >
                    $4,764 - $12,460
                  </span>
                </h2>
                <p
                  className="text-md font-normal"
                  style={{ color: appearanceSettings?.paragraphTextColour }}
                >
                  Roof replacement and installation costs in Colorado range from{" "}
                  <span
                    className="font-semibold"
                    style={{ color: appearanceSettings?.secondaryColour }}
                  >
                    $4,669 - $8,401
                  </span>{" "}
                  with most homeowners paying{" "}
                  <span
                    className="font-semibold"
                    style={{ color: appearanceSettings?.secondaryColour }}
                  >
                    $14,521.
                  </span>
                </p>
                <p
                  className="text-md font-normal"
                  style={{ color: appearanceSettings?.paragraphTextColour }}
                >
                  The roof is one of the most crucial aspects of any home.
                  Having a quality roof installed by a reliable contractor
                  ensures your roof will remain in good shape for years to come.
                </p>
                <div>
                  <button
                    type="button"
                    className="w-auto flex items-center gap-x-2 rounded-full px-4 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400"
                    style={{ background: appearanceSettings?.primaryColour }}
                  >
                    Calculate your roof area & see similar projects
                    <FaCircleArrowRight className="w-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:mx-auto xl:max-w-7xl mt-10 mx-4">
          <div className="py-8 px-4 bg-gray-100 rounded-lg">
            <h3 className="text-3xl font-semibold">Get In Touch</h3>
            <div className="grid grid-cols-10 gap-6 mt-4">
              <div className="col-span-10 md:col-span-3 p-4 bg-orange-200 rounded-lg">
                <div>
                  <form>
                    <div className="flex flex-col gap-y-4 justify-center">
                      <div>
                        <input
                          id="firstName"
                          name="firstName"
                          type="text"
                          // value={formData?.firstName}
                          // onChange={handleChange}
                          placeholder="First Name"
                          className="block w-full rounded-md border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-400 sm:text-sm bg-yellow-50"
                        />
                      </div>
                      <div>
                        <input
                          id="lastName"
                          name="lastName"
                          type="text"
                          // value={formData?.lastName}
                          // onChange={handleChange}
                          placeholder="Last Name"
                          className="block w-full rounded-md border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-400 sm:text-sm bg-yellow-50"
                        />
                      </div>
                      <div>
                        <input
                          id="address"
                          name="address"
                          type="text"
                          // value={formData?.address}
                          // onChange={handleChange}
                          placeholder="Address"
                          className="block w-full rounded-md border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-400 sm:text-sm bg-yellow-50"
                        />
                      </div>
                      <div>
                        <input
                          id="phoneNo"
                          name="phoneNo"
                          type="text"
                          // value={formData?.phoneNo}
                          // onChange={handleChange}
                          placeholder="Phone Number"
                          className="block w-full rounded-md border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-400 sm:text-sm bg-yellow-50"
                        />
                      </div>
                      <div>
                        <input
                          id="email"
                          name="email"
                          type="text"
                          // value={formData?.email}
                          // onChange={handleChange}
                          placeholder="Email"
                          className="block w-full rounded-md border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-400 sm:text-sm bg-yellow-50"
                        />
                      </div>
                      <div>
                        <textarea
                          id="Message"
                          name="Message"
                          placeholder="Message"
                          className="block w-full h-32 resize-none rounded-md border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-400 sm:text-sm bg-yellow-50 overflow-y-auto"
                        />
                      </div>
                      <div
                        className="w-full flex items-center justify-between gap-x-2 rounded-full px-4 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 cursor-pointer"
                        style={{
                          background: appearanceSettings?.secondaryColour,
                        }}
                      >
                        <input type="button" className="" value="Submit" />
                        <FaCircleArrowRight className="w-5" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-span-10 md:col-span-7 h-full">
                {/* show google map */}
                <div className="rounded-lg h-full">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={mapCenter}
                    zoom={10}
                    onLoad={(map) => setMap(map)}
                    options={options}
                    // ref={mapRef}
                  ></GoogleMap>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer style={{ background: appearanceSettings?.headerBGColour }}>
          <div className="mx-auto max-w-7xl px-6 py-12 flex justify-between lg:px-8 gap-x-4">
            <div className="flex-col space-y-2 order-2">
              <div className="bg-white px-1 py-1 rounded-md flex">
                <p className="pt-1 px-2 lg:px-3 text-gray-900 text-xs font-medium">
                  Powered by
                </p>
                <img src={logo} alt="Logo" className="h-6" />
              </div>
              <div className="text-center flex space-x-2">
                <Link
                  to=""
                  className="text-sm font-light text-white"
                  // style={{color: appearanceSettings?.paragraphTextColour}}
                >
                  Terms and Conditions
                </Link>
                <Link
                  to=""
                  className="text-sm font-light text-white"
                  // style={{color: appearanceSettings?.paragraphTextColour}}
                >
                  Privacy Policy
                </Link>
              </div>
            </div>
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">{company?.name}</span>
              <img
                alt=""
                src={
                  company?.logo ? `${baseURL}s3/images/${company?.logo}` : ""
                }
                className="h-12 w-auto"
              />{" "}
            </a>{" "}
          </div>
          <hr className="border-gray-500" />
          <div className="py-4 text-center">
            <span
              className="font-light text-md text-white"
              // style={{ color: appearanceSettings?.paragraphTextColour }}
            >
              © 2024 Pro-Mapper. All rights reserved.
            </span>
          </div>
        </footer>
      </div>
    </>
  );
};

export default RoofingLandingPageSample;

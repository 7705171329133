import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServices } from "../../../../redux/actions/service";
import { updateEquipmentBrandServices } from "../../../../redux/api/equipmentBrand.api";

const UpdateServicesPopup = ({
  equipmentBrand,
  open,
  setOpen,
  equipmentBrandServices,
  getEquipmentBrandServices,
}) => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.authReducer);
  const services = useSelector((state) => state.serviceReducer);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedServices, setSelectedServices] = useState(
    equipmentBrandServices
  );
  const [serviceModelOpen, setServiceModelOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getServices());
  }, [dispatch]);

  useEffect(() => {
    console.log(equipmentBrandServices);
  }, [equipmentBrandServices]);

  useEffect(() => {
    setSelectedServices(equipmentBrandServices);
  }, [equipmentBrandServices]);

  const handleServiceCheckboxChange = (serviceId) => {
    setSelectedServices((prevSelectedServices) => {
      const existingService = prevSelectedServices.find(
        (service) => service === serviceId
      );

      if (existingService) {
        // Remove the service if it's already selected
        return prevSelectedServices.filter((service) => service !== serviceId);
      } else {
        // Add the service with an empty workTypes array
        return [...prevSelectedServices, serviceId];
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await updateEquipmentBrandServices(equipmentBrand?._id, selectedServices)
      .then((res) => {
        getEquipmentBrandServices();
        setOpen(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          onClose={serviceModelOpen ? () => {} : setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-30 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Edit Services
                      </Dialog.Title>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="space-y-1 px-4 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                      <input
                        type="text"
                        placeholder="Search services..."
                        className="w-full p-2 mb-4 border-2 border-green-600 focus:border-green-600 focus:ring-0 rounded-md static"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      {/* Search field to search for services list */}
                      <div className="space-y-1 sm:gap-4 sm:space-y-0 sm:py-5 h-96 overflow-y-scroll">
                        {services
                          .filter((service) =>
                            service.name
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          )
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((service, i) => (
                            <div key={i} className="bg-white p-2">
                              <label className="flex items-center">
                                <input
                                  type="checkbox"
                                  checked={selectedServices?.some(
                                    (s) => s === service._id
                                  )}
                                  onChange={() =>
                                    handleServiceCheckboxChange(service._id)
                                  }
                                  className="text-green-600 focus:ring-green-600 border-green-600 mr-2"
                                  name="selectedServices"
                                  // Disable the checkbox if the user has already selected the maximum number of services allowed by their plan But allow them to unselect the service
                                />
                                <span>{service.name}</span>
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="flex justify-between items-end">
                      <div className="mr-4">
                        <button
                          type="button"
                          onClick={() => {
                            setServiceModelOpen(true);
                            setOpen(false);
                          }}
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                          Request a new Service
                        </button>
                      </div>
                      <div className="ml-4 sm:flex sm:flex-row-reverse">
                        <button
                          type="submit"
                          disabled={loading}
                          className={`${
                            loading
                              ? "bg-green-700 hover:bg-green-700 cursor-not-allowed"
                              : ""
                          }inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-24 sm:text-sm`}
                        >
                          {loading ? (
                            <div className="flex justify-center items-center w-full">
                              {/* <RotatingLines
                                strokeColor="white"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="20"
                                visible={true}
                              /> */}
                              Loading...
                            </div>
                          ) : (
                            "Update"
                          )}
                        </button>
                        <button
                          type="button"
                          className=" inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default UpdateServicesPopup;

import { API } from "./api";

export const getEquipmentTypes = () => API.get("/equipmentTypes");

export const createEquipmentType = (newEquipmentType) =>
  API.post("/equipmentTypes", newEquipmentType);

export const updateEquipmentType = (id, updatedEquipmentType) =>
  API.patch(`/equipmentTypes/${id}`, updatedEquipmentType);

export const deleteEquipmentType = (id) => API.delete(`/equipmentTypes/${id}`);

import React, { useEffect, useState } from "react";
import {
  Card,
  CardFlex,
  CardHeader,
  Title,
} from "../../components/Card/Card.style";
import { Button } from "@mui/material";
import ViewTable from "../../components/ViewTable/ViewTable";
import AddIcon from "@mui/icons-material/Add";
import SearchBar from "../../components/SearchBar/SearchBar";
import Form from "./Form/Form";
import { getUsers, deleteUser } from "../../../redux/actions/user";
import { useDispatch, useSelector } from "react-redux";
import { confirmation } from "../../../utils/notification";
import { getEquipmentBrands } from "../../../redux/actions/equipmentBrand";
import { getCompanies } from "../../../redux/actions/company";
import { getRoles } from "../../../redux/actions/role";
import { useNavigate } from "react-router-dom";

const columnsName = ["First Name", "Last Name", "Email", "Role", "System ID"];

const Users = () => {
  const navigate = useNavigate();
  const users = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [id, setID] = useState(null);
  const [data, setData] = useState([]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = (id = null, data = []) => {
    setID(id);
    setData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (id) => {
    confirmation().then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteUser(id));
      }
    });
  };

  useEffect(() => {
    dispatch(getUsers());
    // dispatch(getEquipmentBrands());
    // dispatch(getCompanies());
    dispatch(getRoles());
  }, [dispatch]);

  return (
    <CardFlex wrap="wrap" align="flex-start">
      <CardFlex flex="25rem" direction="column">
        <Form id={id} data={data} handleClose={handleClose} open={open} />
        <Card>
          <CardHeader>
            <Title>Users</Title>
            <SearchBar />
            {/* <Button
              variant="contained"
              onClick={() => handleClickOpen()}
              endIcon={<AddIcon />}
            >
              Add User
            </Button> */}
          </CardHeader>
          <div>
            <ViewTable
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              emptyRows={emptyRows}
              rows={users}
              columnsName={columnsName}
            >
              {users
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map(
                  (
                    { _id, firstName, lastName, email, role, systemID },
                    index
                  ) => (
                    <tr key={_id}>
                      <td>{index}</td>
                      <td>{firstName}</td>
                      <td>{lastName}</td>
                      <td>{email}</td>
                      <td>{role}</td>
                      <td>{systemID}</td>
                      <td>
                        <CardFlex justify="center">
                          <Button
                            onClick={() => {
                              window.open(
                                `${
                                  process.env.REACT_APP_PM_APP_URL
                                }/${_id}/access?token=${
                                  JSON.parse(localStorage.getItem("admin"))
                                    ?.token
                                }`
                              );
                            }}
                            variant="contained"
                            color="primary"
                          >
                            Get Access
                          </Button>
                          {/* <Button
                            onClick={() =>
                              handleClickOpen(_id, {
                                firstName,
                                lastName,
                                email,
                                role,
                                systemID,
                              })
                            }
                            variant="contained"
                          >
                            Update
                          </Button> */}
                          <Button
                            onClick={() => handleDelete(_id)}
                            variant="contained"
                            color="error"
                          >
                            Delete
                          </Button>
                        </CardFlex>
                      </td>
                    </tr>
                  )
                )}
            </ViewTable>
          </div>
        </Card>
      </CardFlex>
    </CardFlex>
  );
};

export default Users;

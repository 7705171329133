import { API } from "./api";

export const getServices = () => API.get("/services");
export const getServiceById = (id) => API.get(`/services/${id}`);

export const getAllFilters = (serviceId) => {
  return API.get(`/services/${serviceId}/filters`);
};

export const createService = (newService) => API.post("/services", newService);

export const updateService = (id, updatedService) =>
  API.put(`/services/${id}`, updatedService);

export const deleteService = (id) => API.delete(`/services/${id}`);
export const getServicesByUserId = (id) => API.get(`/users/${id}/services`);
export const getServiceFieldOptions = (module, filter = null) =>
  API.get(`/${module}/${filter}`);

import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { warning } from "../../../../../utils/notification";
import { getEquipmentModelsByBrand } from "../../../../../redux/api/equipmentModel.api";

const ViewFields = ({
  newOptions,
  fields,
  setFields = null,
  setField = null,
  setFieldId,
  projectData = {},
  setProjectData = null,
  setSelectedWorkTypes,
}) => {
  // const [equipmentBrandsForModel, setEquipmentBrandsForModel] = useState([]);
  // const [selectedBrand, setSelectedBrand] = useState(null);
  // const [selectedField, setSelectedField] = useState(null);

  const handleChange = async (e) => {
    if (setProjectData)
      setProjectData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

    if (e.target.value === "") return;

    const currentField = fields?.find((field) => field?.name === e.target.name);

    if (currentField?.module === "equipmentBrands") {
      if (
        newOptions?.filter(
          (option) =>
            option?.module === "equipmentModels" &&
            option?.brand === e.target.value
        )?.length > 0
      ) {
        const modelOptions = newOptions?.filter(
          (option) =>
            option?.module === "equipmentModels" &&
            option?.brand === e.target.value
        );
        if (modelOptions?.length > 0) {
          setFields((prevFields) => {
            const newFields = [...prevFields];
            const fieldIndex = newFields.findIndex(
              (field) =>
                field?.type === "select" &&
                field?.module === "equipmentModels" &&
                field?.filter === currentField?.filter
            );
            newFields[fieldIndex] = {
              ...newFields[fieldIndex],
              options: modelOptions?.map((model) => ({
                _id: model?.option,
                name: model?.option,
              })),
            };
            return newFields;
          });
        }
        //  else {
        //   warning("No Equipment Models Found, Please Add New");
        //   const modelField = fields?.find(
        //     (field) =>
        //       field?.type === "select" &&
        //       field?.module === "equipmentModels" &&
        //       field?.filter === currentField?.filter
        //   );
        //   setEquipmentBrandsForModel(currentField?.options);
        //   setSelectedBrand(e.target.value);
        //   setSelectedField(modelField);
        //   // setAddOptionPopupOpen(true);
        // }
      } else {
        try {
          const res = await getEquipmentModelsByBrand(e.target.value);
          if (res?.data?.length > 0) {
            setFields((prevFields) => {
              console.log("check options data", prevFields);
              const newFields = [...prevFields];
              const fieldIndex = newFields.findIndex(
                (field) =>
                  field?.type === "select" &&
                  field?.module === "equipmentModels" &&
                  field?.filter === currentField?.filter
              );
              newFields[fieldIndex] = {
                ...newFields[fieldIndex],
                options: res?.data?.map((model) => ({
                  _id: model?._id,
                  name: model?.name,
                })),
              };
              console.log("models options", newFields);
              return newFields;
            });
          }
          // else {
          //   warning("No Equipment Models Found, Please Add New");
          //   const modelField = fields?.find(
          //     (field) =>
          //       field?.type === "select" &&
          //       field?.module === "equipmentModels" &&
          //       field?.filter === currentField?.filter
          //   );
          //   setEquipmentBrandsForModel(currentField?.options);
          //   setSelectedBrand(e.target.value);
          //   setSelectedField(modelField);
          //   // setAddOptionPopupOpen(true);
          // }
        } catch (error) {
          // warning("No Equipment Models Found, Please Add New");
          // const modelField = fields?.find(
          //   (field) =>
          //     field?.type === "select" &&
          //     field?.module === "equipmentModels" &&
          //     field?.filter === currentField?.filter
          // );
          // setEquipmentBrandsForModel(currentField?.options);
          // setSelectedBrand(e.target.value);
          // setSelectedField(modelField);
          // setAddOptionPopupOpen(true);
          setFields((prevFields) => {
            const newFields = [...prevFields];
            const fieldIndex = newFields.findIndex(
              (field) =>
                field?.type === "select" &&
                field?.module === "equipmentModels" &&
                field?.filter === currentField?.filter
            );
            newFields[fieldIndex] = {
              ...newFields[fieldIndex],
              options: [],
            };
            return newFields;
          });
        }
      }
    }
  };

  const checkIfBrandIsSelected = (field) => {
    if (field?.module === "equipmentModels") {
      const brandField = fields?.find(
        (f) =>
          f?.type === "select" &&
          f?.module === "equipmentBrands" &&
          f?.filter === field?.filter
      );
      if (brandField) {
        return projectData[brandField?.name] === ""
          ? false
          : projectData[brandField?.name] === undefined
          ? false
          : true;
      } else {
        return true;
      }
    }
  };

  const handleDelete = (id) => {
    const result = window.confirm(
      "Are you sure you want to delete this field."
    );
    if (result) {
      const newFields = fields.filter((f, index) => index !== id);
      setFields([...newFields]);
    }
  };

  const handleUpdate = (id) => {
    setField(fields[id]);
    setFieldId(id);
    console.log(fields[id]);
    setSelectedWorkTypes(
      fields[id]?.workTypes?.map((workType) => ({
        value: workType._id,
        label: workType.name,
      }))
    );
    console.log(
      fields[id]?.workTypes?.map((workType) => ({
        value: workType._id,
        label: workType.name,
      }))
    );
  };

  // const fetchModule = async (module) => {
  //   setOptions([]);
  //   const optionSet = optionSets.find((os) => os.name === module);
  //   if (optionSet === undefined) {
  //     const response = await axios.get(`https://api.pro-mapper.com/${module}s`);
  //     const data = await response.data;
  //     setOptions(data);
  //   } else {
  //     setOptions(optionSet.options);
  //   }
  // };
  return (
    <div className="px-4  sm:p-6 py-5 my-5 border-t border-b border-gray-300">
      <div className="grid grid-cols-12 lg:gap-3 md:gap-y-3 md:gap-x-6 gap-3">
        {fields?.length > 0 ? (
          fields?.map((field, index) => (
            <div key={index} className="col-span-12 lg:col-span-6">
              <div className=" flex w-100">
                {field?.type === "textarea" ? (
                  <TextField
                    multiline
                    rows={4}
                    name={field?.name}
                    value={
                      Object.keys(projectData).length > 0
                        ? projectData[field?.name]
                        : ""
                    }
                    onChange={handleChange}
                    label={field?.label}
                    autoFocus
                    fullWidth
                    required={field?.required}
                  />
                ) : field?.type === "select" ? (
                  <div className="flex-1">
                    <label
                      htmlFor={field?.name}
                      className="block font-medium leading-6 text-gray-900"
                    >
                      {field?.label}
                      {field?.module === "equipmentModels" &&
                        !checkIfBrandIsSelected(field) && (
                          <span className="text-red-500 text-xs">
                            {" "}
                            (Select Brand First)
                          </span>
                        )}
                    </label>

                    <div className="mt-2">
                      <select
                        name={field?.name}
                        id={field?.name}
                        value={
                          Object.keys(projectData).length > 0
                            ? projectData[field?.name]
                            : ""
                        }
                        onChange={handleChange}
                        className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
                        required={field?.required}
                      >
                        <option value="">
                          Select{" "}
                          {field?.label.replace(/([a-z])([A-Z])/g, "$1 $2")}
                        </option>
                        {field?.options?.map((option, index) => (
                          <option
                            key={index}
                            value={
                              option._id === undefined ? option : option._id
                            }
                          >
                            {option.name === undefined ? option : option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ) : field?.type === "date" ? (
                  <div className="flex-1">
                    <label
                      htmlFor={field?.name}
                      className="block font-medium leading-6 text-gray-900"
                    >
                      {field?.label}
                    </label>
                    <div className="mt-2">
                      <input
                        type={field?.type}
                        name={field?.name}
                        id={field?.name}
                        value={projectData ? projectData[field?.name] : ""}
                        onChange={handleChange}
                        autoComplete="off"
                        placeholder={field?.label}
                        className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
                        required={field?.required}
                      />
                    </div>
                  </div>
                ) : field?.type === "measurement" ? (
                  // <div className="flex gap-3 items-end w-[92%]">
                  //   <div className="flex-1">
                  //     <label
                  //       htmlFor={field?.name}
                  //       className="block font-medium leading-6 text-gray-900"
                  //     >
                  //       {field?.label}
                  //     </label>
                  //     <div className="mt-2">
                  //       <input
                  //         type="number"
                  //         name={field?.name}
                  //         id={field?.name}
                  //         value={projectData ? projectData[field?.name] : ""}
                  //         onChange={handleChange}
                  //         autoComplete="off"
                  //         placeholder={field?.label}
                  //         className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
                  //         required={field?.required}
                  //       />
                  //     </div>
                  //   </div>
                  //   <div className="flex-1">
                  //     <div className="mt-2">
                  //       <select
                  //         name={field?.name}
                  //         id={field?.name}
                  //         value={
                  //           Object.keys(projectData).length > 0
                  //             ? projectData[field?.name]
                  //             : ""
                  //         }
                  //         onChange={handleChange}
                  //         className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
                  //         required={field?.required}
                  //       >
                  //         <option value="">
                  //           select{" "}
                  //           {field?.label
                  //             .replace(/([a-z])([A-Z])/g, "$1 $2")
                  //             .toLowerCase()}
                  //         </option>
                  //         {field?.options?.map((option, index) => (
                  //           <option
                  //             key={index}
                  //             value={
                  //               option._id === undefined ? option : option._id
                  //             }
                  //           >
                  //             {option.name === undefined ? option : option.name}
                  //           </option>
                  //         ))}
                  //       </select>
                  //     </div>
                  //   </div>
                  // </div>
                  <div className="w-[95%]">
                    <div className="col-span-12 lg:col-span-6">
                      <div className="flex items-center justify-between">
                        <label
                          htmlFor={`${field?.name}_value`}
                          className="block font-medium leading-6 text-gray-900"
                        >
                          {field?.label}
                        </label>
                      </div>
                      <div className="flex items-center gap-3 mt-2">
                        <input
                          type="number"
                          name={`${field?.name}_value`}
                          id={`${field?.name}_value`}
                          value={
                            projectData
                              ? projectData[`${field?.name}_value`]
                              : ""
                          }
                          onChange={handleChange}
                          autoComplete="off"
                          placeholder={field?.label}
                          className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
                          required={field?.required}
                        />
                        <select
                          name={`${field?.name}_unit`}
                          id={`${field?.name}_unit`}
                          value={
                            Object.keys(projectData).length > 0
                              ? projectData[`${field?.name}_unit`]
                              : ""
                          }
                          onChange={handleChange}
                          className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
                          required={field?.required}
                        >
                          <option value="">
                            Select{" "}
                            {field?.label.replace(/([a-z])([A-Z])/g, "$1 $2")}
                          </option>
                          {field?.options?.map((option, index) => (
                            <option
                              key={index}
                              value={
                                option._id === undefined ? option : option._id
                              }
                            >
                              {option.name === undefined ? option : option.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex-1">
                    <label
                      htmlFor={field?.name}
                      className="block font-medium leading-6 text-gray-900"
                    >
                      {field?.label}
                    </label>
                    <div className="mt-2">
                      <input
                        type={field?.type}
                        name={field?.name}
                        id={field?.name}
                        value={projectData ? projectData[field?.name] : ""}
                        onChange={handleChange}
                        autoComplete="off"
                        placeholder={field?.label}
                        className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
                        required={field?.required}
                      />
                    </div>
                  </div>
                )}
                {setField && (
                  <div style={{ gap: "0.1rem" }}>
                    <EditIcon
                      color="primary"
                      onClick={() => handleUpdate(index)}
                    />
                    <DeleteIcon
                      color="primary"
                      onClick={() => handleDelete(index)}
                    />
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="col-span-12 lg:col-span-12">
            <div className="flex justify-center items-center">
              <h1 className="text-2xl text-gray-400">No fields added yet</h1>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewFields;

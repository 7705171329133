/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
// import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { Combobox } from "@headlessui/react";
import { TailSpin } from "react-loader-spinner";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CustomDropDown = ({
  multiple,
  loading = false,
  setLoading = () => {},
  query,
  setQuery = () => {},
  value,
  displayValue,
  data,
  handleSelect,
  selectText,
  filteredData,
  setFilteredData = () => {},
  editing,
  allowTextEntry = false,
  setText = null,
  setValue = null,
  selectedValue,
  setSelectedValue,
  disabled = false,
}) => {
  useEffect(() => {
    if (editing) {
      setSelectedValue(value);
    }
  }, [editing]);
  useEffect(() => {
    if (setValue) {
      setSelectedValue(setValue);
    }
  }, [setValue]);

  return (
    <>
      <Combobox
        as="div"
        value={selectedValue}
        onChange={setSelectedValue}
        disabled={disabled}
        multiple={multiple}
      >
        <div className="relative mt-1">
          <div className="d-flex focus:ring-primary focus:border-primary border-gray-100 shadow-sm rounded-md">
            <Combobox.Button className="inset-y-0 right-0 block h-full w-full items-center rounded-r-md focus:outline-none">
              <Combobox.Input
                className="block w-full sm:text-sm rounded-md"
                onChange={(event) => {
                  setLoading(true);
                  setFilteredData([]);
                  if (allowTextEntry) {
                    setText(event.target.value);
                  }
                  setQuery(event.target.value);
                }}
                displayValue={displayValue}
                placeholder={selectText}
                autoComplete="off"
              />
            </Combobox.Button>
          </div>
          {/* {loading && (
              <TailSpin
                height="20"
                width="20"
                color="#4fa94d"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            )} */}

          {filteredData?.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredData?.map((item, i) => (
                <Combobox.Option
                  key={i}
                  value={item}
                  onClick={() => handleSelect(item)}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active ? "bg-indigo-600 text-white" : "text-gray-900"
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={classNames(
                          "block truncate",
                          selected && "font-semibold"
                        )}
                      >
                        <span>{`${item?.name}`}</span>
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4",
                            active ? "text-white" : "text-indigo-600"
                          )}
                        >
                          {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </>
  );
};

export default CustomDropDown;

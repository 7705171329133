import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CardFlex } from "../../../components/Card/Card.style";
import { Button, TextField } from "@mui/material";
import { createOffice, updateOffice } from "../../../../redux/actions/office";
import { useDispatch } from "react-redux";

const initialData = {
  name: "",
  phoneNo: "",
  address: "",
  websiteToDirect: "",
  websiteToShow: "",
  isHeadOffice: false,
  location: "",
};

const Form = ({ id, data, handleClose, open }) => {
  const dispatch = useDispatch();
  const [office, setOffice] = useState(initialData);

  const handleChange = (e) => {
    setOffice((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (id) {
      dispatch(updateOffice(id, office));
    } else {
      dispatch(createOffice(office));
    }
    clearData();
  };

  const clearData = () => {
    setOffice(initialData);
    handleClose();
  };

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setOffice(data);
    }
  }, [data]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{id ? "Update" : "Add"} Office</DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent>
          <CardFlex direction="column">
            <CardFlex>
              <TextField
                fullWidth
                value={office.name}
                required
                name="name"
                onChange={handleChange}
                size="small"
                label="Name"
              />
              <TextField
                fullWidth
                value={office.location}
                required
                name="location"
                onChange={handleChange}
                size="small"
                label="Location"
              />
            </CardFlex>
            <TextField
              fullWidth
              type="tel"
              value={office.phoneNo}
              required
              name="phoneNo"
              onChange={handleChange}
              size="small"
              label="Phone No"
            />
            <TextField
              fullWidth
              multiline
              rows={4}
              value={office.address}
              required
              name="address"
              onChange={handleChange}
              size="small"
              label="Address"
            />
            <CardFlex>
              <TextField
                fullWidth
                type="url"
                value={office.websiteToDirect}
                required
                name="websiteToDirect"
                onChange={handleChange}
                size="small"
                label="Web Site to Direct"
              />
              <TextField
                fullWidth
                type="url"
                value={office.websiteToShow}
                required
                name="websiteToShow"
                onChange={handleChange}
                size="small"
                label="Web Site to Show"
              />
            </CardFlex>
          </CardFlex>
        </DialogContent>
        <DialogActions>
          <Button onClick={clearData} variant="contained" autoFocus>
            cancel
          </Button>
          <Button variant="contained" type="submit">
            {id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Form;

import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import stringSimilarity from "string-similarity";
import {
  getConflictedEquipmentBrands,
  markEquipmentBrandAsDifferent,
  mergeEquipmentBrands,
} from "../../../redux/api";
import { success, warning } from "../../../utils/notification";

const MergeEquipmentBrands = () => {
  const dispatch = useDispatch();

  const [possibleConflicts, setPossibleConflicts] = useState([]);
  const [conflictedEquipmentBrands, setConflictedEquipmentBrands] = useState(
    []
  );
  const [filteredEquipmentBrands, setFilteredEquipmentBrands] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [startMerge, setStartMerge] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [mergeWith, setMergeWith] = useState("");
  const [showPossibleConflicts, setShowPossibleConflicts] = useState(false);

  const getConflictedBrands = async () => {
    const { data } = await getConflictedEquipmentBrands();
    setConflictedEquipmentBrands(data);
    setFilteredEquipmentBrands(data);
  };

  useEffect(() => {
    getConflictedBrands();
  }, []);

  useEffect(() => {
    setFilteredEquipmentBrands(
      conflictedEquipmentBrands.filter((brand) =>
        brand.name.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [searchText, conflictedEquipmentBrands]);

  useEffect(() => {
    const groups = [];
    const processedIndexes = new Set();

    for (let i = 0; i < conflictedEquipmentBrands.length; i++) {
      if (processedIndexes.has(i)) {
        continue;
      }

      const currentBrandId = conflictedEquipmentBrands[i]._id;
      const currentBrand = conflictedEquipmentBrands[i].name;
      const differentFrom = conflictedEquipmentBrands[i].markedDifferentFrom;
      const group = [conflictedEquipmentBrands[i]];
      processedIndexes.add(i);

      for (let j = i + 1; j < conflictedEquipmentBrands.length; j++) {
        if (processedIndexes.has(j)) {
          continue;
        }

        const similarity = stringSimilarity.compareTwoStrings(
          currentBrand,
          conflictedEquipmentBrands[j].name
        );

        if (similarity >= 0.6 && !differentFrom.includes(currentBrandId)) {
          // You can adjust this value to match your needs
          group.push(conflictedEquipmentBrands[j]);
          processedIndexes.add(j);
        }
      }
      if (group.length > 1) groups.push(group);
    }

    setPossibleConflicts(groups);
  }, [conflictedEquipmentBrands]);

  const handleMerge = async (type) => {
    const selectedBrandsIds = selectedBrands.map((brand) => brand._id);
    if (type === "merge") {
      if (mergeWith === "") {
        warning("Please select a brand to merge with");
        return;
      }
      await mergeEquipmentBrands({
        correctEquipmentBrandId: mergeWith,
        equipmentBrandsToMerge: selectedBrandsIds,
      })
        .then(({ data }) => {
          success(data.message);
          setStartMerge(false);
          setSelectedBrands([]);
          setMergeWith("");
          getConflictedBrands();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await markEquipmentBrandAsDifferent(selectedBrandsIds)
        .then(({ data }) => {
          success(data.message);
          setStartMerge(false);
          setSelectedBrands([]);
          setMergeWith("");
          getConflictedBrands();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <Transition.Root show={startMerge} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setStartMerge}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setStartMerge(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    Merge Conflicts
                  </Dialog.Title>
                  <div className="sm:flex sm:items-center space-x-4 w-full">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <div className="mt-2">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              {/* Check boxes to select rows */}
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              ></th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Name
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {selectedBrands.map((brand, index) => (
                              <tr key={brand._id}>
                                {/* Check boxes to select rows */}
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                  {index + 1}
                                </td>

                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                  {brand.name}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="mergeWith"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Merge With
                      </label>
                      <select
                        id="mergeWith"
                        name="mergeWith"
                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(e) => setMergeWith(e.target.value)}
                        value={mergeWith}
                      >
                        <option value="">Select Brand</option>
                        {selectedBrands.map((brand) => (
                          <option value={brand?._id} key={brand?._id}>
                            {brand?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                      onClick={() => handleMerge("merge")}
                    >
                      Merge
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setStartMerge(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Equipment Brands To Merge
            </h1>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 flex space-x-2">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => {
                setShowPossibleConflicts(!showPossibleConflicts);
              }}
            >
              {showPossibleConflicts ? "Hide" : "Show"} Conflicts
            </button>
            <button
              type="button"
              className="block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => {
                setStartMerge(true);
              }}
            >
              Merge Brands
            </button>
            <button
              type="button"
              className="block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => {
                handleMerge("different");
              }}
            >
              Mark As Different
            </button>
          </div>
        </div>
        {/* Possible Conflicts show here */}
        {showPossibleConflicts &&
          possibleConflicts.length > 0 &&
          possibleConflicts.map((conflictedBrands, index) => (
            <div className="mt-4" key={index}>
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            {/* Check boxes to select rows */}
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-1/12"
                            ></th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Name
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {conflictedBrands.map((brand, index) => (
                            <tr key={brand._id}>
                              {/* Check boxes to select rows */}
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                <input
                                  type="checkbox"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedBrands([
                                        ...selectedBrands,
                                        brand,
                                      ]);
                                    } else {
                                      setSelectedBrands(
                                        selectedBrands.filter(
                                          (b) => b._id !== brand._id
                                        )
                                      );
                                    }
                                  }}
                                  checked={selectedBrands.includes(brand)}
                                />
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                                {brand.name}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        <div className="min-w-0 flex-1 mt-4">
          <div className="relative rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="search"
              name="nameFilter"
              id="nameFilter"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              className="block w-full rounded-md border-gray-500 pl-10 focus:border-primary-600 focus:ring-primary-600 sm:text-sm py-2.5 px-3"
              placeholder="Search by name (any)"
            />
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      {/* Check boxes to select rows */}
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-1/12"
                      ></th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Name
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {filteredEquipmentBrands.map((brand) => (
                      <tr key={brand._id}>
                        {/* Check boxes to select rows */}
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                          <input
                            type="checkbox"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedBrands([...selectedBrands, brand]);
                              } else {
                                setSelectedBrands(
                                  selectedBrands.filter(
                                    (b) => b._id !== brand._id
                                  )
                                );
                              }
                            }}
                            checked={selectedBrands.includes(brand)}
                          />
                        </td>

                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                          {brand.name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MergeEquipmentBrands;

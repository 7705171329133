import {
  CREATE_COMPANY,
  GET_COMPANIES_REQUEST,
  GET_COMPANIES_SUCCESS,
  DELETE_COMPANY,
  UPDATE_COMPANY,
  UPDATE_COMPANY_ADVERTISEMENT,
} from "../types/companies.types";

const initialState = {
  companies: [],
  count: 0,
  loading: false,
  error: null,
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_COMPANY:
      return {
        ...state,
        companies: [action.payload, ...state.companies],
      };
    case GET_COMPANIES_REQUEST:
      return {
        ...state,
        loading: true,
        companies: [],
        count: 0,
      };
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        companies: action.payload.companies,
        count: action.payload.count,
      };
    case DELETE_COMPANY:
      return {
        ...state,
        companies: state.companies.filter(
          (company) => company._id !== action.payload
        ),
        count: state.count - 1,
      };
    case UPDATE_COMPANY:
      return {
        ...state,
        companies: state.companies.map((company) =>
          company._id === action.payload._id ? action.payload : company
        ),
      };
    case UPDATE_COMPANY_ADVERTISEMENT:
      return {
        ...state,
        companies: state.companies.map((company) =>
          company._id === action.payload._id
            ? { ...company, advertise: action.payload.advertise }
            : company
        ),
      };
    default:
      return state;
  }
};

export default companyReducer;

import React, { Fragment, useEffect, useState } from "react";
import Papa from "papaparse";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Card } from "../../../components/Card/Card.style";
import { getServices } from "../../../../redux/actions/service";
import { Button } from "@mui/material";
import * as api from "../../../../redux/api";
import { getEquipmentBrands } from "../../../../redux/actions/equipmentBrand";
import { success, warning } from "../../../../utils/notification";
import { getAllIndividualUsers } from "../../../../redux/api/user.api";
import { getAllCompanies } from "../../../../redux/api/company.api";

let csvColumn = "";

const companiesSort = {
  name: 1,
  entityName: 0,
  description: 0,
  contacts: 0,
  offices: 0,
  services: 0,
};

const projectFields = [
  {
    name: "address",
    label: "Address",
  },
  {
    name: "company",
    label: "Company",
  },
  {
    name: "date",
    label: "Date",
  },
  {
    name: "cost",
    label: "Cost",
  },
  // {
  //   name: "workType",
  //   label: "Project Type",
  // },
];

const userTypes = [
  {
    _id: "individual",
    name: "Individual",
  },
  {
    _id: "business",
    name: "Business",
  },
];

const CsvUpload = ({ csvFile, setCsvFile }) => {
  const dispatch = useDispatch();
  const services = useSelector((state) => state.serviceReducer);
  const [loading, setLoading] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [workTypes, setWorkTypes] = useState([]);
  const [selectedWorkType, setSelectedWorkType] = useState("");
  const [csvColumns, setCsvColumns] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [serviceFields, setServiceFields] = useState([]);
  const [matchedColumns, setMatchedColumns] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState("");
  const [createdBySelectedValue, setCreatedBySelectedValue] = useState(null);
  const [companySearchText, setCompanySearchText] = useState("");
  const [companySelectedValue, setCompanySelectedValue] = useState(null);

  useEffect(() => {
    api
      .getUsers()
      .then(({ data }) => {
        setUsers(data);
      })
      .catch((err) => console.log(err));

    getAllCompanies()
      .then(({ data }) => {
        setCompanies(data);
      })
      .catch((err) => console.log(err));

    dispatch(getServices());
    dispatch(getEquipmentBrands());
    Papa.parse(csvFile, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setCsvData(results.data);
        setCsvColumns(Object.keys(results.data[0]));
      },
    });
  }, []);

  const handleChange = (e) => {
    if (e.target.name === "service") {
      setServiceFields([
        ...services[
          e.target.options[e.target.selectedIndex].dataset["serviceindex"]
        ].fields,
      ]);
      setSelectedService(e.target.value);
      setWorkTypes(
        services.find((service) => service._id === e.target.value).workTypes
      );
    } else if (e.target.name === "field") {
      let matchedArr = [...matchedColumns];
      let newPair = {
        csvColumn: e.target.options[e.target.selectedIndex].dataset.column,
        projectField: e.target.value,
        type: e.target.options[e.target.selectedIndex].dataset.type,
      };
      console.log(newPair);
      if (matchedArr.some((e) => e.csvColumn === csvColumn)) {
        matchedArr = matchedArr.filter((obj) => obj.csvColumn !== csvColumn);
        matchedArr.push(newPair);
      } else {
        matchedArr.push(newPair);
      }
      setMatchedColumns(matchedArr);
    } else if (e.target.name === "workType") {
      setSelectedWorkType(e.target.value);
    } else if (e.target.name === "userType") {
      setSelectedUserType(e.target.value);
    }
  };

  const uploadCsvFile = async () => {
    setLoading(true);
    const formData = new FormData();

    formData.append("serviceId", selectedService);
    formData.append("workTypeId", selectedWorkType);
    formData.append("userType", selectedUserType);
    if (selectedUserType === "business") {
      formData.append("companyId", companySelectedValue.value);
    } else {
      formData.append("createdBy", createdBySelectedValue.value);
    }
    formData.append("fields", JSON.stringify(serviceFields));
    formData.append("mapping", JSON.stringify(matchedColumns));
    formData.append("csvFile", csvFile);
    await api
      .uploadProjectsCsv(formData)
      .then(({ data }) => {
        success(data.message);
        setCsvFile(null);
        setLoading(false);
      })
      .catch((err) => {
        warning(`Error occured:  ${err.response.data.message}`);
        setLoading(false);
      });
  };

  return (
    <Card>
      <div className="container max-w-2xl mx-auto">
        <div className="grid grid-cols-2 gap-3 mb-6 items-center">
          <select
            name="service"
            value={selectedService}
            label="Service"
            onChange={handleChange}
            className="block w-full shadow-sm sm:text-sm focus:ring-green-500 focus:border-green-500 border-gray-300 rounded-md"
            required
          >
            <option value="">Select Service</option>
            {services.map(({ _id, name }, index) => (
              <option key={_id} value={_id} data-serviceindex={index}>
                {name}
              </option>
            ))}
          </select>
          <select
            name="workType"
            value={selectedWorkType}
            label="Work Type"
            onChange={handleChange}
            className="block w-full shadow-sm sm:text-sm focus:ring-green-500 focus:border-green-500 border-gray-300 rounded-md"
            required
          >
            <option value="">Select Work Type</option>
            {workTypes.map(({ _id, name }, index) => (
              <option key={_id} value={_id} data-serviceindex={index}>
                {name}
              </option>
            ))}
          </select>
          <select
            name="userType"
            value={selectedUserType}
            label="User Type"
            onChange={handleChange}
            className="block w-full shadow-sm sm:text-sm focus:ring-green-500 focus:border-green-500 border-gray-300 rounded-md"
            required
          >
            <option value="">Select User Type</option>
            {userTypes.map(({ _id, name }, index) => (
              <option key={_id} value={_id} data-serviceindex={index}>
                {name}
              </option>
            ))}
          </select>
          {selectedUserType === "individual" ? (
            <Select
              id="user"
              name="user"
              isMulti={false} // Change to true if you want to allow multiple selections
              className="bg-gray-50 block w-full shadow-sm focus:ring-primary focus:border-primary sm:text-sm border-gray-300 rounded-md"
              value={createdBySelectedValue}
              onChange={(selectedOption) => {
                setCreatedBySelectedValue(selectedOption);
              }}
              options={users?.map((user) => ({
                value: user._id,
                label: `${user.firstName} ${user.lastName}`,
              }))}
              placeholder="Select User"
            />
          ) : (
            selectedUserType === "business" && (
              <Select
                id="company"
                name="company"
                isMulti={false}
                className="bg-gray-50 block w-full shadow-sm focus:ring-primary focus:border-primary sm:text-sm border-gray-300 rounded-md"
                value={companySelectedValue}
                onChange={(selectedOption) =>
                  setCompanySelectedValue(selectedOption)
                }
                options={companies.map((company) => ({
                  value: company._id,
                  label: company.name,
                }))}
                placeholder="Select Company"
              />
            )
          )}
        </div>
        {/* Add spacing on both sides */}
        {csvColumns.map((column, index) => (
          <div className="grid grid-cols-2 mb-3 items-center" key={index}>
            <h3 className="text-lg">{column}</h3>
            <select
              name="field"
              // value={equipmentModel.Service}
              label="Match Field"
              className="block w-full shadow-sm sm:text-sm focus:ring-green-500 focus:border-green-500 border-gray-300 rounded-md"
              onChange={handleChange}
              required
            >
              <option value="" data-field="projectField">
                Select Field
              </option>
              {projectFields.map(({ label, name }) => (
                <option
                  key={name}
                  value={name}
                  data-field="projectField"
                  data-column={column}
                >
                  {label}
                </option>
              ))}
              {serviceFields.map(({ _id, label, name, type }) =>
                type === "measurement" ? (
                  <Fragment key={name}>
                    <option
                      value={`${name}`}
                      data-field="serviceField"
                      data-type="value"
                      data-column={column}
                    >
                      {`${label} value`}
                    </option>
                    <option
                      value={`${name}`}
                      data-field="serviceField"
                      data-type="unit"
                      data-column={column}
                    >
                      {`${label} unit`}
                    </option>
                  </Fragment>
                ) : (
                  <option
                    key={name}
                    value={name}
                    data-field="serviceField"
                    data-type={type}
                    data-column={column}
                  >
                    {label}
                  </option>
                )
              )}
            </select>
          </div>
        ))}
        <button
          onClick={uploadCsvFile}
          className="bg-green-600 text-white px-3 py-2 rounded-md"
          disabled={loading}
        >
          {loading ? "Uploading..." : "Upload"}
        </button>
      </div>
    </Card>
  );
};

export default CsvUpload;

import { TextField, Button } from "@mui/material";
import React from "react";
import { SearchBarContainer } from "./SearchBar.style";

const SearchBar = ({ value, onChange }) => {
  return (
    <SearchBarContainer>
      <TextField
        type="search"
        label="Search"
        size="small"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <Button variant="contained">Search</Button>
    </SearchBarContainer>
  );
};

export default SearchBar;

import * as type from "../types";

const initialOffice = {
  data: [],
  loading: false,
  error: null,
};

const officeReducer = (offices = [], action) => {
  switch (action.type) {
    case type.CREATE_OFFICE:
      return [action.payload, ...offices];
    case type.GET_OFFICES:
      return action.payload.reverse();
    case type.DELETE_OFFICE:
      return offices.filter((office) => office._id !== action.payload);
    case type.UPDATE_OFFICE:
      return offices.map((office) =>
        office._id === action.payload._id ? action.payload : office
      );
    default:
      return offices;
  }
};

export default officeReducer;

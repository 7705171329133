import { API } from "./api";

export const getEquipmentModels = (
  filter = null,
  sort = null,
  pagination = null
) =>
  API.get("/equipmentModels", {
    params: {
      filter,
      sort,
      skip: pagination?.skip || 0,
      limit: pagination?.limit || 10,
    },
  });

export const createEquipmentModel = (newEquipmentModel) =>
  API.post("/equipmentModels", newEquipmentModel);

export const updateEquipmentModel = (id, updatedEquipmentModel) =>
  API.patch(`/equipmentModels/${id}`, updatedEquipmentModel);

export const deleteEquipmentModel = (id) =>
  API.delete(`/equipmentModels/${id}`);

export const getConflictedEquipmentModels = () =>
  API.get("/equipmentModels/conflicts");

export const mergeEquipmentModels = (data) =>
  API.post("/equipmentModels/merge", data);

export const markEquipmentModelsAsDifferent = (equipmentModels) =>
  API.post(`/equipmentModels/markAsDifferent`, equipmentModels);

export const getEquipmentModelsByBrand = (brandId) =>
  API.get(`/equipmentModels/brand/${brandId}`);

import * as type from "../types";

const utilityReducer = (utilities = [], action) => {
  switch (action.type) {
    case type.CREATE_UTILITY:
      return [action.payload, ...utilities];
    case type.GET_UTILITIES:
      return action.payload.reverse();
    case type.DELETE_UTILITY:
      return utilities.filter((utility) => utility._id !== action.payload);
    case type.UPDATE_UTILITY:
      return utilities.map((utility) =>
        utility._id === action.payload._id ? action.payload : utility
      );
    default:
      return utilities;
  }
};

export default utilityReducer;

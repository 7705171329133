import * as api from "../api";
import * as type from "../types";
import { failure, success } from "../../utils/notification";

export const getEquipmentModels =
  (filter = null, sort = null, pagination = null) =>
  async (dispatch) => {
    try {
      const { data } = await api.getEquipmentModels(filter, sort, pagination);
      dispatch({ type: type.GET_EQUIPMENTMODELS, payload: data });
    } catch (error) {
      failure(error.message);
    }
  };

export const createEquipmentModel = (equipmentModel) => async (dispatch) => {
  try {
    const { data, status } = await api.createEquipmentModel(equipmentModel);
    if (status === 200) {
      dispatch({ type: type.CREATE_EQUIPMENTMODEL, payload: data });
      success("Saved");
    }
  } catch (error) {
    failure(error.message);
  }
};

export const updateEquipmentModel =
  (id, equipmentModel) => async (dispatch) => {
    try {
      const { data, status } = await api.updateEquipmentModel(
        id,
        equipmentModel
      );
      if (status === 200) {
        dispatch({ type: type.UPDATE_EQUIPMENTMODEL, payload: data });
        success("Updated");
      }
    } catch (error) {
      failure(error.message);
    }
  };

export const deleteEquipmentModel = (id) => async (dispatch) => {
  try {
    const { status } = await api.deleteEquipmentModel(id);
    if (status === 200) {
      dispatch({ type: type.DELETE_EQUIPMENTMODEL, payload: id });
      success("Deleted");
    }
  } catch (error) {
    failure(error.message);
  }
};

import * as api from "../api";
import * as type from "../types";
import { failure, success, warning } from "../../utils/notification";

export const getUsers = () => async (dispatch) => {
  try {
    const { data } = await api.getUsers();
    dispatch({ type: type.GET_USERS, payload: data });
  } catch (error) {
    failure(error.message);
  }
};

export const createUser = (service) => async (dispatch) => {
  try {
    const { data, status } = await api.createUser(service);
    if (status === 200) {
      dispatch({ type: type.CREATE_USER, payload: data });
      success("Saved");
    } else {
      warning(data.message);
    }
  } catch (error) {
    failure(error.message);
  }
};

export const updateUser = (id, service) => async (dispatch) => {
  try {
    const { data, status } = await api.updateUser(id, service);
    if (status === 200) {
      dispatch({ type: type.UPDATE_USER, payload: data });
      success("Updated");
    } else {
      warning(data.message);
    }
  } catch (error) {
    failure(error.message);
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    const { status } = await api.deleteUser(id);
    if (status === 200) {
      dispatch({ type: type.DELETE_USER, payload: id });
      success("Deleted");
    }
  } catch (error) {
    failure(error.message);
  }
};

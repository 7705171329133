import React, { useEffect, useState } from "react";
import {
  Card,
  CardFlex,
  CardHeader,
  Title,
} from "../../components/Card/Card.style";
import { Button, Chip, ListItem, Paper } from "@mui/material";
import ViewTable from "../../components/ViewTable/ViewTable";
import AddIcon from "@mui/icons-material/Add";
import SearchBar from "../../components/SearchBar/SearchBar";
import Form from "./Form/Form";
import { useDispatch, useSelector } from "react-redux";
import { confirmation } from "../../../utils/notification";
import { deleteRole, getRoles } from "../../../redux/actions/role";

const columnsName = ["Name", "Permissions"];

const Roles = () => {
  const roles = useSelector((state) => state.roleReducer);
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [id, setID] = useState(null);
  const [data, setData] = useState([]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - roles.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = (id = null, data = []) => {
    setID(id);
    setData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (id) => {
    confirmation().then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteRole(id));
      }
    });
  };

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  return (
    <CardFlex wrap="wrap" align="flex-start">
      <CardFlex flex="25rem" direction="column">
        <Form id={id} data={data} handleClose={handleClose} open={open} />
        <Card>
          <CardHeader>
            <Title>Roles</Title>
            <SearchBar />
            <Button
              variant="contained"
              onClick={() => handleClickOpen()}
              endIcon={<AddIcon />}
            >
              Add New Role
            </Button>
          </CardHeader>
          <div>
            <ViewTable
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              emptyRows={emptyRows}
              rows={roles}
              columnsName={columnsName}
            >
              {roles
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map(({ _id, name, permissions }, index) => (
                  <tr key={_id}>
                    <td>{index}</td>
                    <td>{name}</td>
                    <td>
                      {permissions.map(
                        (permission, index) =>
                          permission.actionsAllowed.length > 0 && (
                            <CardFlex
                              key={index}
                              align="center"
                              justify="center"
                            >
                              {permission.name}
                              {permission.actionsAllowed.map(
                                (actionAllowed, index) => (
                                  <ListItem key={index}>
                                    <Chip label={actionAllowed} size="small" />
                                  </ListItem>
                                )
                              )}
                            </CardFlex>
                          )
                      )}
                    </td>
                    <td>
                      <CardFlex justify="center">
                        <Button
                          onClick={() => handleDelete(_id)}
                          variant="contained"
                        >
                          Delete
                        </Button>
                        <Button
                          onClick={() =>
                            handleClickOpen(_id, {
                              name,
                              permissions,
                            })
                          }
                          variant="contained"
                        >
                          Update
                        </Button>
                      </CardFlex>
                    </td>
                  </tr>
                ))}
            </ViewTable>
          </div>
        </Card>
      </CardFlex>
    </CardFlex>
  );
};

export default Roles;

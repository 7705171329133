import React, { useEffect, useState } from "react";
import { LuImagePlus } from "react-icons/lu";
import { failure, success } from "../../../utils/notification";
import {
  createMarkerIcon,
  getMarkerIcon,
  updateMarkerIcon,
} from "../../../redux/api/settings.api";
import { baseURL } from "../../../redux/api/api";
import { useSelector } from "react-redux";

function AppearanceSettings() {
  const auth = useSelector((state) => state.authReducer);
  const defaultAppearanceSettingsId = "66a4cf7d78c14ba54dbea355";
  const [selectedImage, setSelectedImage] = useState(null);
  const [officeIcon, setOfficeIcon] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  useEffect(() => {
    // console.log("auth is", auth);
    getMarkerIcon()
      .then((res) => {
        setOfficeIcon(res?.data);
        console.log("data is", res?.data);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("officeImageMarker", selectedImage);
    formData.append("type", "default");

    if (officeIcon._id) {
      updateMarkerIcon(officeIcon._id, formData)
        .then((res) => {
          console.log(res.data);
          success("Updated", "Icon Updated Successfully");
        })
        .catch((err) => {
          console.log("Error updating icon", err);
        });
    } else {
      createMarkerIcon(formData)
        .then((res) => {
          console.log(res.data);
          success("Created", "Icon Created Successfully");
        })
        .catch((err) => {
          failure(err?.response?.data?.message);
          console.log("Error Creating icon", err);
        });
    }
  };

  return (
    <>
      <h1 className="text-lg text-gray-700">Appearance Settings</h1>
      <form onSubmit={handleSubmit} className="w-72">
        <div className="py-0.5 md:py-1.5 lg:py-1.5 items-center flex items-center justify-between ">
          <div className="">
            <label
              htmlFor="officeImageMarker"
              className="block text-base leading-6 text-gray-700"
            >
              Office Image Marker
            </label>
          </div>
          <div className="text-left ">
            <div className="mt-2 ">
              <label
                htmlFor="officeImageMarker"
                className="mx-auto bg-gray-100 rounded-full h-28 w-28 inset-0 flex items-center justify-center cursor-pointer"
              >
                {officeIcon?.officeMarkerIcon && (
                  <img
                    src={
                      selectedImage
                        ? URL.createObjectURL(selectedImage)
                        : `${baseURL}s3/images/${officeIcon?.officeMarkerIcon}`
                    }
                    alt="marker icon"
                    crossorigin="anonymous"
                    className="rounded-full h-full w-full object-cover"
                  />
                )}
              </label>
              <input
                type="file"
                name="officeImageMarker"
                id="officeImageMarker"
                className="hidden"
                onChange={handleFileChange}
              />
            </div>
          </div>
        </div>
        <div className="mt-5 flex justify-end">
          <button
            type="submit"
            className="flex justify-center rounded-md bg-green-600 py-2.5 px-8 text-base font-semibold leading-6 text-white shadow-sm hover:bg-opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow"
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
}

export default AppearanceSettings;

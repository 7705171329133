import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CardFlex } from "../../../components/Card/Card.style";
import { Button, TextField } from "@mui/material";
import {
  createEquipmentType,
  updateEquipmentType,
} from "../../../../redux/actions/equipmentType";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Select } from "@mui/material";

const Form = ({ id, data, handleClose, open }) => {
  const dispatch = useDispatch();
  const services = useSelector((state) => state.serviceReducer);
  const [equipmentTypes, setEquipmentTypes] = useState({
    name: "",
    services: [],
  });

  const handleChange = (e) => {
    setEquipmentTypes((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (id) {
      dispatch(updateEquipmentType(id, equipmentTypes));
    } else {
      dispatch(createEquipmentType(equipmentTypes));
    }
    clearData();
  };

  const clearData = () => {
    setEquipmentTypes({ name: "", services: [] });
    handleClose();
  };

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setEquipmentTypes(data);
    }
  }, [data]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{id ? "Update" : "Add"} Equipment Types</DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent>
          <CardFlex direction="column">
            <TextField
              value={equipmentTypes.name}
              name="name"
              onChange={handleChange}
              size="small"
              label="Name"
              required
            />
            <FormControl size="small" sx={{ flex: 1, minWidth: 160 }}>
              <InputLabel>Services</InputLabel>
              <Select
                name="services"
                value={equipmentTypes.services}
                label="Services"
                onChange={handleChange}
                multiple
              >
                {services.map(({ _id, name }) => (
                  <MenuItem key={_id} value={_id}>
                    {name.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardFlex>
        </DialogContent>
        <DialogActions>
          <Button onClick={clearData} variant="contained" autoFocus>
            cancel
          </Button>
          <Button variant="contained" type="submit">
            {id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Form;

import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CardFlex } from "../../../components/Card/Card.style";
import { Button, TextField } from "@mui/material";
import {
  createEquipmentModel,
  updateEquipmentModel,
} from "../../../../redux/actions/equipmentModel";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Select } from "@mui/material";
import { getEquipmentBrandsByType } from "../../../../redux/api/equipmentBrand.api";

const initialData = {
  name: "",
  type: "",
  brand: "",
};

const Form = ({ id, data, handleClose, open }) => {
  const dispatch = useDispatch();
  const equipmentTypes = useSelector((state) => state.equipmentTypeReducer);
  const [equipmentBrands, setEquipmentBrands] = useState([]);
  const [equipmentModel, setEquipmentModel] = useState(initialData);

  useEffect(() => {
    if (equipmentModel?.type !== "") {
      getEquipmentBrandsByType(equipmentModel.type).then((response) => {
        setEquipmentBrands(response.data);
      });
    }
  }, [equipmentModel?.type]);

  const handleChange = (e) => {
    setEquipmentModel((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (id) {
      dispatch(updateEquipmentModel(id, equipmentModel));
    } else {
      dispatch(createEquipmentModel(equipmentModel));
    }
    clearData();
  };

  const clearData = () => {
    setEquipmentModel(initialData);
    handleClose();
  };

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setEquipmentModel(data);
    }
  }, [data]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{id ? "Update" : "Add"} Equipment Model</DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent>
          <CardFlex direction="column">
            <TextField
              value={equipmentModel.name}
              required
              name="name"
              onChange={handleChange}
              size="small"
              label="Name"
            />
            <FormControl size="small" sx={{ flex: 1, minWidth: 160 }}>
              <InputLabel>Type</InputLabel>
              <Select
                name="type"
                value={equipmentModel.type}
                label="Type"
                onChange={handleChange}
                required
              >
                {equipmentTypes.map(({ _id, name }) => (
                  <MenuItem key={_id} value={_id}>
                    {name.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" fullWidth>
              <InputLabel>Brand</InputLabel>
              <Select
                name="brand"
                value={equipmentModel.brand}
                label="Brand"
                onChange={handleChange}
                required
              >
                {equipmentBrands?.map(({ _id, name }) => (
                  <MenuItem key={_id} value={_id}>
                    {name.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardFlex>
        </DialogContent>
        <DialogActions>
          <Button onClick={clearData} variant="contained" autoFocus>
            cancel
          </Button>
          <Button variant="contained" type="submit">
            {id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Form;

import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CardFlex } from "../../../components/Card/Card.style";
import { Button, Divider, TextField } from "@mui/material";
import {
  createService,
  updateService,
} from "../../../../redux/actions/service";
import { useDispatch } from "react-redux";
import ViewFields from "./Field/ViewFields";
import CreateFields from "./Field/CreateFields";

const initialField = {
  type: "",
  label: "",
  name: "",
  module: "",
  filter: "",
  required: false,
};

const Form = ({ id, data, handleClose, open, services }) => {
  const dispatch = useDispatch();
  const [service, setService] = useState({ name: "" });
  const [field, setField] = useState(initialField);
  const [fields, setFields] = useState([]);
  const [fieldId, setFieldId] = useState(null);

  const handleFieldChange = (e) => {
    let { name, value } = e.target;
    value = name === "name" ? value.toLowerCase().replace(/\s+/g, "_") : value;
    setField((prev) => ({
      ...prev,
      [name]: name === "required" ? e.target.checked : value,
      [name]: name === "filterable" ? e.target.checked : value,
    }));
  };

  const handleFieldSubmit = (e) => {
    e.preventDefault();
    const bool = fields?.find(
      (f, id) => f.name === field.name && id !== fieldId && f.name !== ""
    );
    console.log(bool);
    if (!bool) {
      if (fieldId !== null) {
        const newFields = fields.map((f, id) => (id === fieldId ? field : f));
        setFields([...newFields]);
      } else {
        field !== []
          ? setFields((prev) => [...prev, { ...field }])
          : setFields([{ ...field }]);
      }
      clearField();
      console.log("Fields", fields);
    } else {
      alert("Field name already exist");
    }
  };

  const clearField = () => {
    setField(initialField);
    setFieldId(null);
  };

  const handleChange = (e) => {
    setService((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (id) {
      dispatch(updateService(id, { name: service.name, fields }));
    } else {
      dispatch(createService({ name: service.name, fields }));
    }
    clearData();
  };

  const clearData = () => {
    setService({ name: "" });
    setFields([]);
    handleClose();
    clearField();
  };

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setService({ name: data?.name });
      setFields(data?.fields);
    }
  }, [data]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{id ? "Update" : "Add"} Service</DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent>
          <CardFlex direction="column">
            <TextField
              value={service.name}
              name="name"
              onChange={handleChange}
              size="small"
              label="Name"
              autoFocus
              required
            />
          </CardFlex>
        </DialogContent>
        <DialogActions
          style={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <Button onClick={clearData} variant="contained">
            cancel
          </Button>
          <Button variant="contained" type="submit">
            {id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </form>
      <DialogContent>
        <Divider />
      </DialogContent>
      <DialogContent>
        <CardFlex justify="space-between">
          <CreateFields
            services={services}
            setField={setField}
            field={field}
            fieldId={fieldId}
            handleFieldChange={handleFieldChange}
            handleFieldSubmit={handleFieldSubmit}
          />
          <Divider orientation="vertical" />
          <ViewFields
            fields={fields}
            setFields={setFields}
            setField={setField}
            setFieldId={setFieldId}
          />
        </CardFlex>
      </DialogContent>
    </Dialog>
  );
};

export default Form;

import * as React from "react";
import { styled } from "@mui/material/styles";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

const Input = styled("input")({
  display: "none",
});

export default function UploadLogo({ handleChange, name, id }) {
  return (
    <label htmlFor="icon-button-file">
      <Input
        accept="image/*"
        name={name}
        onChange={handleChange}
        id="icon-button-file"
        type="file"
        size="small"
        // required={id ? false : true}
      />
      <PhotoCamera color="primary" />
    </label>
  );
}

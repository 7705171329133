import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import LeftDrawer from "../../components/LeftDrawer/LeftDrawer";
import Main from "../Main/Main";
import { AdminPanelContainer } from "./AdminPanel.style";
import { useNavigate } from "react-router-dom";

const AdminPanel = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!localStorage.getItem("admin")) {
      navigate("login");
    }
  }, [navigate]);

  return (
    <AdminPanelContainer>
      <Header open={open} handleDrawerOpen={handleDrawerOpen} />
      <LeftDrawer open={open} handleDrawerClose={handleDrawerClose} />
      <Main />
    </AdminPanelContainer>
  );
};

export default AdminPanel;

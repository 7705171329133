import styled from "styled-components";

export const CardFlex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  gap: 1rem;
  flex-wrap: ${(props) => props.wrap};
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  flex: ${(props) => props.flex};
  .title {
    font-size: 1rem;
    font-weight: 550;
    text-transform: capitalize;
  }
  .caption-sm {
    font-size: 1.2rem;
    font-weight: 650;
  }
`;

export const Card = styled.div`
  width: ${(props) => props.width};
  padding: 1.5rem;
  border-radius: 7px;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.2);
  flex: ${(props) => props.flex};
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#fff")};
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.content ? props.content : "space-between"};
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`;

export const Title = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${(props) => props.theme.main};
`;

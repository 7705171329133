import React from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar } from "./Header.style";
import Logo from "../Logo/Logo";

const Header = ({ open, handleDrawerOpen }) => {
  return (
    <AppBar open={open}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: "36px",
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Logo />
      </Toolbar>
    </AppBar>
  );
};

export default Header;

import React, { useState, useEffect } from "react";
import { LoginForm, Container, SubContainer } from "./Login.style";
import Input from "./Input/Input";
import { useNavigate } from "react-router-dom";
import { Title } from "../../components/Card/Card.style";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
// import { signin } from "../../../redux/api";
import { signin } from "../../../redux/actions/auth";
import logo from "../../assets/images/logo.png";
import { FaEyeSlash } from "react-icons/fa";
import { MdRemoveRedEye } from "react-icons/md";

const initialData = { email: "", password: "", role: "admin" };

const Login = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleShowPassword = () => setShowPassword((prev) => !prev);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(signin(formData, navigate));
  };

  useEffect(() => {
    localStorage.removeItem("admin");
  }, []);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img alt="Your Company" src={logo} className="mx-auto h-10 w-auto" />
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={handleChange}
                  value={formData.email}
                  placeholder="Enter your email"
                  required
                  autoComplete="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
              <div className="mt-2 relative">
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  onChange={handleChange}
                  value={formData.password}
                  placeholder="Enter your password"
                  required
                  autoComplete="current-password"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                />
                <button
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                  onClick={() => handleShowPassword()}
                  type="button"
                >
                  {showPassword ? (
                    <FaEyeSlash className="cursor-pointer text-gray-400 text-2xl" />
                  ) : (
                    <MdRemoveRedEye className="cursor-pointer text-gray-400 text-2xl" />
                  )}
                </button>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-3 block text-sm leading-6 text-gray-900"
                >
                  Remember me
                </label>
              </div>

              {/* <div className="text-sm leading-6">
                <a
                  href="#"
                  className="font-semibold text-green-600 hover:text-green-500"
                >
                  Forgot password?
                </a>
              </div> */}
            </div>

            <div>
              <button
                type="submit"
                disabled={loading}
                className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                {loading ? (
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V2.5"
                    ></path>
                  </svg>
                ) : (
                  "Sign in"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    // <Container>
    //   {/* <Header /> */}
    //   <SubContainer>
    //     <LoginForm onSubmit={handleSubmit}>
    //       <Title>Login</Title>
    //       <Input
    //         name="email"
    //         label="Email"
    //         handleChange={handleChange}
    //         type="email"
    //       />
    //       <Input
    //         name="password"
    //         label="Password"
    //         handleChange={handleChange}
    //         type={showPassword ? "text" : "password"}
    //         handleShowPassword={handleShowPassword}
    //       />
    //       <Button style={{ width: "100%" }} variant="contained" type="submit">
    //         Login
    //       </Button>
    //     </LoginForm>
    //   </SubContainer>
    //   {/* <Footer /> */}
    // </Container>
  );
};

export default Login;

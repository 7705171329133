import { API } from "./api";

export const getProjects = () => API.get("/projects");

export const createProject = (newProject) => API.post("/projects", newProject);

export const uploadProjectsCsv = (newData) =>
  API.post("/projects/uploadCsv", newData);

export const submitResolvedBrandConflicts = (newData) =>
  API.post("/equipmentBrands/addConflictedBrands", newData);

export const updateProject = (id, updatedProject) =>
  API.patch(`/projects/${id}`, updatedProject);

export const deleteProject = (id) => API.delete(`/projects/${id}`);

export const getUploadRequests = () => API.get(`/projectsUploads/`);
export const getProjectById = (id) => API.get(`/projects/id/${id}`);
export const getProjectsCreatedByUser = (id, filter) => {
  return API.post(`/projects/createdBy/${id}`, filter);
};
export const getProjectsCreatedByCompany = (id, filter) => {
  return API.post(`/projects/createdBy/company/${id}`, filter);
};
export const getProjectsUsingEquipmentBrand = (id, filter) => {
  return API.post(`/projects/using/equipmentBrand/${id}`, filter);
};
export const getServicesByUserProjects = (id) => {
  return API.get(`/services/user/${id}/projects`);
};
export const getServicesByCompanyProjects = (id) => {
  return API.get(`/services/company/${id}/projects`);
};

import React, { useEffect, useState } from "react";
import {
  Card,
  CardFlex,
  CardHeader,
  Title,
} from "../../components/Card/Card.style";
import { Button } from "@mui/material";
import ViewTable from "../../components/ViewTable/ViewTable";
import AddIcon from "@mui/icons-material/Add";
import SearchBar from "../../components/SearchBar/SearchBar";
import Form from "./Form/Form";
import { getOffices, deleteOffice } from "../../../redux/actions/office";
import { useDispatch, useSelector } from "react-redux";
import { confirmation } from "../../../utils/notification";

const columnsName = [
  "Name",
  "Phone No",
  "Address",
  "Web Site to Direct",
  "Web Site to Show",
  "Location",
  "Head Office",
];

const Offices = () => {
  const offices = useSelector((state) => state.officeReducer);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [id, setID] = useState(null);
  const [data, setData] = useState([]);

  const handleClickOpen = (id = null, data = []) => {
    setID(id);
    setData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (id) => {
    confirmation().then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteOffice(id));
      }
    });
  };

  useEffect(() => {
    dispatch(getOffices());
  }, [dispatch]);

  return (
    <CardFlex wrap="wrap" align="flex-start">
      <CardFlex flex="25rem" direction="column">
        <Form id={id} data={data} handleClose={handleClose} open={open} />
        <Card>
          <CardHeader>
            <Title>Offices</Title>
            <SearchBar />
            <Button
              variant="contained"
              onClick={() => handleClickOpen()}
              endIcon={<AddIcon />}
            >
              Add Office
            </Button>
          </CardHeader>
          <div>
            <ViewTable rows={offices} columnsName={columnsName}>
              {offices.map(
                (
                  {
                    _id,
                    name,
                    phoneNo,
                    address,
                    websiteToDirect,
                    websiteToShow,
                    isHeadOffice,
                    location,
                  },
                  index
                ) => (
                  <tr key={_id}>
                    <td>{index + 1}</td>
                    <td>{name}</td>
                    <td>{phoneNo}</td>
                    <td>{address}</td>
                    <td>{websiteToDirect}</td>
                    <td>{websiteToShow}</td>
                    <td>{isHeadOffice}</td>
                    <td>{location}</td>
                    <td>
                      <CardFlex justify="center">
                        <Button
                          onClick={() => handleDelete(_id)}
                          variant="contained"
                        >
                          Delete
                        </Button>
                        <Button
                          onClick={() =>
                            handleClickOpen(_id, {
                              name,
                              phoneNo,
                              address,
                              websiteToDirect,
                              websiteToShow,
                              isHeadOffice,
                              location,
                            })
                          }
                          variant="contained"
                        >
                          Update
                        </Button>
                      </CardFlex>
                    </td>
                  </tr>
                )
              )}
            </ViewTable>
          </div>
        </Card>
      </CardFlex>
    </CardFlex>
  );
};

export default Offices;

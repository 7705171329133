import * as api from "../api";
import * as type from "../types";
import { failure, success } from "../../utils/notification";

export const getRoles = () => async (dispatch) => {
  try {
    const { data } = await api.getRoles();
    dispatch({ type: type.GET_ROLES, payload: data });
  } catch (error) {
    failure(error.message);
  }
};

export const createRole = (role) => async (dispatch) => {
  try {
    const { data, status } = await api.createRole(role);
    if (status === 200) {
      dispatch({ type: type.CREATE_ROLE, payload: data });
      success("Saved");
    }
  } catch (error) {
    failure(error.message);
  }
};

export const updateRole = (id, role) => async (dispatch) => {
  try {
    const { data, status } = await api.updateRole(id, role);
    if (status === 200) {
      dispatch({ type: type.UPDATE_ROLE, payload: data });
      success("Updated");
    }
  } catch (error) {
    failure(error.message);
  }
};

export const deleteRole = (id) => async (dispatch) => {
  try {
    const { status } = await api.deleteRole(id);
    if (status === 200) {
      dispatch({ type: type.DELETE_ROLE, payload: id });
      success("Deleted");
    }
  } catch (error) {
    failure(error.message);
  }
};

import { API } from "./api";

export const getUtilities = () => API.get("/utilities");

export const createUtility = (newUtility) => API.post("/utilities", newUtility);

export const updateUtility = (id, updatedUtility) =>
  API.patch(`/utilities/${id}`, updatedUtility);

export const deleteUtility = (id) => API.delete(`/utilities/${id}`);

export const mergeUtilities = (data) => API.post("/utilities/merge", data);

export const markUtilitiesAsDifferent = (utilities) =>
  API.post(`/utilities/markAsDifferent`, utilities);

import { API } from "./api";

export const getRoles = () => API.get("/roles");

export const createRole = (newRole) => API.post("/roles", newRole);

export const updateRole = (id, updatedRole) =>
  API.patch(`/roles/${id}`, updatedRole);

export const deleteRole = (id) => API.delete(`/roles/${id}`);

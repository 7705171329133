import axios from "axios";

export const baseURL = process.env.REACT_APP_API_URL;

export const API = axios.create({
  baseURL: baseURL,
});

API.interceptors.request.use((req) => {
  const profile =
    localStorage.getItem("admin") ||
    localStorage.getItem("company") ||
    localStorage.getItem("brand");
  if (profile) {
    const token = JSON.parse(profile).token;
    req.headers.authorization = `Bearer ${token}`;
  }
  return req;
});

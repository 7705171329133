import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CardFlex } from "../../../components/Card/Card.style";
import { Autocomplete, Button, TextField } from "@mui/material";
import {
  createCompany,
  updateCompany,
} from "../../../../redux/actions/company";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Select, Chip } from "@mui/material";
import UploadLogo from "../../../components/UploadLogo/UploadLogo";

const initialData = {
  name: "",
  entityName: "",
  logo: "",
  description: "",
  claimed: true,
  contacts: [],
  offices: [],
  services: [],
  users: [],
  brandsOffered: [],
};

const initialContactData = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNo: "",
  offices: [],
  active: true,
};

const initialOfficeData = {
  name: "",
  phoneNo: "",
  address: "",
  websiteToDirect: "",
  websiteToShow: "",
  isHeadOffice: false,
  location: "",
};

const initialUserData = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  role: "company",
};

const Form = ({ id, data, handleClose, open }) => {
  const dispatch = useDispatch();
  const services = useSelector((state) => state.serviceReducer);
  const { equipmentBrands } = useSelector(
    (state) => state.equipmentBrandReducer
  );
  const [company, setCompany] = useState(initialData);
  const [toggleOffice, setToggleOffice] = useState(false);
  const [toggleContact, setToggleContact] = useState(false);
  const [toggleUser, setToggleUser] = useState(false);
  const [office, setOffice] = useState(initialOfficeData);
  const [contact, setContact] = useState(initialContactData);
  const [user, setUser] = useState(initialUserData);
  const [officeUpdateId, setOfficeUpdateId] = useState(null);
  const [contactUpdateId, setContactUpdateId] = useState(null);
  const [userUpdateId, setUserUpdateId] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleOfficeChange = (e) => {
    setOffice((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleOfficeDelete = (id) => {
    const result = window.confirm(
      "Are you sure you want to delete this office."
    );
    if (result) {
      const newOffices = company.offices.filter(
        (office, index) => index !== id
      );
      setCompany((prev) => ({ ...prev, offices: [...newOffices] }));
    }
  };

  const handleOfficeUpdate = (id) => {
    setOffice(company.offices[id]);
    setOfficeUpdateId(id);
  };

  const handleOfficeSubmit = (e) => {
    officeUpdateId !== null
      ? setCompany((prev) => ({
          ...prev,
          offices: company.offices.map((o, id) =>
            officeUpdateId === id ? office : o
          ),
        }))
      : setCompany((prev) => ({
          ...prev,
          offices: company.offices ? [...company.offices, office] : [office],
        }));
    clearOfficeData();
  };

  const clearOfficeData = () => {
    setOffice(initialOfficeData);
    setOfficeUpdateId(null);
  };

  const handleContactChange = (e) => {
    setContact((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleContactDelete = (id) => {
    const result = window.confirm(
      "Are you sure you want to delete this contact."
    );
    if (result) {
      const newContacts = company.contacts.filter(
        (contact, index) => index !== id
      );
      setCompany((prev) => ({ ...prev, contacts: [...newContacts] }));
    }
  };

  const handleContactUpdate = (id) => {
    setContact(company.contacts[id]);
    setContactUpdateId(id);
  };

  const handleContactSubmit = (e) => {
    contactUpdateId !== null
      ? setCompany((prev) => ({
          ...prev,
          contacts: company.contacts.map((c, id) =>
            contactUpdateId === id ? contact : c
          ),
        }))
      : setCompany((prev) => ({
          ...prev,
          contacts: company.contacts
            ? [...company.contacts, contact]
            : [contact],
        }));
    clearContactData();
  };

  const clearContactData = () => {
    setContact(initialContactData);
    setContactUpdateId(null);
  };

  const handleUserChange = (e) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleUserDelete = (id) => {
    const result = window.confirm("Are you sure you want to delete this user.");
    if (result) {
      const newUsers = company.users.filter((user, index) => index !== id);
      setCompany((prev) => ({ ...prev, users: [...newUsers] }));
    }
  };

  const handleUserUpdate = (id) => {
    setUser(company.users[id]);
    setUserUpdateId(id);
  };

  const handleUserSubmit = (e) => {
    if (user.password === confirmPassword) {
      userUpdateId !== null
        ? setCompany((prev) => ({
            ...prev,
            users: company.users.map((u, id) =>
              userUpdateId === id ? user : u
            ),
          }))
        : setCompany((prev) => ({
            ...prev,
            users: company.users ? [...company.users, user] : [user],
          }));
      clearUserData();
    } else {
      alert("Your password didn't match.");
    }
  };

  const clearUserData = () => {
    setUser(initialUserData);
    setConfirmPassword("");
    setUserUpdateId(null);
  };

  const handleChange = (e) => {
    if (e.target.name === "logo") {
      setCompany((prev) => ({
        ...prev,
        [e.target.name]: e.target.files[0],
      }));
    } else {
      setCompany((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in company) {
      if (Object.hasOwnProperty.call(company, key)) {
        if (
          key === "services" ||
          key === "offices" ||
          key === "contacts" ||
          key === "brandsOffered" ||
          key === "users"
        ) {
          formData.append(key, JSON.stringify(company[key]));
        } else {
          formData.append(key, company[key]);
        }
      }
    }
    if (id) {
      dispatch(updateCompany(id, formData));
    } else {
      dispatch(createCompany(formData));
    }
    clearData();
  };

  const clearData = () => {
    setCompany(initialData);
    clearContactData();
    clearOfficeData();
    setToggleContact(false);
    setToggleOffice(false);
    handleClose();
  };

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      console.log(data);
      setCompany(data);
    } else {
      setCompany(initialData);
    }
  }, [data]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{id ? "Update" : "Add"} Company</DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent>
          <CardFlex direction="column">
            <TextField
              value={company.name}
              name="name"
              onChange={handleChange}
              size="small"
              label="Company Name"
              required
            />
            <TextField
              value={company.entityName}
              name="entityName"
              onChange={handleChange}
              size="small"
              label="Entity Name"
            />
            <UploadLogo handleChange={handleChange} name={"logo"} id={id} />
            <TextField
              multiline
              rows={4}
              value={company.description}
              name="description"
              onChange={handleChange}
              size="small"
              label="Description"
            />
            <CardFlex direction="column">
              <Button
                onClick={() => {
                  setToggleOffice((prev) => !prev);
                  if (!toggleOffice) {
                    setToggleContact(false);
                    setToggleUser(false);
                  }
                }}
                variant="contained"
                autoFocus
              >
                Add Offices
              </Button>
              <CardFlex
                direction="column"
                style={{ display: toggleOffice ? "flex" : "none" }}
              >
                <div className="title">
                  {officeUpdateId !== null ? "Update" : "Add"} Office
                </div>
                <CardFlex direction="column">
                  <CardFlex>
                    <TextField
                      fullWidth
                      value={office.name}
                      name="name"
                      onChange={handleOfficeChange}
                      size="small"
                      label="Name"
                    />
                    <TextField
                      fullWidth
                      value={office.location}
                      name="location"
                      onChange={handleOfficeChange}
                      size="small"
                      label="Location"
                    />
                  </CardFlex>
                  <TextField
                    fullWidth
                    type="tel"
                    value={office.phoneNo}
                    name="phoneNo"
                    onChange={handleOfficeChange}
                    size="small"
                    label="Phone No"
                  />
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    value={office.address}
                    name="address"
                    onChange={handleOfficeChange}
                    size="small"
                    label="Address"
                  />
                  <CardFlex>
                    <TextField
                      fullWidth
                      type="url"
                      value={office.websiteToDirect}
                      name="websiteToDirect"
                      onChange={handleOfficeChange}
                      size="small"
                      label="Web Site to Direct"
                    />
                    <TextField
                      fullWidth
                      type="url"
                      value={office.websiteToShow}
                      name="websiteToShow"
                      onChange={handleOfficeChange}
                      size="small"
                      label="Web Site to Show"
                    />
                  </CardFlex>

                  <CardFlex>
                    <Button
                      onClick={clearOfficeData}
                      variant="contained"
                      autoFocus
                    >
                      cancel
                    </Button>
                    <Button
                      variant="contained"
                      type="button"
                      onClick={handleOfficeSubmit}
                    >
                      {officeUpdateId !== null ? "Update" : "Add"}
                    </Button>
                  </CardFlex>
                  <CardFlex
                    wrap="wrap"
                    align="center"
                    style={{ gap: "0.2rem" }}
                  >
                    {company.offices.map((office, id) => (
                      <Chip
                        key={id}
                        label={office?.name}
                        color="primary"
                        onClick={() => handleOfficeUpdate(id)}
                        onDelete={() => handleOfficeDelete(id)}
                      />
                    ))}
                  </CardFlex>
                </CardFlex>
              </CardFlex>
              <Button
                onClick={() => {
                  setToggleContact((prev) => !prev);
                  if (!toggleContact) {
                    setToggleOffice(false);
                    setToggleUser(false);
                  }
                }}
                variant="contained"
                autoFocus
              >
                Add Contacts
              </Button>
              <CardFlex
                direction="column"
                style={{ display: toggleContact ? "flex" : "none" }}
              >
                <div className="title">
                  {contactUpdateId !== null ? "Update" : "Add"} Contact
                </div>
                <CardFlex direction="column">
                  <CardFlex>
                    <TextField
                      fullWidth
                      value={contact.firstName}
                      name="firstName"
                      onChange={handleContactChange}
                      size="small"
                      label="First Name"
                    />
                    <TextField
                      fullWidth
                      value={contact.lastName}
                      name="lastName"
                      onChange={handleContactChange}
                      size="small"
                      label="Last Name"
                    />
                  </CardFlex>
                  <TextField
                    fullWidth
                    type="email"
                    value={contact.email}
                    name="email"
                    onChange={handleContactChange}
                    size="small"
                    label="Email"
                  />
                  <TextField
                    fullWidth
                    type="tel"
                    value={contact.phoneNo}
                    name="phoneNo"
                    onChange={handleContactChange}
                    size="small"
                    label="Phone No"
                  />
                  <FormControl size="small" fullWidth>
                    <InputLabel>Offices</InputLabel>
                    <Select
                      name="offices"
                      value={contact.offices}
                      label="Offices"
                      onChange={handleContactChange}
                      multiple
                    >
                      {company.offices.map((office, id) => (
                        <MenuItem key={id} value={office?.name}>
                          {office?.name?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <CardFlex>
                    <Button
                      onClick={clearContactData}
                      variant="contained"
                      autoFocus
                    >
                      cancel
                    </Button>
                    <Button
                      variant="contained"
                      type="button"
                      onClick={handleContactSubmit}
                    >
                      {contactUpdateId !== null ? "Update" : "Add"}
                    </Button>
                  </CardFlex>
                  <CardFlex
                    wrap="wrap"
                    align="center"
                    style={{ gap: "0.2rem" }}
                  >
                    {company.contacts.map((contact, id) => (
                      <Chip
                        key={id}
                        label={contact?.firstName + " " + contact?.lastName}
                        color="primary"
                        onClick={() => handleContactUpdate(id)}
                        onDelete={() => handleContactDelete(id)}
                      />
                    ))}
                  </CardFlex>
                </CardFlex>
              </CardFlex>
              {!id && (
                <>
                  <Button
                    onClick={() => {
                      setToggleUser((prev) => !prev);
                      if (!toggleUser) {
                        setToggleOffice(false);
                        setToggleContact(false);
                      }
                    }}
                    variant="contained"
                    autoFocus
                  >
                    Add Users
                  </Button>
                  <CardFlex
                    direction="column"
                    style={{ display: toggleUser ? "flex" : "none" }}
                  >
                    <div className="title">
                      {userUpdateId !== null ? "Update" : "Add"} User
                    </div>
                    <CardFlex direction="column">
                      <CardFlex>
                        <TextField
                          fullWidth
                          value={user.firstName}
                          name="firstName"
                          onChange={handleUserChange}
                          size="small"
                          label="First Name"
                        />
                        <TextField
                          fullWidth
                          value={user.lastName}
                          name="lastName"
                          onChange={handleUserChange}
                          size="small"
                          label="Last Name"
                        />
                      </CardFlex>
                      <TextField
                        fullWidth
                        type="email"
                        value={user.email}
                        name="email"
                        onChange={handleUserChange}
                        size="small"
                        label="Email"
                      />
                      <TextField
                        fullWidth
                        type="password"
                        value={user.password}
                        name="password"
                        onChange={handleUserChange}
                        size="small"
                        label="Password"
                      />
                      <TextField
                        fullWidth
                        type="password"
                        value={confirmPassword}
                        name="confirmPassword"
                        size="small"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        label="Confirm Password"
                        autoComplete="false"
                      />
                      <CardFlex>
                        <Button
                          onClick={clearUserData}
                          variant="contained"
                          autoFocus
                        >
                          cancel
                        </Button>
                        <Button
                          variant="contained"
                          type="button"
                          onClick={handleUserSubmit}
                        >
                          {userUpdateId !== null ? "Update" : "Add"}
                        </Button>
                      </CardFlex>
                      <CardFlex
                        wrap="wrap"
                        align="center"
                        style={{ gap: "0.2rem" }}
                      >
                        {company.users?.map((user, id) => (
                          <Chip
                            key={id}
                            label={user?.firstName + " " + user?.lastName}
                            color="primary"
                            onClick={() => handleUserUpdate(id)}
                            onDelete={() => handleUserDelete(id)}
                          />
                        ))}
                      </CardFlex>
                    </CardFlex>
                  </CardFlex>
                </>
              )}
            </CardFlex>
            <FormControl size="small" fullWidth>
              <InputLabel>Services</InputLabel>
              <Select
                name="services"
                value={company.services}
                label="Services"
                onChange={handleChange}
                multiple
                required
              >
                {services.map(({ _id, name }) => (
                  <MenuItem key={_id} value={_id}>
                    {name.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" fullWidth>
              <InputLabel>Brands Offered</InputLabel>
              <Select
                name="brandsOffered"
                value={company.brandsOffered}
                label="Brands Offered"
                onChange={handleChange}
                multiple
                // required
              >
                {equipmentBrands?.map(({ _id, name }) => (
                  <MenuItem key={_id} value={_id}>
                    {name.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardFlex>
        </DialogContent>
        <DialogActions>
          <Button onClick={clearData} variant="contained" autoFocus>
            cancel
          </Button>
          <Button variant="contained" type="submit">
            {id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Form;

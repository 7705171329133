import React, { useEffect, useState } from "react";
import {
  Card,
  CardFlex,
  CardHeader,
  Title,
} from "../../components/Card/Card.style";
import { Button } from "@mui/material";
import ViewTable from "../../components/ViewTable/ViewTable";
import AddIcon from "@mui/icons-material/Add";
import SearchBar from "../../components/SearchBar/SearchBar";
import Form from "./Form/Form";
import { getContacts, deleteContact } from "../../../redux/actions/contact";
import { useDispatch, useSelector } from "react-redux";
import { confirmation } from "../../../utils/notification";
import { getOffices } from "../../../redux/actions/office";

const columnsName = ["First Name", "Last Name", "Email", "Phone No", "Offices"];

const Contacts = () => {
  const contacts = useSelector((state) => state.contactReducer);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [id, setID] = useState(null);
  const [data, setData] = useState([]);

  const handleClickOpen = (id = null, data = []) => {
    setID(id);
    setData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (id) => {
    confirmation().then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteContact(id));
      }
    });
  };

  useEffect(() => {
    dispatch(getContacts());
    dispatch(getOffices());
  }, [dispatch]);

  return (
    <CardFlex wrap="wrap" align="flex-start">
      <CardFlex flex="25rem" direction="column">
        <Form id={id} data={data} handleClose={handleClose} open={open} />
        <Card>
          <CardHeader>
            <Title>contacts</Title>
            <SearchBar />
            <Button
              variant="contained"
              onClick={() => handleClickOpen()}
              endIcon={<AddIcon />}
            >
              Add Contact
            </Button>
          </CardHeader>
          <div>
            <ViewTable rows={contacts} columnsName={columnsName}>
              {contacts.map(
                (
                  { _id, firstName, lastName, email, phoneNo, offices },
                  index
                ) => (
                  <tr key={_id}>
                    <td>{index + 1}</td>
                    <td>{firstName}</td>
                    <td>{lastName}</td>
                    <td>{email}</td>
                    <td>{phoneNo}</td>
                    <td>{offices.length}</td>
                    <td>
                      <CardFlex justify="center">
                        <Button
                          onClick={() => handleDelete(_id)}
                          variant="contained"
                        >
                          Delete
                        </Button>
                        <Button
                          onClick={() =>
                            handleClickOpen(_id, {
                              firstName,
                              lastName,
                              email,
                              phoneNo,
                              offices,
                            })
                          }
                          variant="contained"
                        >
                          Update
                        </Button>
                      </CardFlex>
                    </td>
                  </tr>
                )
              )}
            </ViewTable>
          </div>
        </Card>
      </CardFlex>
    </CardFlex>
  );
};

export default Contacts;

import * as type from "../types";

const serviceReducer = (services = [], action) => {
  switch (action.type) {
    case type.CREATE_SERVICE:
      return [action.payload, ...services];
    case type.GET_SERVICES:
      return action.payload.reverse();
    case type.DELETE_SERVICE:
      return services.filter((service) => service._id !== action.payload);
    case type.UPDATE_SERVICE:
      return services.map((service) =>
        service._id === action.payload._id ? action.payload : service
      );
    default:
      return services;
  }
};

export default serviceReducer;

import styled from "styled-components";

export const LogoImg = styled.img`
  width: 12rem;
  height: 3rem;
  object-fit: cover;
  background-color: #fff;
  padding: 0.2rem;
  margin-right: 0.9rem;
`;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DrawerList from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import ConstructionIcon from "@mui/icons-material/Construction";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import LogoutIcon from "@mui/icons-material/Logout";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import BusinessIcon from "@mui/icons-material/Business";
import GroupIcon from "@mui/icons-material/Group";
import { ItemButton } from "./List.style";
import WorkIcon from "@mui/icons-material/Work";
import ListIcon from "@mui/icons-material/List";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { IoSettingsOutline } from "react-icons/io5";

const List = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();
  const lists = [
    { text: "Dashboard", route: "", icon: <DashboardIcon color="primary" /> },
    {
      text: "Option Sets",
      route: "option-sets",
      icon: <ListIcon color="primary" />,
    },
    {
      text: "Services",
      route: "services",
      icon: <WarehouseIcon color="primary" />,
    },
    {
      text: "Projects",
      route: "projects",
      icon: <WorkIcon color="primary" />,
    },
    {
      text: "CSV Upload Requests",
      route: "CsvUploadRequests",
      icon: <WarehouseIcon color="primary" />,
    },
    {
      text: "Equipment Types",
      route: "equipment-types",
      icon: <ConstructionIcon color="primary" />,
    },
    {
      text: "Equipment Brands",
      route: "equipment-brands",
      icon: <BrandingWatermarkIcon color="primary" />,
    },
    {
      text: "Equipment Models",
      route: "equipment-models",
      icon: <ModelTrainingIcon color="primary" />,
    },
    {
      text: "Utilities",
      route: "utilities",
      icon: <WorkspacesIcon color="primary" />,
    },
    // {
    //   text: "Offices",
    //   route: "offices",
    //   icon: <ApartmentIcon color="primary" />,
    // },
    // {
    //   text: "Contacts",
    //   route: "contacts",
    //   icon: <ContactPageIcon color="primary" />,
    // },
    {
      text: "Companies",
      route: "companies",
      icon: <BusinessIcon color="primary" />,
    },
    { text: "Users", route: "users", icon: <GroupIcon color="primary" /> },
    { text: "Roles", route: "roles", icon: <GroupIcon color="primary" /> },
    {
      text: "Settings",
      route: "settings",
      icon: <IoSettingsOutline className="text-2xl text-green-600" />,
    },
    { text: "Logout", route: "login", icon: <LogoutIcon color="primary" /> },
  ];

  const handleListItemClick = (route, index) => {
    setSelectedIndex(index);
    if (route === "login") {
      localStorage.removeItem("admin");
    }
    navigate(route);
  };

  return (
    <DrawerList>
      {lists.map((list, index) => (
        <ItemButton
          color="primary"
          selected={selectedIndex === index}
          onClick={() => handleListItemClick(list.route, index)}
          key={index}
        >
          <ListItemIcon>{list.icon}</ListItemIcon>
          <ListItemText primary={list.text} />
        </ItemButton>
      ))}
    </DrawerList>
  );
};

export default List;

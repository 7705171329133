import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RotatingLines } from "react-loader-spinner";
import { FaPencilAlt } from "react-icons/fa";
import { getAppearanceSettingsForCompany } from "../../../../redux/api/appearanceSettings.api";
import { getZipCodesListByCompany } from "../../../../redux/api/zipCodes.api";
import RoofingLandingPageSample from "./RoofingLandingPageSample";
import Select from "react-select";
import states from "../../../../utils/states.json";
import { getRoofTypes } from "../../../../redux/api/optionSet.api";

const LandingPagePopup = ({
  open,
  setOpen,
  company,
  servicesUsed,
  mapCenter,
  mapZoom,
  createLandingPage,
  appearanceSettings,
  setAppearanceSettings,
  zipCodesList,
  setZipCodesList,
}) => {
  const auth = useSelector((state) => state.authReducer);
  const scaleClass = open ? "scale-100" : "scale-0";
  const [service, setService] = useState("");
  const [selectedWorkTypes, setSelectedWorkTypes] = useState([]);
  const [name, setName] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [state, setState] = useState("");
  const [selectedAppearanceSettingOption, setSelectedAppearanceSettingOption] =
    useState(null);
  const [useCompanyDataOnly, setUseCompanyDataOnly] = useState(false);
  const [enableMapLocation, setEnableMapLocation] = useState(false);
  const [showProjectCost, setShowProjectCost] = useState(false);
  const [allowMapZoom, setAllowMapZoom] = useState(false);
  const [allowMapPan, setAllowMapPan] = useState(false);
  const [appearanceSettingsOptions, setAppearanceSettingsOptions] = useState(
    []
  );
  const [selectedZipCodesList, setSelectedZipCodesList] = useState(null);
  const [roofMaterialsList, setRoofMaterialsList] = useState([]);
  const [selectedRoofMaterials, setSelectedRoofMaterials] = useState([]);
  const [loanTerm, setLoanTerm] = useState("");
  const [loanRate, setLoanRate] = useState("");
  const [headerTitle, setHeaderTitle] = useState("");
  const [headerDescription, setHeaderDescription] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [headerMapImage, setHeaderMapImage] = useState(null);
  const [headerImage, setHeaderImage] = useState(null);
  const [firstSectionBGImage, setFirstSectionBGImage] = useState(null);
  const [sectionImage, setSectionImage] = useState(null);
  const [leftCollageImage, setLeftCollageImage] = useState(null);
  const [rightTopCollageImage, setRightTopCollageImage] = useState(null);
  const [rightBottomCollageImage, setRightBottomCollageImage] = useState(null);
  const [validatedLeadCost, setValidatedLeadCost] = useState("");
  const [unvalidatedLeadCost, setUnvalidatedLeadCost] = useState("");
  const [defaultZipCode, setDefaultZipCode] = useState("");
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (company?._id) {
      getAppearanceSettingsForCompany(company?._id)
        .then((response) => {
          setAppearanceSettingsOptions(response.data);
        })
        .catch((error) => {
          console.error(error);
        });

      getZipCodesListByCompany(company?._id)
        .then((response) => {
          setZipCodesList(response.data);
        })
        .catch((error) => {
          console.error(error);
        });

      getRoofTypes()
        .then((response) => {
          setRoofMaterialsList(response.data.options);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [company?._id]);

  const handleImageChange = (e, setImage) => {
    const { name, value } = e.target;
    setImage((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e, setImage) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const lpFormData = new FormData();
    lpFormData.append("service", service);
    lpFormData.append(
      "workTypes",
      JSON.stringify(selectedWorkTypes.map((item) => item?.value))
    );
    lpFormData.append("name", name);
    lpFormData.append("state", state);
    lpFormData.append("userType", "business");
    lpFormData.append("useCompanyDataOnly", useCompanyDataOnly);
    lpFormData.append("enableMapLocation", enableMapLocation);
    lpFormData.append("showProjectCost", showProjectCost);
    lpFormData.append("allowMapZoom", allowMapZoom);
    lpFormData.append("allowMapPan", allowMapPan);
    lpFormData.append("mapCenter", JSON.stringify(mapCenter));
    lpFormData.append("mapZoom", mapZoom);
    lpFormData.append("appearanceSettings", JSON.stringify(appearanceSettings));
    lpFormData.append("zipCodesListId", selectedZipCodesList?.value);
    lpFormData.append(
      "roofMaterialsList",
      JSON.stringify(selectedRoofMaterials.map((item) => item.value))
    );
    lpFormData.append("headerTitle", headerTitle);
    lpFormData.append("headerDescription", headerDescription);
    lpFormData.append("buttonText", buttonText);
    lpFormData.append("defaultZipCode", defaultZipCode);
    lpFormData.append("companyPhoneNumber", companyPhoneNumber);
    lpFormData.append("headerMapImage", headerMapImage);
    lpFormData.append("headerImage", headerImage);
    lpFormData.append("firstSectionBGImage", firstSectionBGImage);
    lpFormData.append("sectionImage", sectionImage);
    lpFormData.append("leftCollageImage", leftCollageImage);
    lpFormData.append("rightTopCollageImage", rightTopCollageImage);
    lpFormData.append("rightBottomCollageImage", rightBottomCollageImage);
    lpFormData.append("loanTerm", loanTerm);
    lpFormData.append("loanRate", loanRate);
    lpFormData.append("validatedLeadCost", validatedLeadCost);
    lpFormData.append("unvalidatedLeadCost", unvalidatedLeadCost);
    console.log("lpFormData", Object.fromEntries(lpFormData));
    createLandingPage(lpFormData);
    setLoading(false);
  };

  console.log("Services Used", servicesUsed);
  useEffect(() => {
    console.log(
      servicesUsed
        ?.find((s) => s?._id === service)
        ?.workTypes?.map((w) => ({ value: w?._id, label: w?.name }))
    );
  }, [service]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="" onClose={setOpen}>
        <Transition.Child
          enter="ease-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100 transform scale-100"
          leaveTo="opacity-0 transform scale-0"
        >
          <div className="z-30 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-30">
          <div className="flex min-h-full items-center justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-500"
              enterFrom="opacity-0 transform scale-0"
              enterTo="opacity-100 transform scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 transform scale-100"
              leaveTo="opacity-0 transform scale-0"
            >
              <Dialog.Panel
                className={`ml-0 lg:ml-48 md:ml-48 w-4/6 lg:w-9/12 h-[80vh] ${scaleClass} fixed mx-auto rounded-lg bg-white pt-5 pb-4 text-left shadow-xl transition-all overflow-y-auto`}
              >
                <div className="absolute top-0 right-0 pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className=" rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className=" px-4 pb-4">
                  <div className=" sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Save Map Filter as Landing Page
                    </Dialog.Title>
                  </div>
                </div>
                <form>
                  <div className="overflow-y-scroll">
                    <div className="p-5">
                      <div className="items-start gap-4 grid sm:grid-cols-12 lg:grid-cols-2 md:grid-cols-12">
                        <div className="items-center gap-2 grid sm:grid-cols-12 lg:grid-cols-6 md:grid-cols-12">
                          <h3 className="text-lg font-medium text-gray-900 col-span-6">
                            General Information
                          </h3>
                          {/* Enter Landing Page Name */}
                          <div className="lg:col-span-6 md:col-span-6 sm:col-span-12 ">
                            <div className="mx-1">
                              <input
                                type="text"
                                name="name"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Page Name"
                              />
                            </div>
                          </div>
                          {/* Select State */}
                          <div className="lg:col-span-3 md:col-span-6 sm:col-span-12 ">
                            <div className="mx-1">
                              <Select
                                options={[
                                  { value: "all", label: "All States" },
                                  ...states?.map((state) => ({
                                    value: state?.abbreviation,
                                    label: `${state?.name} (${state?.abbreviation})`,
                                  })),
                                ]}
                                value={selectedState}
                                onChange={(selectedOption) => {
                                  setSelectedState(selectedOption);
                                  setState(selectedOption?.value);
                                }}
                                placeholder="Select State"
                                className="z-[100]"
                                menuPortalTarget={document.body}
                                menuPosition="fixed"
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                            </div>
                          </div>
                          {/* Select Zip Codes List */}
                          <div className="lg:col-span-3 md:col-span-6 sm:col-span-12">
                            <div className="mx-1">
                              <Select
                                options={zipCodesList?.map((list) => ({
                                  value: list?._id,
                                  label: list?.listName,
                                }))}
                                value={selectedZipCodesList}
                                onChange={(selectedOption) => {
                                  setSelectedZipCodesList(selectedOption);
                                }}
                                placeholder="Select Zip Codes List"
                                className="z-50"
                                menuPortalTarget={document.body}
                                menuPosition="fixed"
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                            </div>
                          </div>
                          {/* Enter Landing Page Validated Lead Cost */}
                          <div className="lg:col-span-3 md:col-span-6 sm:col-span-12">
                            <div className="mx-1">
                              <input
                                type="number"
                                name="validatedLeadCost"
                                id="validatedLeadCost"
                                value={validatedLeadCost}
                                onChange={(e) =>
                                  setValidatedLeadCost(e.target.value)
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Validated Lead Cost"
                              />
                            </div>
                          </div>
                          {/* Enter Landing Page Unvalidated Lead Cost */}
                          <div className="lg:col-span-3 md:col-span-6 sm:col-span-12">
                            <div className="mx-1">
                              <input
                                type="number"
                                name="unvalidatedLeadCost"
                                id="unvalidatedLeadCost"
                                value={unvalidatedLeadCost}
                                onChange={(e) =>
                                  setUnvalidatedLeadCost(e.target.value)
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Unvalidated Lead Cost"
                              />
                            </div>
                          </div>
                          <h3 className="text-lg font-medium text-gray-900 col-span-6">
                            Service Information
                          </h3>
                          {/* Select Service */}
                          <div className="lg:col-span-3 md:col-span-6 sm:col-span-12 ">
                            <div className="">
                              <select
                                onChange={(e) => setService(e.target.value)}
                                value={service}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                              >
                                <option value="">Select Service</option>
                                {servicesUsed?.map((service, i) => (
                                  <option value={service?._id} key={i}>
                                    {service?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          {/* Select Worktype */}
                          <div className="lg:col-span-3 md:col-span-6 sm:col-span-12 ">
                            <div className="mx-1">
                              <Select
                                isMulti={true}
                                options={servicesUsed
                                  ?.find((s) => s?._id === service)
                                  ?.workTypes?.map((w) => ({
                                    value: w?._id,
                                    label: w?.name,
                                  }))}
                                value={selectedWorkTypes}
                                onChange={(selectedOption) => {
                                  setSelectedWorkTypes(selectedOption);
                                }}
                                placeholder="Select Worktypes"
                                className="z-[100]"
                                menuPortalTarget={document.body}
                                menuPosition="fixed"
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                            </div>
                          </div>
                          {servicesUsed?.find((s) => s?._id === service)
                            ?.name === "Roofing" && (
                            <div className="lg:col-span-3">
                              <div className="mx-1">
                                <Select
                                  options={roofMaterialsList?.map((item) => ({
                                    value: item,
                                    label: item,
                                  }))}
                                  value={selectedRoofMaterials}
                                  onChange={(selectedOption) => {
                                    setSelectedRoofMaterials(selectedOption);
                                  }}
                                  placeholder="Select Roof Materials"
                                  className="z-50"
                                  menuPortalTarget={document.body}
                                  menuPosition="fixed"
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  isMulti={true}
                                />
                              </div>
                            </div>
                          )}
                          {servicesUsed?.find((s) => s._id === service)
                            ?.name === "Solar" && (
                            <>
                              <div className="lg:col-span-3">
                                <div className="mx-1">
                                  <input
                                    type="number"
                                    name="loanTerm"
                                    id="loanTerm"
                                    value={loanTerm}
                                    onChange={(e) =>
                                      setLoanTerm(e.target.value)
                                    }
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                    placeholder="Enter Loan Term"
                                  />
                                </div>
                              </div>
                              <div className="lg:col-span-3">
                                <div className="mx-1">
                                  <input
                                    type="number"
                                    name="loanRate"
                                    id="loanRate"
                                    value={loanRate}
                                    onChange={(e) =>
                                      setLoanRate(e.target.value)
                                    }
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                    placeholder="Enter Loan Rate"
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          <h3 className="text-lg font-medium text-gray-900 col-span-6">
                            Landing Page Options
                          </h3>
                          {/* Use Company Data Only Checkbox */}
                          <div className="lg:col-span-3 md:col-span-3 sm:col-span-3 ">
                            <div className="relative flex items-start">
                              <div className="flex h-6 items-center">
                                <input
                                  id="useCompanyDataOnly"
                                  aria-describedby="useCompanyDataOnly"
                                  name="useCompanyDataOnly"
                                  type="checkbox"
                                  value={useCompanyDataOnly}
                                  onChange={(e) =>
                                    setUseCompanyDataOnly(e.target.checked)
                                  }
                                  className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                                />
                              </div>
                              <div className="ml-3 text-sm leading-6">
                                <label
                                  htmlFor="useCompanyDataOnly"
                                  className="font-medium text-gray-900"
                                >
                                  Use Company Data Only
                                </label>
                              </div>
                            </div>
                          </div>
                          {/* Enable map location in page checkbox */}
                          <div className="lg:col-span-3 md:col-span-3 sm:col-span-3 ">
                            <div className="relative flex items-start">
                              <div className="flex h-6 items-center">
                                <input
                                  id="enableMapLocation"
                                  aria-describedby="enableMapLocation"
                                  name="enableMapLocation"
                                  type="checkbox"
                                  value={enableMapLocation}
                                  onChange={(e) =>
                                    setEnableMapLocation(e.target.checked)
                                  }
                                  className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                                />
                              </div>
                              <div className="ml-3 text-sm leading-6">
                                <label
                                  htmlFor="enableMapLocation"
                                  className="font-medium text-gray-900"
                                >
                                  Enable location in page
                                </label>
                              </div>
                            </div>
                          </div>
                          {/* Show project cost in page checkbox */}
                          <div className="lg:col-span-3 md:col-span-3 sm:col-span-3 ">
                            <div className="relative flex items-start">
                              <div className="flex h-6 items-center">
                                <input
                                  id="showProjectCost"
                                  aria-describedby="showProjectCost"
                                  name="showProjectCost"
                                  type="checkbox"
                                  value={showProjectCost}
                                  onChange={(e) =>
                                    setShowProjectCost(e.target.checked)
                                  }
                                  checked={showProjectCost}
                                  className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                                />
                              </div>
                              <div className="ml-3 text-sm leading-6">
                                <label
                                  htmlFor="showProjectCost"
                                  className="font-medium text-gray-900"
                                >
                                  Show Project Cost
                                </label>
                              </div>
                            </div>
                          </div>
                          <h3 className="text-lg font-medium text-gray-900 col-span-6">
                            Map Options
                          </h3>
                          {/* Allow map zoom in page checkbox */}
                          <div className="lg:col-span-3 md:col-span-3 sm:col-span-3 ">
                            <div className="relative flex items-start">
                              <div className="flex h-6 items-center">
                                <input
                                  id="allowMapZoom"
                                  aria-describedby="allowMapZoom"
                                  name="allowMapZoom"
                                  type="checkbox"
                                  value={allowMapZoom}
                                  onChange={(e) =>
                                    setAllowMapZoom(e.target.checked)
                                  }
                                  checked={allowMapZoom}
                                  className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                                />
                              </div>
                              <div className="ml-3 text-sm leading-6">
                                <label
                                  htmlFor="allowMapZoom"
                                  className="font-medium text-gray-900"
                                >
                                  Allow map zoom
                                </label>
                              </div>
                            </div>
                          </div>
                          {/* Allow map pan in page checkbox */}
                          <div className="lg:col-span-3 md:col-span-3 sm:col-span-3 ">
                            <div className="relative flex items-start">
                              <div className="flex h-6 items-center">
                                <input
                                  id="allowMapPan"
                                  aria-describedby="allowMapPan"
                                  name="allowMapPan"
                                  type="checkbox"
                                  value={allowMapPan}
                                  onChange={(e) =>
                                    setAllowMapPan(e.target.checked)
                                  }
                                  checked={allowMapPan}
                                  className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                                />
                              </div>
                              <div className="ml-3 text-sm leading-6">
                                <label
                                  htmlFor="allowMapPan"
                                  className="font-medium text-gray-900"
                                >
                                  Allow map Pan
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="items-center gap-2 grid sm:grid-cols-12 lg:grid-cols-6 md:grid-cols-12">
                          <h3 className="text-lg font-medium text-gray-900 col-span-6">
                            Landing Page Appearance
                          </h3>
                          {/* Select Landing Page Appearance Setings */}
                          <div className="lg:col-span-6 items-center">
                            <Select
                              options={appearanceSettingsOptions?.map(
                                (setting) => ({
                                  value: setting?._id,
                                  label: setting?.name,
                                  icon: setting?.serviceIcons?.find(
                                    (serviceIcon) =>
                                      serviceIcon?.serviceId?._id === service // Ensure `service` holds the correct serviceId
                                  )?.icon,
                                  officeIcon: setting?.officeMarkerIcon,
                                })
                              )}
                              value={selectedAppearanceSettingOption?.name}
                              onChange={(selectedOption) => {
                                setSelectedAppearanceSettingOption(
                                  selectedOption
                                );
                                setAppearanceSettings({
                                  ...appearanceSettings,
                                  icon: selectedOption?.icon,
                                  officeIcon: selectedOption?.officeIcon,
                                });
                              }}
                              placeholder="Select Appearance Settings"
                              className="z-50"
                              menuPortalTarget={document.body}
                              menuPosition="fixed"
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                          </div>
                          <h3 className="text-md font-medium text-gray-900 col-span-6">
                            Select Colours
                          </h3>
                          {/* Landing Page Primary Colour */}
                          <div className="lg:col-span-3 flex items-center">
                            <label
                              htmlFor="primaryColour"
                              className="h-10 w-10 rounded-full flex items-end justify-end relative"
                              style={{
                                backgroundColor:
                                  appearanceSettings?.primaryColour || "#000",
                              }}
                            >
                              <div className="h-5 w-5 text-gray-500 -bottom-0.5 -right-0.5 bg-gray-200 rounded-full flex items-center justify-center">
                                <FaPencilAlt className="absolute h-3 w-3 text-gray-600" />
                              </div>
                            </label>
                            <label
                              htmlFor="primaryColour"
                              className="block text-sm font-medium text-gray-700 ml-2"
                            >
                              Primary Colour
                            </label>
                            <div className="mt-1 relative">
                              <input
                                type="color"
                                name="primaryColour"
                                id="primaryColour"
                                value={appearanceSettings?.primaryColour}
                                onChange={(e) => {
                                  const newColour = e.target.value;
                                  setAppearanceSettings({
                                    ...appearanceSettings,
                                    primaryColour: newColour,
                                  });
                                }}
                                className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
                              />
                            </div>
                          </div>
                          {/* Landing Page Secondary Colour */}
                          <div className="lg:col-span-3 flex items-center">
                            <label
                              htmlFor="secondaryColour"
                              className="h-10 w-10 rounded-full flex items-end justify-end relative"
                              style={{
                                backgroundColor:
                                  appearanceSettings?.secondaryColour || "#000",
                              }}
                            >
                              <div className="h-5 w-5 text-gray-500 -bottom-0.5 -right-0.5 bg-gray-200 rounded-full flex items-center justify-center">
                                <FaPencilAlt className="absolute h-3 w-3 text-gray-600" />
                              </div>
                            </label>
                            <label
                              htmlFor="secondaryColour"
                              className="block text-sm font-medium text-gray-700 ml-2"
                            >
                              Secondary Colour
                            </label>
                            <div className="mt-1 relative">
                              <input
                                type="color"
                                name="secondaryColour"
                                id="secondaryColour"
                                value={appearanceSettings?.secondaryColour}
                                onChange={(e) => {
                                  const newColour = e.target.value;
                                  setAppearanceSettings({
                                    ...appearanceSettings,
                                    secondaryColour: newColour,
                                  });
                                }}
                                className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
                              />
                            </div>
                          </div>
                          {/* Landing Page Heading Text Colour */}
                          <div className="lg:col-span-3 flex items-center">
                            <label
                              htmlFor="headingTextColour"
                              className="h-10 w-10 rounded-full flex items-end justify-end relative"
                              style={{
                                backgroundColor:
                                  appearanceSettings?.headingTextColour ||
                                  "#000",
                              }}
                            >
                              <div className="h-5 w-5 text-gray-500 -bottom-0.5 -right-0.5 bg-gray-200 rounded-full flex items-center justify-center">
                                <FaPencilAlt className="absolute h-3 w-3 text-gray-600" />
                              </div>
                            </label>
                            <label
                              htmlFor="headingTextColour"
                              className="block text-sm font-medium text-gray-700 ml-2"
                            >
                              Heading Text Colour
                            </label>
                            <div className="mt-1 relative">
                              <input
                                type="color"
                                name="headingTextColour"
                                id="headingTextColour"
                                value={appearanceSettings?.headingTextColour}
                                onChange={(e) => {
                                  const newColour = e.target.value;
                                  setAppearanceSettings({
                                    ...appearanceSettings,
                                    headingTextColour: newColour,
                                  });
                                }}
                                className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
                              />
                            </div>
                          </div>
                          {/* Landing Page Paragraph Text Colour */}
                          <div className="lg:col-span-3 flex items-center">
                            <label
                              htmlFor="paragraphTextColour"
                              className="h-10 w-10 rounded-full flex items-end justify-end relative"
                              style={{
                                backgroundColor:
                                  appearanceSettings?.paragraphTextColour ||
                                  "#000",
                              }}
                            >
                              <div className="h-5 w-5 text-gray-500 -bottom-0.5 -right-0.5 bg-gray-200 rounded-full flex items-center justify-center">
                                <FaPencilAlt className="absolute h-3 w-3 text-gray-600" />
                              </div>
                            </label>
                            <label
                              htmlFor="paragraphTextColour"
                              className="block text-sm font-medium text-gray-700 ml-2"
                            >
                              Paragraph Text Colour
                            </label>
                            <div className="mt-1 relative">
                              <input
                                type="color"
                                name="paragraphTextColour"
                                id="paragraphTextColour"
                                value={appearanceSettings?.paragraphTextColour}
                                onChange={(e) => {
                                  const newColour = e.target.value;
                                  setAppearanceSettings({
                                    ...appearanceSettings,
                                    paragraphTextColour: newColour,
                                  });
                                }}
                                className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
                              />
                            </div>
                          </div>
                          {/* Landing Page Header Footer Background Colour */}
                          <div className="lg:col-span-3 flex items-center">
                            <label
                              htmlFor="headerBGColour"
                              className="h-10 w-10 rounded-full flex items-end justify-end relative"
                              style={{
                                backgroundColor:
                                  appearanceSettings?.headerBGColour || "#000",
                              }}
                            >
                              <div className="h-5 w-5 text-gray-500 -bottom-0.5 -right-0.5 bg-gray-200 rounded-full flex items-center justify-center">
                                <FaPencilAlt className="absolute h-3 w-3 text-gray-600" />
                              </div>
                            </label>
                            <label
                              htmlFor="headerBGColour"
                              className="block text-sm font-medium text-gray-700 ml-2 text-wrap"
                            >
                              Header Footer Background Colour
                            </label>
                            <div className="mt-1 relative">
                              <input
                                type="color"
                                name="headerBGColour"
                                id="headerBGColour"
                                value={appearanceSettings?.headerBGColour}
                                onChange={(e) => {
                                  const newColour = e.target.value;
                                  setAppearanceSettings({
                                    ...appearanceSettings,
                                    headerBGColour: newColour,
                                  });
                                }}
                                className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
                              />
                            </div>
                          </div>
                          <h3 className="text-md font-medium text-gray-900 col-span-6">
                            Add Hero Section Text
                          </h3>
                          {/* Enter Landing Page Header Text */}
                          <div className="lg:col-span-6">
                            <div className="mx-1">
                              <input
                                type="text"
                                name="headerTitle"
                                id="headerTitle"
                                value={headerTitle}
                                onChange={(e) => setHeaderTitle(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Header Title"
                              />
                            </div>
                          </div>
                          {/* Enter Landing Page Description */}
                          <div className="lg:col-span-6">
                            <div className="mx-1">
                              <input
                                type="text"
                                name="headerDescription"
                                id="headerDescription"
                                value={headerDescription}
                                onChange={(e) =>
                                  setHeaderDescription(e.target.value)
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Header Description"
                              />
                            </div>
                          </div>
                          {/* Enter Button text */}
                          <div className="lg:col-span-6">
                            <div className="mx-1">
                              <input
                                type="text"
                                name="buttonText"
                                id="buttonText"
                                value={buttonText}
                                onChange={(e) =>
                                  setButtonText(e.target.value)
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Button Text"
                              />
                            </div>
                          </div>
                          {/* Enter Default Zip Code */}
                          <div className="lg:col-span-6">
                            <div className="mx-1">
                              <input
                                type="text"
                                name="defaultZipCode"
                                id="defaultZipCode"
                                value={defaultZipCode}
                                onChange={(e) =>
                                  setDefaultZipCode(e.target.value)
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Default Zip Code"
                              />
                            </div>
                          </div>
                          {/* Enter Phone Number */}
                          <div className="lg:col-span-6">
                            <div className="mx-1">
                              <input
                                type="text"
                                name="companyPhoneNumber"
                                id="companyPhoneNumber"
                                value={companyPhoneNumber}
                                onChange={(e) =>
                                  setCompanyPhoneNumber(e.target.value)
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                placeholder="Enter Company Phone Number"
                              />
                            </div>
                          </div>

                          {/* Upload Images */}
                          <h3 className="text-md font-medium text-gray-900 col-span-6">
                            Upload Images
                          </h3>
                          <div className="lg:col-span-3 md:col-span-12 sm:col-span-12">
                            <label className="block text-sm font-medium text-gray-700">
                              Header Map Image
                            </label>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                handleFileChange(e, setHeaderMapImage)
                              }
                              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                            />
                          </div>
                          <div className="lg:col-span-3 md:col-span-12 sm:col-span-12">
                            <label className="block text-sm font-medium text-gray-700">
                              Header Image
                            </label>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                handleFileChange(e, setHeaderImage)
                              }
                              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                            />
                          </div>
                          <div className="lg:col-span-3 md:col-span-12 sm:col-span-12">
                            <label className="block text-sm font-medium text-gray-700">
                              First Section Background Image
                            </label>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                handleFileChange(e, setFirstSectionBGImage)
                              }
                              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                            />
                          </div>
                          <div className="lg:col-span-3 md:col-span-12 sm:col-span-12">
                            <label className="block text-sm font-medium text-gray-700">
                              Section Image
                            </label>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                handleFileChange(e, setSectionImage)
                              }
                              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                            />
                          </div>
                          <div className="lg:col-span-3 md:col-span-12 sm:col-span-12">
                            <label className="block text-sm font-medium text-gray-700">
                              Left Collage Image
                            </label>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                handleFileChange(e, setLeftCollageImage)
                              }
                              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                            />
                          </div>
                          <div className="lg:col-span-3 md:col-span-12 sm:col-span-12">
                            <label className="block text-sm font-medium text-gray-700">
                              Right Top Collage Image
                            </label>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                handleFileChange(e, setRightTopCollageImage)
                              }
                              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                            />
                          </div>
                          <div className="lg:col-span-3 md:col-span-12 sm:col-span-12">
                            <label className="block text-sm font-medium text-gray-700">
                              Right Bottom Collage Image
                            </label>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                handleFileChange(e, setRightBottomCollageImage)
                              }
                              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                            />
                          </div>
                        </div>

                        {/* Select appearance settings */}
                        {/* <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 ">
                          <div className="relative">
                            <select
                              id="appearanceSettings"
                              name="appearanceSettings"
                              value={appearanceSettings}
                              onChange={(e) =>
                                setAppearanceSettings(e.target.value)
                              }
                              className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-green-600 focus:border-green-600 sm:text-sm"
                            >
                              <option value="">
                                Select Appearance Settings
                              </option>
                              {appearanceSettingsOptions.map((setting) => (
                                <option key={setting._id} value={setting._id}>
                                  {setting.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="border border-gray-200 rounded-md m-2 p-2">
                    <RoofingLandingPageSample
                      company={company}
                      appearanceSettings={appearanceSettings}
                    />
                  </div>
                  <div className="px-5 mt-3">
                    <button
                      type="button"
                      disabled={loading}
                      onClick={handleSubmit}
                      className={`w-full ${
                        loading
                          ? "bg-green-700 hover:bg-green-700 cursor-not-allowed"
                          : ""
                      } bg-green-600 hover:bg-green-700 text-white font-semibold py-2.5 px-3 rounded-md`}
                    >
                      {loading ? (
                        <div className="flex justify-center items-center">
                          <RotatingLines
                            strokeColor="white"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="24"
                            visible={true}
                          />
                        </div>
                      ) : (
                        "Save Map Page"
                      )}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default LandingPagePopup;

import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SubContainer = styled.div`
  display: flex;
  padding: 1rem;
  align-self: center;
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  gap: 1rem;
  background-color: #fff;
  .sign-action {
    cursor: pointer;
  }
`;

import * as api from "../api";
import * as type from "../types";
import { failure, success } from "../../utils/notification";

export const getOptionSets = () => async (dispatch) => {
  try {
    const { data } = await api.getOptionSets();
    dispatch({ type: type.GET_OPTIONSETS, payload: data });
  } catch (error) {
    failure(error.message);
  }
};

export const createOptionSet = (optionSet) => async (dispatch) => {
  try {
    const { data, status } = await api.createOptionSet(optionSet);
    if (status === 200) {
      dispatch({ type: type.CREATE_OPTIONSET, payload: data });
      success("Saved");
    }
  } catch (error) {
    failure(error.message);
  }
};

export const updateOptionSet = (id, optionSet) => async (dispatch) => {
  try {
    const { data, status } = await api.updateOptionSet(id, optionSet);
    if (status === 200) {
      dispatch({ type: type.UPDATE_OPTIONSET, payload: data });
      success("Updated");
    }
  } catch (error) {
    failure(error.message);
  }
};

export const deleteOptionSet = (id) => async (dispatch) => {
  try {
    const { status } = await api.deleteOptionSet(id);
    if (status === 200) {
      dispatch({ type: type.DELETE_OPTIONSET, payload: id });
      success("Deleted");
    }
  } catch (error) {
    failure(error.message);
  }
};

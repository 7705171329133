import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getOptionSets } from "../../../../../redux/actions/optionSet";
import { getEquipmentTypes } from "../../../../../redux/actions/equipmentType";

const types = [
  "text",
  "number",
  "email",
  "date",
  "tel",
  "select",
  "textarea",
  "measurement",
];
const filterTypes = ["text", "number", "select", "date"];
const filterSubTypes = {
  text: ["equals", "contains"],
  number: ["equals", "range"],
  select: ["single", "multiple"],
  date: ["equals", "range"],
};
const initialModules = [
  "equipmentBrands",
  "equipmentModels",
  "equipmentTypes",
  "utilities",
  "optionSets",
];

const CreateFields = ({
  field,
  setField,
  fieldId,
  handleFieldChange,
  handleFieldSubmit,
  services,
  workTypes,
  selectedWorkTypes,
  setSelectedWorkTypes,
}) => {
  const dispatch = useDispatch();
  const optionSets = useSelector((state) => state.optionSetReducer);
  const equipmentTypes = useSelector((state) => state.equipmentTypeReducer);
  const [modules, setModules] = useState(initialModules);

  useEffect(() => {
    dispatch(getOptionSets());
    dispatch(getEquipmentTypes());
  }, [dispatch]);

  // useEffect(() => {
  //   const newModules = optionSets.map(({ name }) => name);
  //   setModules([...initialModules, ...newModules]);
  // }, [optionSets]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedWorkTypes(selectedOptions);
    // Update the field object as well
    setField((prev) => ({
      ...prev,
      workTypes: selectedOptions.map((option) => ({
        _id: option.value,
        name: option.label,
      })),
    }));
  };

  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-lg font-semibold text-gray-900">
        {fieldId !== null
          ? `Update ${field?.type} Field`
          : `Create ${field?.type} Field`}
      </h2>
      {/* <form autoComplete="off" onSubmit={handleFieldSubmit}> */}
      <div className="flex flex-col">
        <div className="flex flex-col gap-3">
          <div>
            <label
              htmlFor="prevFieldData"
              className="block font-medium leading-6 text-gray-900"
            >
              Previous Field
            </label>
            <div className="mt-2">
              <select
                type="select"
                name="prevFieldData"
                id="prevFieldData"
                value=""
                onChange={(e) => setField(JSON.parse(e.target.value))}
                autoComplete="off"
                className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
              >
                <option value="">Select Previous Field</option>
                {services.map((service) =>
                  service?.fields.map((field, id) => (
                    <option
                      key={`${id}${services._id}`}
                      value={JSON.stringify(field)}
                    >
                      {field?.name}
                    </option>
                  ))
                )}
              </select>
            </div>
          </div>
          <div>
            <label
              htmlFor="workType"
              className="block font-medium leading-6 text-gray-900"
            >
              Project Type
            </label>
            <div className="mt-2">
              <Select
                options={workTypes?.map((workType) => ({
                  value: workType?._id || workType?.name,
                  label: workType?.name,
                }))}
                isMulti={true}
                name="workTypes"
                id="workTypes"
                value={selectedWorkTypes}
                onChange={handleSelectChange}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="type"
              className="block font-medium leading-6 text-gray-900"
            >
              Field Type
            </label>
            <div className="mt-2">
              <select
                name="type"
                id="type"
                value={field?.type}
                onChange={handleFieldChange}
                autoComplete="off"
                className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
              >
                <option value="">Select Field Type</option>
                {types.map((type, id) => (
                  <option key={id} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div>
            <label
              htmlFor="label"
              className="block font-medium leading-6 text-gray-900"
            >
              Label
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="label"
                id="label"
                value={field?.label}
                onChange={handleFieldChange}
                autoComplete="off"
                placeholder="Field Label"
                className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="fieldName"
              className="block font-medium leading-6 text-gray-900"
            >
              Name
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="name"
                id="fieldName"
                value={field?.name}
                onChange={handleFieldChange}
                autoComplete="off"
                placeholder="Field Name"
                className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
              />
            </div>
          </div>
          {field?.type === "select" && (
            <>
              <div>
                <label
                  htmlFor="module"
                  className="block font-medium leading-6 text-gray-900"
                >
                  Module
                </label>
                <div className="mt-2">
                  <select
                    name="module"
                    id="module"
                    value={field?.module}
                    onChange={handleFieldChange}
                    autoComplete="off"
                    required
                    className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
                  >
                    <option value="">Select Module</option>
                    {modules.map((module, id) => (
                      <option key={id} value={module}>
                        {module}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id="allowOptionsByUsers"
                    aria-describedby="allowOptionsByUsers"
                    name="allowOptionsByUsers"
                    type="checkbox"
                    value={field?.allowOptionsByUsers}
                    onChange={handleFieldChange}
                    checked={field?.allowOptionsByUsers || false}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="allowOptionsByUsers"
                    className="font-medium text-gray-900"
                  >
                    Allow user to add options
                  </label>
                </div>
              </div>
            </>
          )}
          {field?.type === "select" &&
            (field?.module === "equipmentModels" ||
              field?.module === "equipmentBrands") && (
              <div>
                <label
                  htmlFor="filter"
                  className="block font-medium leading-6 text-gray-900"
                >
                  Filter
                </label>
                <div className="mt-2">
                  <select
                    name="filter"
                    id="filter"
                    value={field?.filter}
                    onChange={handleFieldChange}
                    autoComplete="off"
                    className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
                  >
                    <option value="">Select Filter</option>
                    {equipmentTypes.map(({ _id, name }, id) => (
                      <option key={id} value={_id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          {field?.type === "select" && field?.module === "optionSets" && (
            <div>
              <label
                htmlFor="optionSet"
                className="block font-medium leading-6 text-gray-900"
              >
                Option Set
              </label>
              <div className="mt-2">
                <select
                  name="optionSet"
                  id="optionSet"
                  value={field?.optionSet}
                  onChange={handleFieldChange}
                  autoComplete="off"
                  className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
                >
                  <option value="">Select Option Set</option>
                  {optionSets.map(({ _id, name }, id) => (
                    <option key={id} value={_id}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
          {field?.type === "measurement" && (
            <div>
              <label
                htmlFor="optionSet"
                className="block font-medium leading-6 text-gray-900"
              >
                Unit Option Set
              </label>
              <div className="mt-2">
                <select
                  name="optionSet"
                  id="optionSet"
                  value={field?.optionSet}
                  onChange={handleFieldChange}
                  autoComplete="off"
                  className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
                >
                  <option value="">Select Option Set</option>
                  {optionSets.map(({ _id, name }, id) => (
                    <option key={id} value={_id}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
          <div className="relative flex items-start">
            <div className="absolute flex h-5 items-center">
              <input
                id="required"
                name="required"
                type="checkbox"
                value={field?.required}
                onChange={handleFieldChange}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                checked={field?.required}
              />
            </div>
            <div className="pl-7 text-sm">
              <label htmlFor="required" className="font-medium text-gray-900">
                Required
              </label>
            </div>
          </div>
          <div className="relative flex items-start">
            <div className="absolute flex h-5 items-center">
              <input
                id="filterable"
                name="filterable"
                type="checkbox"
                value={field?.filterable}
                onChange={handleFieldChange}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                checked={field?.filterable}
              />
            </div>
            <div className="pl-7 text-sm">
              <label htmlFor="filterable" className="font-medium text-gray-900">
                Filterable
              </label>
            </div>
          </div>
          {field?.filterable && (
            <div>
              <label
                htmlFor="filterType"
                className="block font-medium leading-6 text-gray-900"
              >
                Filter Type
              </label>
              <div className="mt-2">
                <select
                  name="filterType"
                  id="filterType"
                  value={field?.filterType}
                  onChange={handleFieldChange}
                  autoComplete="off"
                  className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
                >
                  <option value="">Select Filter Type</option>
                  {filterTypes.map((filterType, id) => (
                    <option key={id} value={filterType}>
                      {filterType}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
          {field?.filterable && field?.filterType !== "" && (
            <div>
              <label
                htmlFor="filterSubType"
                className="block font-medium leading-6 text-gray-900"
              >
                Filter Sub Type
              </label>
              <div className="mt-2">
                <select
                  name="filterSubType"
                  id="filterSubType"
                  value={field?.filterSubType}
                  onChange={handleFieldChange}
                  autoComplete="off"
                  className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
                >
                  <option value="">Select Filter Sub Type</option>
                  {filterSubTypes[field?.filterType]?.map(
                    (filterSubType, id) => (
                      <option key={id} value={filterSubType}>
                        {filterSubType}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>
          )}
        </div>
        <div>
          <button
            type="button"
            onClick={handleFieldSubmit}
            className="relative mt-3 inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
          >
            {fieldId !== null ? "Update Field" : "Save Field"}
          </button>
        </div>
      </div>
      {/* </form> */}
    </div>
  );
};

export default CreateFields;

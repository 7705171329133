import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const initialData = {
  name: "",
  costLabel: "",
  temporary: false,
  createdBy: null,
  selectedIndex: -1,
};

const WorkTypeForm = ({
  open,
  setOpen,
  workTypes,
  setWorkTypes,
  dataToEdit,
}) => {
  const [workType, setWorkType] = useState(initialData);

  useEffect(() => {
    if (dataToEdit && Object.keys(dataToEdit).length > 0) {
      setWorkType(dataToEdit);
    }
  }, [dataToEdit]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setWorkType({ ...workType, [name]: value });
  };

  const handleSubmitWorkType = async (event) => {
    event.preventDefault();
    console.log(workType);
    // no duplicate names
    const existing = workTypes?.find(
      (o, i) => o?.name === workType?.name && workType?.selectedIndex !== i
    );
    if (existing) {
      alert("Project Type already exists!");
      return;
    }
    if (dataToEdit && Object.keys(dataToEdit).length > 0) {
      // Update work types array with updated work type by selected index
      const updatedWorkTypes = workTypes.map((o, i) =>
        i === workType?.selectedIndex ? workType : o
      );
      setWorkTypes(updatedWorkTypes);
    } else {
      // create work type
      setWorkTypes([...workTypes, workType]);
    }
    setWorkType(initialData);
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {`${
                        dataToEdit && Object.keys(dataToEdit).length > 0
                          ? "Update"
                          : "Create"
                      }
                      Project Type`}
                    </Dialog.Title>
                  </div>
                </div>
                <form onSubmit={handleSubmitWorkType}>
                  <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Name
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full shadow-sm sm:text-sm focus:ring-green-500 focus:border-green-500 border-gray-300 rounded-md"
                        value={workType?.name}
                        onChange={handleChange}
                        placeholder="Name"
                        required
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="costLabel"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Cost Label
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        type="text"
                        name="costLabel"
                        id="costLabel"
                        className="block w-full shadow-sm sm:text-sm focus:ring-green-500 focus:border-green-500 border-gray-300 rounded-md"
                        value={workType?.costLabel}
                        onChange={handleChange}
                        placeholder="Cost Label"
                        required
                      />
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      {dataToEdit && Object.keys(dataToEdit).length > 0
                        ? "Update"
                        : "Create"}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default WorkTypeForm;

import * as type from "../types";

const initialState = {
  equipmentModels: [],
  count: 0,
  loading: false,
  error: null,
};

const equipmentModelReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.CREATE_EQUIPMENTMODEL:
      return {
        ...state,
        equipmentModels: [action.payload, ...state.equipmentModels],
      };
    case type.GET_EQUIPMENTMODELS:
      return {
        ...state,
        loading: false,
        equipmentModels: action.payload.equipmentModels,
        count: action.payload.count,
      };
    case type.DELETE_EQUIPMENTMODEL:
      return {
        ...state,
        equipmentModels: state.equipmentModels.filter(
          (equipmentModel) => equipmentModel._id !== action.payload
        ),
      };
    case type.UPDATE_EQUIPMENTMODEL:
      return {
        ...state,
        equipmentModels: state.equipmentModels.map((equipmentModel) =>
          equipmentModel._id === action.payload._id
            ? action.payload
            : equipmentModel
        ),
      };
    default:
      return state;
  }
};

export default equipmentModelReducer;

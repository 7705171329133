import { API } from "./api";

export const getEquipmentBrands = (
  filter = null,
  sort = null,
  pagination = null
) =>
  API.get("/equipmentBrands", {
    params: {
      filter: filter,
      sort: sort,
      skip: pagination?.skip || 0,
      limit: pagination?.limit || 10,
    },
  });

export const getEquipmentBrand = (id) => API.get(`/equipmentBrands/id/${id}`);

export const getEquipmentBrandsByType = (type) =>
  API.get(`/equipmentBrands/${type}`);

export const getServicesByEquipmentBrand = (id) =>
  API.get(`/equipmentBrands/${id}/services`);

export const updateEquipmentBrandServices = (id, services) =>
  API.put(`/equipmentBrands/${id}/updateServices/`, services);

export const getConflictedEquipmentBrands = () =>
  API.get("/equipmentBrands/conflicts");

export const createEquipmentBrand = (newEquipmentBrand) =>
  API.post("/equipmentBrands", newEquipmentBrand);

export const uploadEquipmentBrandsCsv = (newEquipmentBrands) =>
  API.post("/equipmentBrands/upload", newEquipmentBrands);

export const updateEquipmentBrand = (id, updatedEquipmentBrand) =>
  API.patch(`/equipmentBrands/${id}`, updatedEquipmentBrand);

export const deleteEquipmentBrand = (id) =>
  API.delete(`/equipmentBrands/${id}`);

export const mergeEquipmentBrands = (data) =>
  API.post("/equipmentBrands/merge", data);

export const markEquipmentBrandAsDifferent = (equipmentBrands) =>
  API.post(`/equipmentBrands/markAsDifferent`, equipmentBrands);

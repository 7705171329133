import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CardFlex } from "../../../components/Card/Card.style";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Chip,
} from "@mui/material";
import {
  createEquipmentBrand,
  updateEquipmentBrand,
} from "../../../../redux/actions/equipmentBrand";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import UploadLogo from "../../../components/UploadLogo/UploadLogo";

const initialData = {
  name: "",
  entityName: "",
  logo: "",
  claimed: true,
  contacts: [],
  services: [],
  offices: [],
};

const initialContactData = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNo: "",
  offices: [],
  active: true,
};

const initialOfficeData = {
  name: "",
  phoneNo: "",
  address: "",
  websiteToDirect: "",
  websiteToShow: "",
  isHeadOffice: false,
  location: "",
};

const Form = ({ id, data, handleClose, open }) => {
  const dispatch = useDispatch();
  const services = useSelector((state) => state.serviceReducer);
  const equipmentTypes = useSelector((state) => state.equipmentTypeReducer);
  const [equipmentBrand, setEquipmentBrand] = useState(initialData);
  const [toggleOffice, setToggleOffice] = useState(false);
  const [toggleContact, setToggleContact] = useState(false);
  const [contact, setContact] = useState(initialContactData);
  const [office, setOffice] = useState(initialOfficeData);
  const [officeUpdateId, setOfficeUpdateId] = useState(null);
  const [contactUpdateId, setContactUpdateId] = useState(null);

  const handleOfficeChange = (e) => {
    setOffice((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleOfficeDelete = (id) => {
    const result = window.confirm(
      "Are you sure you want to delete this office."
    );
    if (result) {
      const newOffices = equipmentBrand.offices.filter(
        (office, index) => index !== id
      );
      setEquipmentBrand((prev) => ({ ...prev, offices: [...newOffices] }));
    }
  };

  const handleOfficeUpdate = (id) => {
    setOffice(equipmentBrand.offices[id]);
    setOfficeUpdateId(id);
  };

  const handleOfficeSubmit = (e) => {
    officeUpdateId !== null
      ? setEquipmentBrand((prev) => ({
          ...prev,
          offices: equipmentBrand.offices?.map((o, id) =>
            officeUpdateId === id ? office : o
          ),
        }))
      : setEquipmentBrand((prev) => ({
          ...prev,
          offices: equipmentBrand.offices
            ? [...equipmentBrand.offices, office]
            : [office],
        }));
    clearOfficeData();
  };

  const clearOfficeData = () => {
    setOffice(initialOfficeData);
    setOfficeUpdateId(null);
  };

  const handleContactChange = (e) => {
    setContact((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleContactDelete = (id) => {
    const result = window.confirm(
      "Are you sure you want to delete this contact."
    );
    if (result) {
      const newContacts = equipmentBrand.contacts.filter(
        (contact, index) => index !== id
      );
      setEquipmentBrand((prev) => ({ ...prev, contacts: [...newContacts] }));
    }
  };

  const handleContactUpdate = (id) => {
    setContact(equipmentBrand.contacts[id]);
    setContactUpdateId(id);
  };

  const handleContactSubmit = (e) => {
    contactUpdateId !== null
      ? setEquipmentBrand((prev) => ({
          ...prev,
          contacts: equipmentBrand.contacts?.map((c, id) =>
            contactUpdateId === id ? contact : c
          ),
        }))
      : setEquipmentBrand((prev) => ({
          ...prev,
          contacts: equipmentBrand.contacts
            ? [...equipmentBrand.contacts, contact]
            : [contact],
        }));
    clearContactData();
  };

  const clearContactData = () => {
    setContact(initialContactData);
    setContactUpdateId(null);
  };

  const handleChange = (e) => {
    if (e.target.name === "logo") {
      setEquipmentBrand((prev) => ({
        ...prev,
        [e.target.name]: e.target.files[0],
      }));
    } else {
      setEquipmentBrand((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in equipmentBrand) {
      if (Object.hasOwnProperty.call(equipmentBrand, key)) {
        if (key === "services" || key === "offices" || key === "contacts") {
          formData.append(key, JSON.stringify(equipmentBrand[key]));
        } else {
          formData.append(key, equipmentBrand[key]);
        }
      }
    }
    if (id) {
      dispatch(updateEquipmentBrand(id, formData));
    } else {
      dispatch(createEquipmentBrand(formData));
    }
    clearData();
  };

  const clearData = () => {
    setEquipmentBrand(initialData);
    clearContactData();
    clearOfficeData();
    setToggleContact(false);
    setToggleOffice(false);
    handleClose();
  };

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setEquipmentBrand(data);
    }
  }, [data]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{id ? "Update" : "Add"} Equipment Brand</DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent>
          <CardFlex direction="column">
            <TextField
              value={equipmentBrand.name}
              name="name"
              onChange={handleChange}
              size="small"
              label="Brand Name"
              required
            />
            <TextField
              value={equipmentBrand.entityName}
              name="entityName"
              onChange={handleChange}
              size="small"
              label="Entity Name"
            />
            <UploadLogo handleChange={handleChange} name={"logo"} id={id} />
            <CardFlex direction="column">
              <Button
                onClick={() => {
                  setToggleOffice((prev) => !prev);
                  !toggleOffice && setToggleContact(false);
                }}
                variant="contained"
                autoFocus
              >
                Add Office
              </Button>
              <CardFlex
                direction="column"
                style={{ display: toggleOffice ? "flex" : "none" }}
              >
                <div className="title">
                  {officeUpdateId !== null ? "Update" : "Add"} Office
                </div>
                <CardFlex direction="column">
                  <CardFlex>
                    <TextField
                      fullWidth
                      value={office.name}
                      name="name"
                      onChange={handleOfficeChange}
                      size="small"
                      label="Name"
                    />
                    <TextField
                      fullWidth
                      value={office.location}
                      name="location"
                      onChange={handleOfficeChange}
                      size="small"
                      label="Location"
                    />
                  </CardFlex>
                  <TextField
                    fullWidth
                    type="tel"
                    value={office.phoneNo}
                    name="phoneNo"
                    onChange={handleOfficeChange}
                    size="small"
                    label="Phone No"
                  />
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    value={office.address}
                    name="address"
                    onChange={handleOfficeChange}
                    size="small"
                    label="Address"
                  />
                  <CardFlex>
                    <TextField
                      fullWidth
                      type="url"
                      value={office.websiteToDirect}
                      name="websiteToDirect"
                      onChange={handleOfficeChange}
                      size="small"
                      label="Web Site to Direct"
                    />
                    <TextField
                      fullWidth
                      type="url"
                      value={office.websiteToShow}
                      name="websiteToShow"
                      onChange={handleOfficeChange}
                      size="small"
                      label="Web Site to Show"
                    />
                  </CardFlex>

                  <CardFlex>
                    <Button
                      onClick={clearOfficeData}
                      variant="contained"
                      autoFocus
                    >
                      cancel
                    </Button>
                    <Button
                      variant="contained"
                      type="button"
                      onClick={handleOfficeSubmit}
                    >
                      {officeUpdateId !== null ? "Update" : "Add"}
                    </Button>
                  </CardFlex>
                  <CardFlex
                    wrap="wrap"
                    align="center"
                    style={{ gap: "0.2rem" }}
                  >
                    {equipmentBrand.offices?.map((office, id) => (
                      <Chip
                        key={id}
                        label={office?.name}
                        color="primary"
                        onClick={() => handleOfficeUpdate(id)}
                        onDelete={() => handleOfficeDelete(id)}
                      />
                    ))}
                  </CardFlex>
                </CardFlex>
              </CardFlex>
              <Button
                onClick={() => {
                  setToggleContact((prev) => !prev);
                  !toggleContact && setToggleOffice(false);
                }}
                variant="contained"
                autoFocus
              >
                Add Contact
              </Button>
              <CardFlex
                direction="column"
                style={{ display: toggleContact ? "flex" : "none" }}
              >
                <div className="title">
                  {contactUpdateId !== null ? "Update" : "Add"} Contact
                </div>
                <CardFlex direction="column">
                  <CardFlex>
                    <TextField
                      fullWidth
                      value={contact.firstName}
                      name="firstName"
                      onChange={handleContactChange}
                      size="small"
                      label="First Name"
                    />
                    <TextField
                      fullWidth
                      value={contact.lastName}
                      name="lastName"
                      onChange={handleContactChange}
                      size="small"
                      label="Last Name"
                    />
                  </CardFlex>
                  <TextField
                    fullWidth
                    type="email"
                    value={contact.email}
                    name="email"
                    onChange={handleContactChange}
                    size="small"
                    label="Email"
                  />
                  <TextField
                    fullWidth
                    type="tel"
                    value={contact.phoneNo}
                    name="phoneNo"
                    onChange={handleContactChange}
                    size="small"
                    label="Phone No"
                  />
                  <FormControl size="small" fullWidth>
                    <InputLabel>Offices</InputLabel>
                    <Select
                      name="offices"
                      value={contact.offices}
                      label="Offices"
                      onChange={handleContactChange}
                      multiple
                    >
                      {equipmentBrand.offices?.map((office, id) => (
                        <MenuItem key={id} value={office?.name}>
                          {office?.name?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <CardFlex>
                    <Button
                      onClick={clearContactData}
                      variant="contained"
                      autoFocus
                    >
                      cancel
                    </Button>
                    <Button
                      variant="contained"
                      type="button"
                      onClick={handleContactSubmit}
                    >
                      {contactUpdateId !== null ? "Update" : "Add"}
                    </Button>
                  </CardFlex>
                  <CardFlex
                    wrap="wrap"
                    align="center"
                    style={{ gap: "0.2rem" }}
                  >
                    {equipmentBrand.contacts?.map((contact, id) => (
                      <Chip
                        key={id}
                        label={contact?.firstName + " " + contact?.lastName}
                        color="primary"
                        onClick={() => handleContactUpdate(id)}
                        onDelete={() => handleContactDelete(id)}
                      />
                    ))}
                  </CardFlex>
                </CardFlex>
              </CardFlex>
            </CardFlex>
            <FormControl size="small" sx={{ flex: 1, minWidth: 160 }}>
              <InputLabel>Services</InputLabel>
              <Select
                name="services"
                value={equipmentBrand.services}
                label="Services"
                onChange={handleChange}
                multiple
                required
              >
                {services?.map(({ _id, name }) => (
                  <MenuItem key={_id} value={_id}>
                    {name.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardFlex>
        </DialogContent>
        <DialogActions>
          <Button onClick={clearData} variant="contained" autoFocus>
            cancel
          </Button>
          <Button variant="contained" type="submit">
            {id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Form;

import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CardFlex } from "../../../components/Card/Card.style";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  createContact,
  updateContact,
} from "../../../../redux/actions/contact";
import { useDispatch, useSelector } from "react-redux";

const initialData = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNo: "",
  offices: [],
};

const Form = ({ id, data, handleClose, open }) => {
  const dispatch = useDispatch();
  const offices = useSelector((state) => state.officeReducer);
  const [contact, setContact] = useState(initialData);

  const handleChange = (e) => {
    setContact((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (id) {
      dispatch(updateContact(id, contact));
    } else {
      dispatch(createContact(contact));
    }
    clearData();
  };

  const clearData = () => {
    setContact(initialData);
    handleClose();
  };

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setContact(data);
    }
  }, [data]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{id ? "Update" : "Add"} Contact</DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent>
          <CardFlex direction="column">
            <CardFlex>
              <TextField
                fullWidth
                value={contact.firstName}
                required
                name="firstName"
                onChange={handleChange}
                size="small"
                label="First Name"
              />
              <TextField
                fullWidth
                value={contact.lastName}
                required
                name="lastName"
                onChange={handleChange}
                size="small"
                label="Last Name"
              />
            </CardFlex>
            <TextField
              fullWidth
              type="email"
              value={contact.email}
              required
              name="email"
              onChange={handleChange}
              size="small"
              label="Email"
            />
            <TextField
              fullWidth
              type="tel"
              value={contact.phoneNo}
              required
              name="phoneNo"
              onChange={handleChange}
              size="small"
              label="Phone No"
            />
            <FormControl size="small" fullWidth>
              <InputLabel>Offices</InputLabel>
              <Select
                name="offices"
                value={contact.offices}
                label="Offices"
                onChange={handleChange}
                multiple
              >
                {offices.map(({ _id, name }) => (
                  <MenuItem key={_id} value={_id}>
                    {name.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardFlex>
        </DialogContent>
        <DialogActions>
          <Button onClick={clearData} variant="contained" autoFocus>
            cancel
          </Button>
          <Button variant="contained" type="submit">
            {id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Form;

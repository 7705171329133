import { failure, warning, loginSuccess } from "../../utils/notification";
import * as api from "../api";
import * as type from "../types";

export const signin = (formData, navigate) => async (dispatch) => {
  try {
    const { data, status } = await api.signin(formData);
    if (status === 200) {
      dispatch({ type: type.AUTH, payload: data });
      loginSuccess();
      navigate(`/`);
    } else {
      warning(data.message);
    }
  } catch (error) {
    failure(error.message);
  }
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  createService,
  getServices,
  updateService,
} from "../../../redux/actions/service";
import CreateFields from "./Form/Field/CreateFields";
import ViewFields from "./Form/Field/ViewFields";
import { updateWorkType } from "../../../redux/api/workType.api";
import Table from "../../components/Table/Table";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import WorkTypeForm from "./Components/WorkTypeForm";
import { getServiceById } from "../../../redux/api/service.api";
import { FaPlus } from "react-icons/fa";
import { baseURL } from "../../../redux/api/api";

const initialField = {
  workTypes: [],
  type: "",
  label: "",
  name: "",
  module: "",
  optionSet: "",
  filter: "",
  required: false,
  allowOptionsByUsers: false,
  filterable: false,
  filterType: "",
  filterSubType: "",
};

const ServiceForm = () => {
  const workTypesTableColumns = [
    {
      header: "Name",
      fieldName: "name",
      sortable: false,
      render: (row) => row?.name,
    },
    {
      header: "Cost Label",
      fieldName: "costLabel",
      sortable: false,
      render: (row) => row?.costLabel,
    },
    {
      header: "Actions",
      fieldName: "actions",
      sortable: false,
      render: (row, index) => (
        <div className="flex items-center gap-2">
          <button
            type="button"
            onClick={() => {
              setWorkTypeToEdit({
                ...row,
                selectedIndex: index,
              });
              setWorkTypePopupOpen(true);
            }}
            className="relative inline-flex items-center rounded-md px-1 py-1 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          >
            <PencilIcon
              className="h-5 w-5 text-green-500"
              aria-hidden="true"
            ></PencilIcon>
          </button>
          <button
            type="button"
            onClick={() => handleDeleteWorkType(index)}
            className="relative inline-flex items-center rounded-md px-1 py-1 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          >
            <TrashIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            ></TrashIcon>
          </button>
        </div>
      ),
    },
  ];

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const services = useSelector((state) => state.serviceReducer);
  const [service, setService] = useState({ name: "" });
  const [field, setField] = useState(initialField);
  const [fields, setFields] = useState([]);
  const [fieldId, setFieldId] = useState(null);
  const [projectMarkerIconPreview, setProjectMarkerIconPreview] =
    useState(null);

  const [workTypePopupOpen, setWorkTypePopupOpen] = useState(false);
  const [workTypes, setWorkTypes] = useState([]);
  const [selectedWorkTypes, setSelectedWorkTypes] = useState([]);
  const [workTypeToEdit, setWorkTypeToEdit] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (id) {
      getService();
    }
  }, [id]);

  const getService = async () => {
    const res = await getServiceById(id);
    setService(res.data);
    setFields(res.data.fields);
    setWorkTypes(res.data?.workTypes);
  };

  const handleFieldChange = (e) => {
    let { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      value = checked;
    } else {
      value =
        name === "name" ? value.toLowerCase().replace(/\s+/g, "_") : value;
    }
    setField((prev) => ({
      ...prev,
      [name]: name === "required" ? e.target.checked : value,
    }));
  };

  const handleFieldSubmit = (e) => {
    e.preventDefault();
    console.log(field);
    const bool = fields?.find(
      (f, id) => f.name === field.name && id !== fieldId && f.name !== ""
    );
    if (!bool) {
      if (fieldId !== null) {
        const newFields = fields.map((f, id) => (id === fieldId ? field : f));
        setFields([...newFields]);
      } else {
        setFields((prev) => [...prev, { ...field }]);
      }
      clearField();
    } else {
      alert("Field name already exist");
    }
  };

  const clearField = () => {
    setField(initialField);
    setFieldId(null);
    setSelectedWorkTypes([]);
  };

  const handleChange = (e) => {
    if (e.target.name === "projectMarkerIcon") {
      const file = e.target.files[0];
      if (file) {
        setSelectedFile(file);
        setProjectMarkerIconPreview(URL.createObjectURL(file));
      }
      setService((prev) => ({ ...prev, [e.target.name]: file }));
    } else {
      setService((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const handleDeleteWorkType = (index) => {
    const newWorkTypes = workTypes.filter((o, i) => i !== index);
    setWorkTypes(newWorkTypes);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (service.name === "") {
      alert("Service name is required");
      return;
    }
    if (workTypes.length === 0) {
      alert("Please add at least one project type");
      return;
    }
    const formData = new FormData();
    formData.append("name", service.name);
    formData.append("projectMarkerIcon", service.projectMarkerIcon);
    formData.append(
      "fields",
      JSON.stringify(
        fields?.map((field) => ({
          ...field,
          workTypes: field?.workTypes?.map((workType) => workType?._id),
        }))
      )
    );
    formData.append("workTypes", JSON.stringify(workTypes));
    if (id) {
      dispatch(updateService(id, formData));
    } else {
      dispatch(createService(formData));
    }
    navigate("/services");
    clearData();
  };

  const clearData = () => {
    // setService({ name: "" });
    setFields([]);
    setWorkTypes([]);
    clearField();
  };

  useEffect(() => {
    dispatch(getServices());
  }, [dispatch]);

  // useEffect(() => {
  //   if (Object.keys(data).length > 0) {
  //     setService({ name: data?.name });
  //     setFields(data?.fields);
  //   }
  // }, [data]);

  return (
    <div className="md:grid md:grid-cols-2 gap-4">
      <WorkTypeForm
        open={workTypePopupOpen}
        setOpen={setWorkTypePopupOpen}
        workTypes={workTypes}
        setWorkTypes={setWorkTypes}
        dataToEdit={workTypeToEdit}
      />
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div className="md:col-span-1 rounded-md">
          <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 rounded-t-lg">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-2">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Add New Service
                </h3>
              </div>
              <div className="ml-4 mt-2 flex-shrink-0">
                <button
                  type="submit"
                  className="relative inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                >
                  {id ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </div>
          <div className="bg-white px-4 sm:px-6 rounded-b-lg">
            <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
              <div className="space-y-1 sm:gap-4 sm:space-y-4 sm:py-5">
                <div className="grid grid-cols-3 space-x-4">
                  <div className="col-span-2">
                    <label
                      htmlFor="serviceName"
                      className="block font-medium leading-6 text-gray-900"
                    >
                      Name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="name"
                        id="serviceName"
                        value={service.name}
                        onChange={handleChange}
                        autoComplete="off"
                        placeholder="Service Name"
                        autoFocus
                        className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="col-span-1">
                    <label
                      htmlFor="projectMarkerIcon"
                      className="block font-medium leading-6 text-gray-900"
                    >
                      Map Marker
                    </label>
                    <div className="border-2 border-dashed border-gray-200 flex items-center h-10 justify-center w-10 mt-2">
                      <label
                        htmlFor="projectMarkerIcon"
                        className="mx-auto flex items-center justify-center cursor-pointer"
                      >
                        {service?.projectMarkerIcon ? (
                          <img
                            src={
                              selectedFile
                                ? URL.createObjectURL(selectedFile)
                                : `${baseURL}s3/images/${service?.projectMarkerIcon}`
                            }
                            alt="Selected"
                            className="h-10 w-10 object-cover"
                          />
                        ) : (
                          <FaPlus className="h-5 w-5" />
                        )}
                      </label>
                      <input
                        type="file"
                        name="projectMarkerIcon"
                        id="projectMarkerIcon"
                        className="hidden"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-center justify-between">
                    <h3 className="block font-medium cursor-default leading-6 text-gray-900">
                      Project Types
                    </h3>
                    <button
                      type="button"
                      onClick={() => {
                        setWorkTypePopupOpen(true);
                      }}
                      className="relative inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                    >
                      Add
                    </button>
                  </div>
                  <div className="mt-2">
                    {/* Table to show work types */}
                    <Table
                      pagination={false}
                      columns={workTypesTableColumns}
                      data={workTypes}
                    />
                  </div>
                </div>
              </div>
              <div className="space-y-1 sm:gap-4 sm:space-y-0 sm:py-5">
                <CreateFields
                  services={services}
                  workTypes={workTypes}
                  selectedWorkTypes={selectedWorkTypes}
                  setSelectedWorkTypes={setSelectedWorkTypes}
                  setField={setField}
                  field={field}
                  fieldId={fieldId}
                  handleFieldChange={handleFieldChange}
                  handleFieldSubmit={handleFieldSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="md:col-span-1 rounded-md">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 rounded-t-lg">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Form Demo
              </h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              {/* <button
                type="button"
                className="relative inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                Save
              </button> */}
            </div>
          </div>
        </div>
        <div className="bg-white px-4 sm:px-6 rounded-b-lg">
          {fields?.length > 0 ? (
            <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
              <div className="space-y-1 sm:gap-4 sm:space-y-0 sm:py-5">
                <ViewFields
                  fields={fields}
                  setFields={setFields}
                  setField={setField}
                  setFieldId={setFieldId}
                  setSelectedWorkTypes={setSelectedWorkTypes}
                />
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center h-96">
              <h1 className="text-2xl font-semibold text-gray-400">
                No Fields Added
              </h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceForm;

import { API } from "./api";

export const getUsers = () => API.get("/users");

export const getAllIndividualUsers = () => API.get("/users/individuals");

export const createUser = (newUser, companyId = null) => {
  if (companyId) {
    return new Promise((resolve, reject) => {
      API.post(`/users/${companyId}`, newUser)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }
  return new Promise((resolve, reject) => {
    API.post("/users", newUser)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const updateUser = (id, updatedUser) =>
  API.patch(`/users/${id}`, updatedUser);

export const deleteUser = (id) => API.delete(`/users/${id}`);

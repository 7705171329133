import React, { useEffect, useState } from "react";
import {
  Card,
  CardFlex,
  CardHeader,
  Title,
} from "../../components/Card/Card.style";
import { Button } from "@mui/material";
import ViewTable from "../../components/ViewTable/ViewTable";
import AddIcon from "@mui/icons-material/Add";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { getUploadRequests } from "../../../redux/api/project.api";

const columnsName = ["Uploaded By", "Date", "Time", "File"];

const CsvUploadRequests = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [CsvUploadRequests, setCsvUploadRequests] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [csvFile, setCsvFile] = useState(null);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    getUploadRequests()
      .then((res) => {
        setCsvUploadRequests(res?.data);
        console.log("Requests data", res?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleDownload = (url, filename) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error("Error downloading file:", error));
  };
  return (
    <CardFlex wrap="wrap" align="flex-start">
      <CardFlex flex="25rem" direction="column">
        <Card>
          <CardHeader>
            <Title>Csv Upload Requests</Title>
          </CardHeader>
          <div>
            <ViewTable
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rows={CsvUploadRequests}
              columnsName={columnsName}
            >
              {CsvUploadRequests.map((csvRequest, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{csvRequest.uploadedBy}</td>
                  <td>{csvRequest.createdAt.split("T")[0]}</td>
                  <td>{csvRequest.updatedAt.split("T")[1]}</td>
                  <td>{csvRequest.csvFile}</td>
                  <td>
                    <CardFlex justify="center">
                      <Button
                        onClick={() =>
                          handleDownload(csvRequest.csvFile, "filename.csv")
                        }
                        variant="contained"
                      >
                        Download
                      </Button>
                    </CardFlex>
                  </td>
                </tr>
              ))}
            </ViewTable>{" "}
          </div>
        </Card>
      </CardFlex>
    </CardFlex>
  );
};

export default CsvUploadRequests;

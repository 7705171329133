import { failure, success } from "../../utils/notification";
import * as api from "../api";
import {
  CREATE_COMPANY,
  DELETE_COMPANY,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_REQUEST,
  UPDATE_COMPANY,
  UPDATE_COMPANY_ADVERTISEMENT,
} from "../types/companies.types";

export const getCompanies =
  (filter = null, sort = null, pagination = null) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_COMPANIES_REQUEST });
      const { data } = await api.getCompanies(filter, sort, pagination);
      dispatch({ type: GET_COMPANIES_SUCCESS, payload: data });
    } catch (error) {
      failure(error.message);
    }
  };

export const createCompany = (company) => async (dispatch) => {
  try {
    const { data, status } = await api.createCompany(company);
    if (status === 200) {
      dispatch({ type: CREATE_COMPANY, payload: data });
      success("Saved");
    }
  } catch (error) {
    failure(error.message);
  }
};

export const updateCompany = (id, company) => async (dispatch) => {
  console.log("updateCompany", id, company);
  try {
    const { data, status } = await api.updateCompany(id, company);
    if (status === 200) {
      dispatch({ type: UPDATE_COMPANY, payload: data });
      success("Updated");
    }
  } catch (error) {
    failure(error.message);
  }
};

export const updateCompanyAdvertisment = (id, company) => async (dispatch) => {
  try {
    const { data, status } = await api.updateCompanyAdvertisment(id, company);
    if (status === 200) {
      dispatch({ type: UPDATE_COMPANY_ADVERTISEMENT, payload: data });
      success("Updated");
    }
  } catch (error) {
    failure(error.message);
  }
};

export const deleteCompany = (id) => async (dispatch) => {
  try {
    const { status } = await api.deleteCompany(id);
    if (status === 200) {
      dispatch({ type: DELETE_COMPANY, payload: id });
      success("Deleted");
    }
  } catch (error) {
    failure(error.message);
  }
};

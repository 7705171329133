import { failure, success } from "../../utils/notification";
import * as api from "../api";
import * as type from "../types";

export const getContacts = () => async (dispatch) => {
  try {
    const { data } = await api.getContacts();
    dispatch({ type: type.GET_CONTACTS, payload: data });
  } catch (error) {
    failure(error.message);
  }
};

export const createContact = (contact) => async (dispatch) => {
  try {
    const { data, status } = await api.createContact(contact);
    if (status === 200) {
      dispatch({ type: type.CREATE_CONTACT, payload: data });
      success("Saved");
    }
  } catch (error) {
    failure(error.message);
  }
};

export const updateContact = (id, contact) => async (dispatch) => {
  try {
    const { data, status } = await api.updateContact(id, contact);
    if (status === 200) {
      dispatch({ type: type.UPDATE_CONTACT, payload: data });
      success("Updated");
    }
  } catch (error) {
    failure(error.message);
  }
};

export const deleteContact = (id) => async (dispatch) => {
  try {
    const { status } = await api.deleteContact(id);
    if (status === 200) {
      dispatch({ type: type.DELETE_CONTACT, payload: id });
      success("Deleted");
    }
  } catch (error) {
    failure(error.message);
  }
};

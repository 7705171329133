import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CardFlex } from "../../../components/Card/Card.style";
import {
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  createOptionSet,
  updateOptionSet,
} from "../../../../redux/actions/optionSet";
import { useDispatch } from "react-redux";

const initialData = {
  name: "",
};

const types = ["number", "text"];

const Form = ({ id, data, handleClose, open }) => {
  const dispatch = useDispatch();
  const [optionSet, setOptionSet] = useState(initialData);
  const [type, setType] = useState("text");
  const [options, setOptions] = useState([]);
  const [option, setOption] = useState("");
  const [updateId, setUpdateId] = useState(null);

  const handleDelete = (id) => {
    const result = window.confirm(
      "Are you sure you want to delete this field."
    );
    if (result) {
      const newOptions = options.filter((o, index) => index !== id);
      setOptions([...newOptions]);
    }
  };

  const handleUpdate = (id) => {
    setOption(options[id]);
    setUpdateId(id);
  };

  const handleChange = (e) => {
    setOptionSet((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleChangeType = (e) => {
    setType(e.target.value);
    setOption("");
  };

  const handleOptionAdd = (e) => {
    e.preventDefault();
    updateId !== null
      ? setOptions((prev) =>
          prev.map((p, id) => (updateId === id ? option : p))
        )
      : setOptions((prev) => [...prev, option]);
    setOption("");
    setUpdateId(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (id) {
      dispatch(updateOptionSet(id, { ...optionSet, options }));
    } else {
      dispatch(createOptionSet({ ...optionSet, options }));
    }
    clearData();
  };

  const clearData = () => {
    setOptionSet(initialData);
    setOption("");
    setOptions([]);
    setUpdateId(null);
    handleClose();
  };

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setOptionSet({ name: data.name });
      setOptions(data.options);
    }
  }, [data]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{id ? "Update" : "Add"} Option Set</DialogTitle>
      <DialogContent>
        <CardFlex direction="column">
          <form autoComplete="off" onSubmit={handleSubmit}>
            <CardFlex direction="column">
              <TextField
                value={optionSet.name}
                name="name"
                onChange={handleChange}
                size="small"
                label="Name"
                required
              />
            </CardFlex>
            <DialogActions
              style={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <Button onClick={clearData} variant="contained" autoFocus>
                cancel
              </Button>
              <Button variant="contained" type="submit">
                {id ? "Update" : "Save"}
              </Button>
            </DialogActions>
          </form>
          <form onSubmit={handleOptionAdd}>
            <CardFlex direction="column">
              <CardFlex>
                <TextField
                  value={option}
                  name="option"
                  onChange={(e) => setOption(e.target.value)}
                  size="small"
                  label="Option"
                  required
                  type={type}
                  fullWidth
                />
                <FormControl size="small" sx={{ width: "15rem" }}>
                  <InputLabel>Field Type</InputLabel>
                  <Select
                    name="type"
                    value={type}
                    label="Field Type"
                    required
                    onChange={handleChangeType}
                  >
                    <MenuItem value="">Field Type</MenuItem>
                    {types.map((type, id) => (
                      <MenuItem key={id} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button type="submit" variant="contained" autoFocus>
                  {updateId !== null ? "Update" : "Add"}
                </Button>
              </CardFlex>
              <CardFlex direction="column">
                <div className="title">Your Options</div>
                <CardFlex align="center" style={{ gap: "0.2rem" }}>
                  {options.map((option, index) => (
                    <Chip
                      key={index}
                      label={option}
                      color="primary"
                      onClick={() => handleUpdate(index)}
                      onDelete={() => handleDelete(index)}
                    />
                  ))}
                </CardFlex>
              </CardFlex>
            </CardFlex>
          </form>
        </CardFlex>
      </DialogContent>
    </Dialog>
  );
};

export default Form;

import styled from "styled-components";
import ListItemButton from "@mui/material/ListItemButton";

export const ItemButton = styled(ListItemButton)`
  margin-bottom: 0.6rem;
  height: 3.5rem;
  font-size: 14px;
  font-weight: 600;
  color: #808191;
  :hover {
    color: ${(props) => props.theme.main};
  }
`;

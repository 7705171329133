import React, { useEffect, useState } from "react";
import {
  Card,
  CardFlex,
  CardHeader,
  Title,
} from "../../components/Card/Card.style";
import { Button } from "@mui/material";
import ViewTable from "../../components/ViewTable/ViewTable";
import AddIcon from "@mui/icons-material/Add";
import SearchBar from "../../components/SearchBar/SearchBar";
import Form from "./Form/Form";
import {
  getOptionSets,
  deleteOptionSet,
} from "../../../redux/actions/optionSet";
import { useDispatch, useSelector } from "react-redux";
import { confirmation } from "../../../utils/notification";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const columnsName = ["Name", "Options"];

const OptionSets = () => {
  const optionSets = useSelector((state) => state.optionSetReducer);
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filteredOptionSets, setFilteredOptionSets] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [id, setID] = useState(null);
  const [data, setData] = useState([]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - optionSets.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = (id = null, data = []) => {
    setID(id);
    setData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (id) => {
    confirmation().then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteOptionSet(id));
      }
    });
  };

  useEffect(() => {
    dispatch(getOptionSets());
  }, [dispatch]);

  useEffect(() => {
    setFilteredOptionSets(
      optionSets.filter((optionSet) =>
        optionSet.name.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [searchText, optionSets]);

  return (
    <CardFlex wrap="wrap" align="flex-start">
      <CardFlex flex="25rem" direction="column">
        <Form id={id} data={data} handleClose={handleClose} open={open} />
        <Card>
          <CardHeader>
            <Title>Option Sets</Title>
            <Button
              variant="contained"
              onClick={() => handleClickOpen()}
              endIcon={<AddIcon />}
            >
              Add Option Set
            </Button>
          </CardHeader>
          <div className="min-w-0 flex-1 my-4">
            <div className="relative rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="search"
                name="nameFilter"
                id="nameFilter"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                className="block w-full rounded-md border-gray-300 border pl-10 focus:border-primary-600 focus:ring-primary-600 sm:text-sm py-2.5 px-3"
                placeholder="Search by name (any)"
              />
            </div>
          </div>
          <div>
            <ViewTable
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              emptyRows={emptyRows}
              rows={filteredOptionSets}
              columnsName={columnsName}
            >
              {filteredOptionSets
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map(({ _id, name, options }, index) => (
                  <tr key={_id}>
                    <td>{index + 1}</td>
                    <td>{name}</td>
                    <td>{options?.length}</td>
                    <td>
                      <CardFlex justify="center">
                        <Button
                          onClick={() => handleDelete(_id)}
                          variant="contained"
                        >
                          Delete
                        </Button>
                        <Button
                          onClick={() =>
                            handleClickOpen(_id, { name, options })
                          }
                          variant="contained"
                        >
                          Update
                        </Button>
                      </CardFlex>
                    </td>
                  </tr>
                ))}
            </ViewTable>
          </div>
        </Card>
      </CardFlex>
    </CardFlex>
  );
};

export default OptionSets;

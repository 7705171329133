import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CardFlex } from "../../../components/Card/Card.style";
import { Autocomplete, Button, TextField } from "@mui/material";
import { createUser, updateUser } from "../../../../redux/actions/user";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Select } from "@mui/material";

const initialData = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  role: "",
  systemID: "",
};

const Form = ({ id, data, handleClose, open }) => {
  const dispatch = useDispatch();
  const { equipmentBrands } = useSelector(
    (state) => state.equipmentBrandReducer
  );
  const { companies } = useSelector((state) => state.companyReducer);
  const roles = useSelector((state) => state.roleReducer);
  const [systems, setSystems] = useState([]);
  const [user, setUser] = useState(initialData);
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleChange = (e) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user.password === confirmPassword) {
      if (id) {
        dispatch(updateUser(id, { ...user, systemID: user?.systemID?._id }));
      } else {
        dispatch(createUser({ ...user, systemID: user?.systemID?._id }));
      }
      clearData();
    } else {
      alert("Your password didn't match.");
    }
  };

  const clearData = () => {
    setSystems([]);
    setConfirmPassword("");
    setUser(initialData);
    handleClose();
  };

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setUser(data);
      setConfirmPassword(data.password);
    }
  }, [data]);

  useEffect(() => {
    if (user.role === "brand") {
      setSystems(equipmentBrands);
    } else if (user.role === "company") {
      setSystems(companies);
    } else if (user.role === "admin") {
      setSystems(roles);
    }
  }, [user.role, companies, equipmentBrands, roles]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{id ? "Update" : "Add"} User</DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent>
          <CardFlex direction="column">
            <CardFlex>
              <TextField
                fullWidth
                value={user.firstName}
                required
                name="firstName"
                onChange={handleChange}
                size="small"
                label="First Name"
              />
              <TextField
                fullWidth
                value={user.lastName}
                required
                name="lastName"
                onChange={handleChange}
                size="small"
                label="Last Name"
              />
            </CardFlex>
            <CardFlex>
              <TextField
                fullWidth
                type="email"
                value={user.email}
                required
                name="email"
                onChange={handleChange}
                size="small"
                label="Email"
              />
              <FormControl size="small" fullWidth>
                <InputLabel>Role</InputLabel>
                <Select
                  name="role"
                  value={user.role}
                  label="Role"
                  onChange={handleChange}
                  required
                >
                  {["brand", "company", "admin"].map((role, id) => (
                    <MenuItem key={id} value={role}>
                      {role.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardFlex>
            <Autocomplete
              options={systems}
              getOptionLabel={(option) => (option ? option?.name : "")}
              value={user.systemID}
              onChange={(event, value) =>
                setUser((prev) => ({
                  ...prev,
                  systemID: value,
                }))
              }
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField
                  name="systemID"
                  {...params}
                  label="System"
                  required
                />
              )}
            />
            <CardFlex>
              <TextField
                fullWidth
                type="password"
                value={user.password}
                required
                name="password"
                onChange={handleChange}
                size="small"
                label="Password"
                autoComplete="false"
              />
              <TextField
                fullWidth
                type="password"
                required
                value={confirmPassword}
                name="confirmPassword"
                size="small"
                onChange={(e) => setConfirmPassword(e.target.value)}
                label="Confirm Password"
                autoComplete="false"
              />
            </CardFlex>
          </CardFlex>
        </DialogContent>
        <DialogActions>
          <Button onClick={clearData} variant="contained" autoFocus>
            cancel
          </Button>
          <Button variant="contained" type="submit">
            {id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Form;

import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import Services from "../Services/Services";
import EquipmentTypes from "../EquipmentTypes/EquipmentTypes";
import { MainContainer } from "./Main.style";
import PageNotFound from "../PageNotFound/PageNotFound";
import EquipmentBrands from "../EquipmentBrands/EquipmentBrands";
import EquipmentModels from "../EquipmentModels/EquipmentModels";
import WorkTypes from "../WorkTypes/WorkTypes";
import Companies from "../Companies/Companies";
import Users from "../Users/Users";
import Projects from "../Projects/Projects";
import Offices from "../Offices/Offices";
import Contacts from "../Contacts/Contacts";
import OptionSets from "../OptionSets/OptionSet";
import Roles from "../Roles/Roles";
import MergeEquipmentBrands from "../EquipmentBrands/MergeEquipmentBrands";
import MergeEquipmentModels from "../EquipmentModels/MergeEquipmentModels";
import MergeCompanies from "../Companies/MergeCompanies";
// import MergeUtilities from "../Utilities/MergeUtilities";
import ServiceForm from "../Services/ServiceForm";
import Utilities from "../Utilities/Utilities";
import MergeUtilities from "../Utilities/MergeUtilities";
import CreateCompany from "../Companies/CreateCompany/CreateCompany";
import EquipmentBrandForm from "../EquipmentBrands/CreateEquipmentForm/CreateEquipmentBrand";
import CsvUploadRequests from "../CsvUploadRequests/CsvUploadRequests";
import AddProject from "../Projects/Form/AddProject";
import AppearanceSettings from "../AppearanceSettings/AppearanceSettings";

const Main = () => {
  return (
    <MainContainer>
      <Routes>
        <Route index element={<Dashboard />} />
        <Route path="services" element={<Services />} />
        <Route path="services/add" element={<ServiceForm />} />
        <Route path="services/edit/:id" element={<ServiceForm />} />
        <Route path="projects" element={<Projects />} />
        <Route path="equipment-types" element={<EquipmentTypes />} />
        <Route path="equipment-brands" element={<EquipmentBrands />} />
        <Route path="equipment-brands/add" element={<EquipmentBrandForm />} />
        <Route path="CsvUploadRequests" element={<CsvUploadRequests />} />
        <Route path="project/add" element={<AddProject />} />
        <Route
          path="equipment-brands/update/:id"
          element={<EquipmentBrandForm />}
        />
        <Route
          path="merge-equipment-brands"
          element={<MergeEquipmentBrands />}
        />
        <Route path="equipment-models" element={<EquipmentModels />} />
        <Route
          path="merge-equipment-models"
          element={<MergeEquipmentModels />}
        />
        <Route path="utilities" element={<Utilities />} />
        <Route path="merge-utilities" element={<MergeUtilities />} />
        <Route path="companies" element={<Companies />} />
        <Route path="companies/new-company" element={<CreateCompany />} />
        <Route path="companies/update/:id" element={<CreateCompany />} />
        <Route path="merge-companies" element={<MergeCompanies />} />
        <Route path="offices" element={<Offices />} />
        <Route path="contacts" element={<Contacts />} />
        <Route path="users" element={<Users />} />
        <Route path="roles" element={<Roles />} />
        <Route path="option-sets" element={<OptionSets />} />
        <Route path="settings" element={<AppearanceSettings />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </MainContainer>
  );
};

export default Main;

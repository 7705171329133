import React, { useEffect, useState } from "react";
import {
  Card,
  CardFlex,
  CardHeader,
  Title,
} from "../../components/Card/Card.style";
import { Button } from "@mui/material";
import ViewTable from "../../components/ViewTable/ViewTable";
import AddIcon from "@mui/icons-material/Add";
import SearchBar from "../../components/SearchBar/SearchBar";
import Form from "./Form/Form";
import { getProjects, deleteProject } from "../../../redux/actions/project";
import { useDispatch, useSelector } from "react-redux";
import { confirmation } from "../../../utils/notification";
import { getServices } from "../../../redux/actions/service";
import { getWorkTypes } from "../../../redux/actions/workType";
import { getOptionSets } from "../../../redux/actions/optionSet";
import CsvUpload from "./CSVUpload/CsvUpload";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

const columnsName = ["Company", "Project Type", "Address", "Service"];

const Projects = () => {
  const projects = useSelector((state) => state.projectReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [id, setID] = useState(null);
  const [data, setData] = useState([]);
  const [csvFile, setCsvFile] = useState(null);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - projects.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = (id = null, data = []) => {
    setID(id);
    // console.log(data);
    setData(data);
    // setOpen(true);
    navigate("/project/add");
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const handleDelete = (id) => {
    confirmation().then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteProject(id));
      }
    });
  };

  useEffect(() => {
    dispatch(getProjects());
    dispatch(getServices());
    dispatch(getOptionSets());
  }, [dispatch]);

  useEffect(() => {
    setFilteredProjects(
      projects.filter(
        (project) =>
          project?.companyData?.name
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
          project?.serviceData?.name
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
          project?.address?.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [searchText, projects]);

  return (
    <CardFlex wrap="wrap" align="flex-start">
      <CardFlex flex="25rem" direction="column">
        <Form id={id} data={data} open={open} />
        <Card>
          <CardHeader>
            <Title>Projects</Title>
            <div>
              <Button
                variant="contained"
                onClick={() => handleClickOpen()}
                endIcon={<AddIcon />}
              >
                Add Project
              </Button>
              <Button
                component="label"
                variant="contained"
                endIcon={<AddIcon />}
              >
                Upload CSV
                <input
                  type="file"
                  onChange={(e) => {
                    setCsvFile(e.target.files[0]);
                  }}
                  accept=".csv"
                  hidden
                />
              </Button>
            </div>
          </CardHeader>
          <div className="min-w-0 flex-1 my-4">
            <div className="relative rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="search"
                name="nameFilter"
                id="nameFilter"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                className="block w-full rounded-md border-gray-300 border pl-10 focus:border-primary-600 focus:ring-primary-600 sm:text-sm py-2.5 px-3"
                placeholder="Search by name (any)"
              />
            </div>
          </div>
          <div>
            {csvFile ? (
              <CsvUpload csvFile={csvFile} setCsvFile={setCsvFile} />
            ) : (
              <ViewTable
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                emptyRows={emptyRows}
                rows={filteredProjects}
                columnsName={columnsName}
              >
                {filteredProjects
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map(
                    (
                      {
                        _id,
                        company,
                        companyData,
                        workType,
                        address,
                        service,
                        isDeleted,
                        serviceData,
                        projectData,
                      },
                      index
                    ) => (
                      <tr key={_id} className={isDeleted ? "bg-red-200" : ""}>
                        <td>{index + 1}</td>
                        <td>{companyData?.name}</td>
                        <td>{workType}</td>
                        <td>{address}</td>
                        <td>{serviceData.name}</td>
                        <td>
                          <CardFlex justify="center">
                            {isDeleted ? (
                              ""
                            ) : (
                              // <Button
                              //   onClick={() => handleDelete(_id)}
                              //   variant="contained"
                              // >
                              //   Restore
                              // </Button>
                              <>
                                <Button
                                  onClick={() => handleDelete(_id)}
                                  variant="contained"
                                >
                                  Delete
                                </Button>
                                <Button
                                  onClick={() =>
                                    handleClickOpen(_id, {
                                      company,
                                      workType,
                                      address,
                                      service,
                                      projectData,
                                      index: service,
                                    })
                                  }
                                  variant="contained"
                                >
                                  Update
                                </Button>
                              </>
                            )}
                          </CardFlex>
                        </td>
                      </tr>
                    )
                  )}
              </ViewTable>
            )}
          </div>
        </Card>
      </CardFlex>
    </CardFlex>
  );
};

export default Projects;

import React from "react";
import { Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { GlobalStyle } from "./App.style";
import { ThemeProvider as StyledProvider } from "styled-components";
import AdminPanel from "./admin/pages/AdminPanel/AdminPanel";
import AdminLogin from "./admin/pages/Login/Login";
import PageNotFound from "./admin/pages/PageNotFound/PageNotFound";
import "./index.css";
import { LoadScript } from "@react-google-maps/api";
import Notification from "./admin/components/Notification/Notification";

const LIBRARIES = ["places", "geometry"];

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#2faf4d",
      contrastText: "#fff",
    },
  },
});

const styledTheme = {
  main: "#2faf4d",
  darkMain: "#2c8541",
};

const App = () => {
  return (
    <ThemeProvider theme={muiTheme}>
      <StyledProvider theme={styledTheme}>
        <GlobalStyle />
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          libraries={LIBRARIES}
        >
          <Routes>
            <Route path="/login" element={<AdminLogin />} />
            <Route path="/*" element={<AdminPanel />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </LoadScript>
      </StyledProvider>
    </ThemeProvider>
  );
};

export default App;

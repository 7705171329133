import React from "react";
import Divider from "@mui/material/Divider";
import Header from "./Header/Header";
import List from "./List/List";
import { Drawer } from "./LeftDrawer.style";

const LeftDrawer = ({ open, handleDrawerClose }) => {
  return (
    <Drawer variant="permanent" open={open}>
      <Header handleDrawerClose={handleDrawerClose} />
      <Divider />
      <List />
    </Drawer>
  );
};

export default LeftDrawer;

import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useState } from "react";
import { createContact, updateContact } from "../../../../redux/api";
import { createContactInEquipmentBrand } from "../../../../redux/api/contact.api";
import CustomDropDown from "../../../components/CustomDropDown/CustomDropDown";

const AddContactPopup = ({
  data = {},
  open,
  setOpen,
  equipmentBrand,
  setEquipmentBrand,
  offices,
  editing,
}) => {
  const initialData = {
    firstName: "",
    lastName: "",
    phoneNo: "",
    email: "",
    offices: [],
    active: true,
  };
  const [contact, setContact] = useState(initialData);
  const [officeQuery, setOfficeQuery] = useState("");
  const [filteredOffices, setFilteredOffices] = useState([]);
  const [officesSelectedValue, setOfficesSelectedValue] = useState([]);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) setContact(data);
  }, [data]);

  useEffect(() => {
    setFilteredOffices(
      offices?.filter((office) =>
        office.name.toLowerCase().includes(officeQuery.toLowerCase())
      )
    );
  }, [officeQuery]);

  const handleChange = (event) => {
    const { name, value, type } = event.target;
    if (name === "offices") {
      setContact({ ...contact, [name]: [value] });
      return;
    }
    if (type === "checkbox") {
      setContact({ ...contact, [name]: !contact[name] });
      return;
    }
    setContact({ ...contact, [name]: value });
  };

  const handleSubmitItem = async (event) => {
    event.preventDefault();

    if (data && Object.keys(data).length > 0) {
      const res = await updateContact(data?._id, {
        ...contact,
        offices: contact?.offices?.map((office) => office._id),
      });
      console.log(res.data);
      setEquipmentBrand({
        ...equipmentBrand,
        contacts: equipmentBrand?.contacts?.map((contact) =>
          contact._id === res.data._id ? res.data : contact
        ),
      });
    } else {
      if (equipmentBrand?._id) {
        const newContact = await createContactInEquipmentBrand(
          equipmentBrand?._id,
          {
            ...contact,
            offices: contact?.offices?.map((office) => office._id),
          }
        );
        setEquipmentBrand({
          ...equipmentBrand,
          contacts: [...equipmentBrand?.contacts, newContact],
        });
      } else {
        const newContact = await createContact({
          ...contact,
          offices: contact?.offices?.map((office) => office._id),
        });
        setEquipmentBrand({
          ...equipmentBrand,
          contacts: [...equipmentBrand?.contacts, newContact],
        });
      }
    }
    setContact(initialData);
    setOpen(false);
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {data && Object.keys(data).length > 0
                        ? "Update"
                        : "Create"}{" "}
                      Contact
                    </Dialog.Title>
                  </div>
                </div>
                <form onSubmit={handleSubmitItem}>
                  <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                    <div>
                      <label
                        htmlFor="firstName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        First Name
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        value={contact?.firstName}
                        onChange={handleChange}
                        placeholder="First Name"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="lastName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Last Name
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        value={contact?.lastName}
                        onChange={handleChange}
                        placeholder="Last Name"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="phoneNo"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Phone Number
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        type="text"
                        name="phoneNo"
                        id="phoneNo"
                        className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        value={contact?.phoneNo}
                        onChange={handleChange}
                        placeholder="Phone Number"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        value={contact?.email}
                        onChange={handleChange}
                        placeholder="Email"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="offices"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Offices
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <CustomDropDown
                        multiple={true}
                        query={officeQuery}
                        setQuery={setOfficeQuery}
                        // loading={brandsOfferedLoading}
                        // setLoading={setBrandsOfferedLoading}
                        value={contact?.offices}
                        displayValue={(offices) =>
                          offices.length > 0
                            ? offices?.map((office) => office.name).join(", ")
                            : ""
                        }
                        data={offices}
                        filteredData={filteredOffices}
                        setFilteredData={setFilteredOffices}
                        handleSelect={(thisOffice) => {
                          if (
                            !contact?.offices?.find(
                              (office) => office._id === thisOffice._id
                            )
                          ) {
                            setContact({
                              ...contact,
                              offices: [...contact?.offices, thisOffice],
                            });
                          } else {
                            setContact({
                              ...contact,
                              offices: contact?.offices?.filter(
                                (office) => office._id !== thisOffice._id
                              ),
                            });
                          }
                        }}
                        selectText="Select Offices"
                        editing={equipmentBrand?._id}
                        setValue={contact?.offices}
                        selectedValue={officesSelectedValue}
                        setSelectedValue={setOfficesSelectedValue}
                        // disabled={appointment && true}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="active"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Is Active
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        type="checkbox"
                        name="active"
                        id="active"
                        className="block shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        checked={contact?.active}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      {data && Object.keys(data).length > 0
                        ? "Update"
                        : "Create"}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddContactPopup;

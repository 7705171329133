import { API } from "./api";

export const getOffices = () => API.get("/offices");

export const createOffice = (newOffice) => {
  return new Promise((resolve, reject) => {
    API.post("/offices", newOffice)
      .then((res) => {
        console.log(res);
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const createOfficeInCompany = (companyId, office) => {
  return new Promise((resolve, reject) => {
    API.post(`/offices/${companyId}`, office)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const createOfficeInBrand = (brandId, office) => {
  return new Promise((resolve, reject) => {
    API.post(`/offices/brand/${brandId}`, office)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const updateOffice = (id, updatedOffice) => {
  return new Promise((resolve, reject) => {
    API.patch(`/offices/${id}`, updatedOffice)
      .then((res) => {
        console.log(res);
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};
export const deleteOffice = (id, companyId = null) =>
  companyId
    ? API.delete(`/offices/${companyId}/${id}`)
    : API.delete(`/offices/${id}`);

export const deleteOfficeInBrand = (id, brandId = null) =>
  brandId
    ? API.delete(`/offices/${id}/equipmentBrand/${brandId}`)
    : API.delete(`/offices/${id}`);

export const getOfficesByBounds = (companyId, bounds) =>
  API.post(`/offices/company/${companyId}/bounds`, { bounds });

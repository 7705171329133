import { Checkbox, FormControlLabel } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const CheckboxGroup = ({
  name,
  actionsAllowed,
  permissions,
  setPermissions,
  indexOfPermission,
}) => {
  const handlePermissionsChange = (value, indexOfPermission) => {
    let allPermissions = [...permissions];
    let permissionActionsAllowed =
      allPermissions[indexOfPermission].actionsAllowed;
    if (permissionActionsAllowed.includes(value)) {
      let index = permissionActionsAllowed.indexOf(value);
      permissionActionsAllowed.splice(index, 1);
    } else {
      permissionActionsAllowed.push(value);
    }
    allPermissions[indexOfPermission].actionsAllowed = permissionActionsAllowed;
    setPermissions(allPermissions);
  };

  const handlePermissionsGroupChange = (values, indexOfPermission) => {
    let allPermissions = [...permissions];
    let permissionActionsAllowed =
      allPermissions[indexOfPermission].actionsAllowed;
    if (
      values.every((value) => {
        return permissionActionsAllowed.includes(value);
      })
    ) {
      allPermissions[indexOfPermission].actionsAllowed = [];
      setPermissions(allPermissions);
    } else {
      allPermissions[indexOfPermission].actionsAllowed = values;
    }
    setPermissions(allPermissions);
  };

  return (
    <div>
      <FormControlLabel
        label={name}
        control={
          <Checkbox
            checked={actionsAllowed.every((value) => {
              return permissions[indexOfPermission].actionsAllowed.includes(
                value
              );
            })}
            indeterminate={
              !actionsAllowed.every((value) => {
                return permissions[indexOfPermission].actionsAllowed.includes(
                  value
                );
              }) && permissions[indexOfPermission].actionsAllowed.length > 0
            }
            onChange={() => {
              handlePermissionsGroupChange(actionsAllowed, indexOfPermission);
            }}
          />
        }
      />
      <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
        {actionsAllowed.map((actionAllowed, index) => (
          <FormControlLabel
            key={index}
            label={actionAllowed}
            control={
              <Checkbox
                size="small"
                checked={permissions[indexOfPermission].actionsAllowed.includes(
                  actionAllowed
                )}
                onChange={() => {
                  handlePermissionsChange(actionAllowed, indexOfPermission);
                }}
              />
            }
          />
        ))}
      </Box>
    </div>
  );
};

export default CheckboxGroup;

import * as api from "../api";
import * as type from "../types";
import { failure, success } from "../../utils/notification";

export const getEquipmentTypes = () => async (dispatch) => {
  try {
    const { data } = await api.getEquipmentTypes();
    dispatch({ type: type.GET_EQUIPMENTTYPES, payload: data });
  } catch (error) {
    failure(error.message);
  }
};

export const createEquipmentType = (equipmentType) => async (dispatch) => {
  try {
    const { data, status } = await api.createEquipmentType(equipmentType);
    if (status === 200) {
      dispatch({ type: type.CREATE_EQUIPMENTTYPE, payload: data });
      success("Saved");
    }
  } catch (error) {
    failure(error.message);
  }
};

export const updateEquipmentType = (id, equipmentType) => async (dispatch) => {
  try {
    const { data, status } = await api.updateEquipmentType(id, equipmentType);
    if (status === 200) {
      dispatch({ type: type.UPDATE_EQUIPMENTTYPE, payload: data });
      success("Updated");
    }
  } catch (error) {
    failure(error.message);
  }
};

export const deleteEquipmentType = (id) => async (dispatch) => {
  try {
    const { status } = await api.deleteEquipmentType(id);
    if (status === 200) {
      dispatch({ type: type.DELETE_EQUIPMENTTYPE, payload: id });
      success("Deleted");
    }
  } catch (error) {
    failure(error.message);
  }
};

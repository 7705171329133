import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useState, useEffect } from "react";
import { createZipCodesList } from "../../../../redux/api/zipCodes.api";
import Papa from "papaparse"; // Add this import for CSV parsing

const initialData = {
  listName: "",
  zipCodes: [],
};

const AddCampaignsZipCodesPopup = ({
  open,
  setOpen,
  companyId,
  setZipCodesList,
  editData,
  setEditData,
}) => {
  const [zipCodes, setZipCodes] = useState(editData?.zipCodes || []);
  const [inputValue, setInputValue] = useState("");
  const [data, setData] = useState(editData || initialData);

  useEffect(() => {
    if (editData) {
      setData(editData);
      setZipCodes(editData.zipCodes);
    } else {
      setData(initialData);
      setZipCodes([]);
    }
  }, [editData]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setData({ ...data, zipCodes: [...zipCodes, e.target.value] });
  };

  const handleListNameChange = (e) => {
    setData({ ...data, listName: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createZipCodesList(companyId, data);
      setOpen(false);
      setZipCodesList((prevList) => {
        if (editData) {
          return prevList.map((item) =>
            item._id === editData._id ? response.data : item
          );
        }
        return [...prevList, response.data];
      });
      setZipCodes([]);
      setEditData(null);
    } catch (error) {
      console.error("Failed to create zip codes list", error);
    }
  };

  const isValidZipCode = (zip) => {
    return /^\d{5}(-\d{4})?$/.test(zip);
  };

  const handleKeyDown = (e) => {
    if (e.key === " " || e.key === "Enter") {
      e.preventDefault();
      if (inputValue.trim() !== "" && isValidZipCode(inputValue.trim())) {
        const newZipCodes = [...zipCodes, inputValue.trim()];
        setZipCodes(newZipCodes);
        setInputValue("");
        e.target.textContent = "";
      }
    }
  };

  const removeZipCode = (index) => {
    const newZipCodes = zipCodes.filter((_, i) => i !== index);
    setZipCodes(newZipCodes);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          const newZipCodes = result.data.flat().filter(isValidZipCode);
          setZipCodes([...zipCodes, ...newZipCodes]);
          setData({ ...data, zipCodes: [...zipCodes, ...newZipCodes] });
        },
      });
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Create List
                    </Dialog.Title>
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-4 sm:py-3">
                    <div>
                      <label
                        htmlFor="listName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        List Name
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        type="text"
                        name="listName"
                        id="listName"
                        className="block w-full shadow-sm sm:text-sm focus:ring-green-500 focus:border-green-500 border-gray-300 rounded-md"
                        placeholder="Enter zip codes list name"
                        value={data?.listName}
                        onChange={handleListNameChange}
                      />
                    </div>
                  </div>
                  <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-4 sm:py-3">
                    <div>
                      <label
                        htmlFor="zipCodes"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Zip Codes
                      </label>
                    </div>
                    <div className="sm:col-span-2 border-gray-200 border-2 rounded-md h-32 overflow-y-auto p-2 relative">
                      <input
                        id="zipCodes"
                        name="zipCodes"
                        className="focus:ring-0 w-full min-h-[40px] appearance-none border-none p-0"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        style={{ position: "relative" }}
                      />
                      <div className="block w-full mt-2">
                        {zipCodes.map((zipCode, index) => (
                          <div
                            key={index}
                            className="inline-flex items-center bg-gray-200 rounded-md px-3 py-1 mr-2 mb-2"
                          >
                            <span className="text-sm">{zipCode}</span>
                            <button
                              type="button"
                              className="ml-2 text-gray-500 hover:text-gray-700"
                              onClick={() => removeZipCode(index)}
                            >
                              <XMarkIcon
                                className="h-4 w-4"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-4 sm:py-3">
                    <div>
                      <label
                        htmlFor="csvUpload"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Upload CSV
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        type="file"
                        id="csvUpload"
                        accept=".csv"
                        onChange={handleFileUpload}
                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                      />
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      {editData ? "Update List" : "Create List"}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddCampaignsZipCodesPopup;

import { API } from "./api";

export const createAppearanceSettings = (settings) =>
  API.post(`/appearanceSettings/`, settings);

export const updateAppearanceSettings = (id, settings) =>
  API.put(`/appearanceSettings/${id}`, settings);

export const getDefaultAppearanceSettings = () =>
  API.get(`/appearanceSettings/default`);

export const getAppearanceSettingsForCompany = (companyId) =>
  API.get(`/appearanceSettings/company/${companyId}`, {
    params: { settingsFor: "landingPages" },
  });

export const getAppearanceSettingsForEquipmentBrand = (brandId) =>
  API.get(`/appearanceSettings/equipmentBrand/${brandId}`, {
    params: { settingsFor: "landingPages" },
  });

export const deleteAppearanceSetting = (id) =>
  API.delete(`/appearanceSettings/${id}`);

import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CardFlex } from "../../../components/Card/Card.style";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { createUser, updateUser } from "../../../../redux/actions/user";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Select } from "@mui/material";
import { createRole, updateRole } from "../../../../redux/actions/role";
import { Box } from "@mui/system";
import CheckboxGroup from "../../../components/CheckboxGroup/CheckboxGroup";

const initialData = {
  name: "",
  permissions: [],
};

const initialPermissions = [
  {
    name: "optionSets",
    actionsAllowed: [],
  },
  {
    name: "services",
    actionsAllowed: [],
  },
  {
    name: "projects",
    actionsAllowed: [],
  },
  {
    name: "equipmetTypes",
    actionsAllowed: [],
  },
  {
    name: "equipmentBrands",
    actionsAllowed: [],
  },
  {
    name: "equipmentModels",
    actionsAllowed: [],
  },
  {
    name: "workTypes",
    actionsAllowed: [],
  },
  {
    name: "companies",
    actionsAllowed: [],
  },
  {
    name: "users",
    actionsAllowed: [],
  },
];

const Form = ({ id, data, handleClose, open }) => {
  const dispatch = useDispatch();
  const [permissions, setPermissions] = React.useState(initialPermissions);
  const equipmentBrands = useSelector((state) => state.equipmentBrandReducer);
  const companies = useSelector((state) => state.companyReducer);
  const [systems, setSystems] = useState([]);
  const [role, setRole] = useState(initialData);

  const handleChange = (e) => {
    setRole((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (id) {
      dispatch(updateRole(id, { ...role, permissions: permissions }));
    } else {
      dispatch(createRole({ ...role, permissions: permissions }));
    }
    clearData();
  };

  const clearData = () => {
    setRole(initialData);
    setPermissions(initialPermissions);
    handleClose();
  };

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      console.log(data);
      setRole(data);
      setPermissions(data.permissions);
    }
  }, [data]);

  // useEffect(() => {
  //   if (user.role === "brand") {
  //     setSystems(equipmentBrands);
  //   } else if (user.role === "company") {
  //     setSystems(companies);
  //   }
  // }, [user.role, companies, equipmentBrands]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{id ? "Update" : "Add"} Role</DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent>
          <CardFlex direction="column">
            <CardFlex>
              <TextField
                fullWidth
                value={role.name}
                required
                name="name"
                onChange={handleChange}
                size="small"
                label="Name of Role"
              />
            </CardFlex>
            <Typography variant="body">Priveliges</Typography>
            <CardFlex wrap="wrap">
              <CheckboxGroup
                name="Option Sets"
                actionsAllowed={["create", "read", "update", "delete"]}
                permissions={permissions}
                setPermissions={setPermissions}
                indexOfPermission={0}
              />
              <CheckboxGroup
                name="Services"
                actionsAllowed={["create", "read", "update", "delete"]}
                permissions={permissions}
                setPermissions={setPermissions}
                indexOfPermission={1}
              />
              <CheckboxGroup
                name="Projects"
                actionsAllowed={["create", "read", "update", "delete"]}
                permissions={permissions}
                setPermissions={setPermissions}
                indexOfPermission={2}
              />
              <CheckboxGroup
                name="Equipment Types"
                actionsAllowed={["create", "read", "update", "delete"]}
                permissions={permissions}
                setPermissions={setPermissions}
                indexOfPermission={3}
              />
              <CheckboxGroup
                name="Equipment Brands"
                actionsAllowed={["create", "read", "update", "delete"]}
                permissions={permissions}
                setPermissions={setPermissions}
                indexOfPermission={4}
              />
              <CheckboxGroup
                name="Equipment Models"
                actionsAllowed={["create", "read", "update", "delete"]}
                permissions={permissions}
                setPermissions={setPermissions}
                indexOfPermission={5}
              />
              <CheckboxGroup
                name="Project Types"
                actionsAllowed={["create", "read", "update", "delete"]}
                permissions={permissions}
                setPermissions={setPermissions}
                indexOfPermission={6}
              />
              <CheckboxGroup
                name="Companies"
                actionsAllowed={["create", "read", "update", "delete"]}
                permissions={permissions}
                setPermissions={setPermissions}
                indexOfPermission={7}
              />
              <CheckboxGroup
                name="Users"
                actionsAllowed={["create", "read", "update", "delete"]}
                permissions={permissions}
                setPermissions={setPermissions}
                indexOfPermission={8}
              />
            </CardFlex>
          </CardFlex>
        </DialogContent>
        <DialogActions>
          <Button onClick={clearData} variant="contained" autoFocus>
            cancel
          </Button>
          <Button variant="contained" type="submit">
            {id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Form;

import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    *{
        box-sizing: border-box;
        padding:0;
        margin:0;
    }
    body{
      background-color:#f9f9f9;
    }
    table{
      width:100%;
      border-collapse: collapse;
      font-size: 13px;
      font-weight: 500;
    }
    td,th{
      padding:1.3rem;
      border-bottom: 1px solid #DDD;
    }
    thead{
      background-color:${(props) => props.theme.main};
      color:#fff;
    }
    tbody tr:hover{
      background-color: rgb(47 175 77 / 8%);
    }
    body {
      font-family: 'Poppins', sans-serif;
      overflow-y: auto;
        ::-webkit-scrollbar {
          width: 0.5rem;
          height: 0.5rem;
        }
        ::-webkit-scrollbar-track {
          background: #e7e2e2;
        }
        ::-webkit-scrollbar-thumb {
          background: ${(props) => props.theme.main};
        }
        ::-webkit-scrollbar-thumb:hover {
          background-color: ${(props) => props.theme.darkMain};
        }
    }
`;

import React, { useEffect, useState } from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  deleteZipCodesList,
  getZipCodesListByCompany,
} from "../../../../redux/api/zipCodes.api";

const CampaignsZipCodes = ({
  open,
  setOpen,
  companyId,
  zipCodesList,
  setZipCodesList,
  setEditData,
  handleDeleteZipCodeList,
}) => {
  const [expandedRows, setExpandedRows] = useState({});

  const toggleExpandRow = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  useEffect(() => {
    const fetchZipCodesList = async () => {
      try {
        const response = await getZipCodesListByCompany(companyId);
        setZipCodesList(response?.data);
      } catch (error) {
        console.error("Failed to fetch zip codes list", error);
      }
    };
    fetchZipCodesList();
  }, [companyId, setZipCodesList]);

  return (
    <>
      <div className="bg-white rounded-lg shadow">
        <div className="border-b border-gray-200 rounded-t-lg bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Campaigns Zip Codes
              </h3>
            </div>
            <div className="flex items-center  mt-2 flex-shrink-0">
              <button
                type="button"
                onClick={() => setOpen(true)}
                className="relative inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                Add New
              </button>
            </div>
          </div>
        </div>
        <div className="mt-8 flow-root mx-6">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      {/* Check boxes to select rows */}
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      ></th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        List Name
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Zip Codes
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {zipCodesList.length === 0 ? (
                      <tr>
                        <td
                          colSpan="4"
                          className="py-4 pl-4 pr-3 text-2xl font-medium text-gray-900 sm:pl-6 text-center h-32"
                        >
                          Add your first list
                        </td>
                      </tr>
                    ) : (
                      zipCodesList.map((zipCode, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start"></td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                            {zipCode.listName}
                          </td>
                          <td className="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                            {expandedRows[index] ? (
                              <>
                                {zipCode.zipCodes.join(", ")}
                                <button
                                  type="button"
                                  onClick={() => toggleExpandRow(index)}
                                  className="text-blue-500 ml-2"
                                >
                                  Show less
                                </button>
                              </>
                            ) : (
                              <>
                                {zipCode.zipCodes.slice(0, 3).join(", ")}
                                {zipCode.zipCodes.length > 3 && (
                                  <button
                                    type="button"
                                    onClick={() => toggleExpandRow(index)}
                                    className="text-blue-500 ml-2"
                                  >
                                    ...
                                  </button>
                                )}
                              </>
                            )}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-start">
                            <button
                              type="button"
                              onClick={() =>
                                handleDeleteZipCodeList(zipCode._id)
                              }
                            >
                              <TrashIcon
                                className="h-5 w-5 text-gray-400 hover:text-gray-500"
                                aria-hidden="true"
                              />
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignsZipCodes;

import { API } from "./api";

export const createMapPage = (preset) => API.post("/mapPages/", preset);

export const updateLandingPage = (id, preset) =>
  API.put(`/mapPages/${id}`, preset);

export const updateEnableMapLocation = (id, enableMapLocation) =>
  API.put(`/mapPages/${id}/enableMapLocation`, { enableMapLocation });

export const updateShowProjectCost = (id, showProjectCost) =>
  API.put(`/mapPages/${id}/showProjectCost`, { showProjectCost });

export const updateAllowMapZoom = (id, allowMapZoom) =>
  API.put(`/mapPages/${id}/allowMapZoom`, { allowMapZoom });

export const updateAllowMapPan = (id, allowMapPan) =>
  API.put(`/mapPages/${id}/allowMapPan`, { allowMapPan });
export const toggleAdvertise = (id, advertise) =>
  API.put(`/mapPages/${id}/advertise`, { advertise });

export const getMapPagesByCompany = (id) => API.get(`/mapPages/company/${id}`);

export const getMapPagesByEquipmentBrand = (id) =>
  API.get(`/mapPages/equipmentBrand/${id}`);

export const getMapPageById = (filterId) => API.get(`/mapPages/${filterId}`);

export const deleteMapPage = (id) => API.delete(`/mapPages/${id}`);

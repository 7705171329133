import { failure, success } from "../../utils/notification";
import * as api from "../api";
import * as type from "../types";

export const getEquipmentBrands =
  (filter = null, sort = null, pagination = null) =>
  async (dispatch) => {
    try {
      const { data } = await api.getEquipmentBrands(filter, sort, pagination);
      dispatch({ type: type.GET_EQUIPMENTBRANDS, payload: data });
    } catch (error) {
      failure(error.message);
    }
  };

export const getConflictedEquipmentBrands = () => async () => {
  try {
    const { data } = await api.getConflictedEquipmentBrands();
    return data;
  } catch (error) {
    failure(error.message);
  }
};

export const createEquipmentBrand = (equipmentBrand) => async (dispatch) => {
  try {
    const { data, status } = await api.createEquipmentBrand(equipmentBrand);
    if (status === 200) {
      dispatch({ type: type.CREATE_EQUIPMENTBRAND, payload: data });
      success("Saved");
    }
  } catch (error) {
    failure(error.message);
  }
};

export const uploadEquipmentBrandsCsv =
  (equipmentBrands) => async (dispatch) => {
    try {
      const { data, status } = await api.uploadEquipmentBrandsCsv(
        equipmentBrands
      );
      // if (status === 200) {
      //   dispatch({ type: type.CREATE_EQUIPMENTBRAND, payload: data });
      //   success("Saved");
      // }
    } catch (error) {
      console.log(error);
      // failure(error.message);
    }
  };

export const updateEquipmentBrand =
  (id, equipmentBrand) => async (dispatch) => {
    try {
      const { data, status } = await api.updateEquipmentBrand(
        id,
        equipmentBrand
      );
      if (status === 200) {
        dispatch({ type: type.UPDATE_EQUIPMENTBRAND, payload: data });
        success("Updated");
      }
    } catch (error) {
      failure(error.message);
    }
  };

export const deleteEquipmentBrand = (id) => async (dispatch) => {
  try {
    const { status } = await api.deleteEquipmentBrand(id);
    if (status === 200) {
      dispatch({ type: type.DELETE_EQUIPMENTBRAND, payload: id });
      success("Deleted");
    }
  } catch (error) {
    failure(error.message);
  }
};

import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RotatingLines } from "react-loader-spinner";
import {
  getAppearanceSettingsForCompany,
  getAppearanceSettingsForEquipmentBrand,
} from "../../../../redux/api/appearanceSettings.api";

const LandingPagePopup = ({
  open,
  setOpen,
  equipmentBrand,
  mapCenter,
  mapZoom,
  createLandingPage,
}) => {
  const scaleClass = open ? "scale-100" : "scale-0";
  const [name, setName] = useState("");
  const [enableMapLocation, setEnableMapLocation] = useState(false);
  const [showProjectCost, setShowProjectCost] = useState(false);
  const [allowMapZoom, setAllowMapZoom] = useState(false);
  const [allowMapPan, setAllowMapPan] = useState(false);
  const [appearanceSettingsOptions, setAppearanceSettingsOptions] = useState(
    []
  );
  const [appearanceSettings, setAppearanceSettings] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (equipmentBrand?._id) {
      getAppearanceSettingsForEquipmentBrand(equipmentBrand?._id)
        .then((response) => {
          setAppearanceSettingsOptions(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [equipmentBrand?._id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    createLandingPage({
      name,
      userType: "brand",
      enableMapLocation,
      showProjectCost,
      allowMapZoom,
      allowMapPan,
      mapCenter,
      mapZoom,
      appearanceSettings,
    });
    setLoading(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="" onClose={setOpen}>
        <Transition.Child
          enter="ease-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100 transform scale-100"
          leaveTo="opacity-0 transform scale-0"
        >
          <div className="z-30 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-30">
          <div className="flex min-h-full items-center justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-500"
              enterFrom="opacity-0 transform scale-0"
              enterTo="opacity-100 transform scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 transform scale-100"
              leaveTo="opacity-0 transform scale-0"
            >
              <Dialog.Panel
                className={`ml-0 lg:ml-48 md:ml-48 w-4/6 lg:w-3/6 ${scaleClass} fixed mx-auto rounded-lg bg-white pt-5 pb-4 text-left shadow-xl transition-all  h-auto`}
              >
                <div className="absolute top-0 right-0 pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className=" rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className=" px-4 pb-4">
                  <div className=" sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Save Map Filter as Map Page
                    </Dialog.Title>
                  </div>
                </div>
                <div className="px-5 flex justify-between items-center col-span-12">
                  <h3 className="text-base ">Page Details</h3>
                </div>
                <form>
                  <div className=" h-80 overflow-y-scroll">
                    <div className="p-5">
                      <div className="items-center mt-3 gap-4 grid grid-cols-1 sm:grid-cols-12 lg:grid-cols-12 md:grid-cols-12">
                        <div className="lg:col-span-6 md:col-span-6 sm:col-span-12 ">
                          <div className="mx-1">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                              placeholder="Enter Name"
                            />
                          </div>
                        </div>
                        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 ">
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="enableMapLocation"
                                aria-describedby="enableMapLocation"
                                name="enableMapLocation"
                                type="checkbox"
                                value={enableMapLocation}
                                onChange={(e) =>
                                  setEnableMapLocation(e.target.checked)
                                }
                                className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label
                                htmlFor="enableMapLocation"
                                className="font-medium text-gray-900"
                              >
                                Enable location in page
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 ">
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="showProjectCost"
                                aria-describedby="showProjectCost"
                                name="showProjectCost"
                                type="checkbox"
                                value={showProjectCost}
                                onChange={(e) =>
                                  setShowProjectCost(e.target.checked)
                                }
                                checked={showProjectCost}
                                className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label
                                htmlFor="showProjectCost"
                                className="font-medium text-gray-900"
                              >
                                Show Project Cost
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 ">
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="allowMapZoom"
                                aria-describedby="allowMapZoom"
                                name="allowMapZoom"
                                type="checkbox"
                                value={allowMapZoom}
                                onChange={(e) =>
                                  setAllowMapZoom(e.target.checked)
                                }
                                checked={allowMapZoom}
                                className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label
                                htmlFor="allowMapZoom"
                                className="font-medium text-gray-900"
                              >
                                Allow map zoom
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 ">
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="allowMapPan"
                                aria-describedby="allowMapPan"
                                name="allowMapPan"
                                type="checkbox"
                                value={allowMapPan}
                                onChange={(e) =>
                                  setAllowMapPan(e.target.checked)
                                }
                                checked={allowMapPan}
                                className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label
                                htmlFor="allowMapPan"
                                className="font-medium text-gray-900"
                              >
                                Allow map Pan
                              </label>
                            </div>
                          </div>
                        </div>
                        {/* Select appearance settings */}
                        <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 ">
                          <div className="relative">
                            <select
                              id="appearanceSettings"
                              name="appearanceSettings"
                              value={appearanceSettings}
                              onChange={(e) =>
                                setAppearanceSettings(e.target.value)
                              }
                              className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-green-600 focus:border-green-600 sm:text-sm"
                            >
                              <option value="">
                                Select Appearance Settings
                              </option>
                              {appearanceSettingsOptions.map((setting) => (
                                <option key={setting._id} value={setting._id}>
                                  {setting.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-5 mt-3">
                    <button
                      type="button"
                      disabled={loading}
                      onClick={handleSubmit}
                      className={`w-full ${
                        loading
                          ? "bg-green-700 hover:bg-green-700 cursor-not-allowed"
                          : ""
                      } bg-green-600 hover:bg-green-700 text-white font-semibold py-2.5 px-3 rounded-md`}
                    >
                      {loading ? (
                        <div className="flex justify-center items-center">
                          <RotatingLines
                            strokeColor="white"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="24"
                            visible={true}
                          />
                        </div>
                      ) : (
                        "Save Map Page"
                      )}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default LandingPagePopup;

import * as api from "../api";
import * as type from "../types";
import { failure, success } from "../../utils/notification";

export const getProjects = () => async (dispatch) => {
  try {
    const { data } = await api.getProjects();
    dispatch({ type: type.GET_PROJECTS, payload: data });
  } catch (error) {
    failure(error.message);
  }
};

export const createProject = (service) => async (dispatch) => {
  try {
    const { data, status } = await api.createProject(service);
    if (status === 200) {
      dispatch({ type: type.CREATE_PROJECT, payload: data });
      dispatch(getProjects());
      success("Saved");
    }
  } catch (error) {
    failure(error.message);
  }
};

export const updateProject = (id, service) => async (dispatch) => {
  try {
    const { data, status } = await api.updateProject(id, service);
    if (status === 200) {
      dispatch({ type: type.UPDATE_PROJECT, payload: data });
      success("Updated");
    }
  } catch (error) {
    failure(error.message);
  }
};

export const deleteProject = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.deleteProject(id);
    if (status === 200) {
      dispatch({ type: type.DELETE_PROJECT, payload: data });
      success("Deleted");
    }
  } catch (error) {
    failure(error.message);
  }
};

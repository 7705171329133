import { API } from "./api";

export const getCompanies = (filter = null, sort = null, pagination = null) =>
  API.get("/companies", {
    params: {
      filter: filter,
      sort: sort,
      skip: pagination?.skip || null,
      limit: pagination?.limit || null,
    },
  });

export const getAllCompanies = () => API.get("/companies/all");

export const getCompany = (id) => API.get(`/companies/${id}`);

export const updateCompanyServices = (id, services) =>
  API.put(`/companies/${id}/updateServices/`, services);

export const updateCompanyWorkTypes = (id, serviceId, workTypes) =>
  API.put(`/companies/${id}/service/${serviceId}/updateWorkTypes/`, workTypes);

export const getServicesByCompanyId = (id) =>
  API.get(`/companies/${id}/services`);

export const getConflictedCompanies = () => API.get("/companies/conflicts");

export const createCompany = (newCompany) => API.post("/companies", newCompany);

export const updateCompany = (id, updatedCompany) =>
  API.patch(`/companies/${id}`, updatedCompany);

export const updateCompanyAdvertisment = (id, updatedCompany) =>
  API.patch(`/companies/${id}/advertise`, updatedCompany);

export const deleteCompany = (id) => API.delete(`/companies/${id}`);

export const mergeCompanies = (data) => API.post("/companies/merge", data);

export const markCompaniesAsDifferent = (companies) =>
  API.post(`/companies/markAsDifferent`, companies);

export const getCompaniesAssociatedToUser = (id) =>
  API.get(`/companies/user/${id}`);

export const updateCompanyServiceIcon = (data) =>
  API.put("/companies/updateServiceIcon", data);

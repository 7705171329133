import * as api from "../api";
import * as type from "../types";
import { failure, success } from "../../utils/notification";

export const getUtilities = () => async (dispatch) => {
  try {
    const { data } = await api.getUtilities();
    dispatch({ type: type.GET_UTILITIES, payload: data });
  } catch (error) {
    failure(error.message);
  }
};

export const createUtility = (utility) => async (dispatch) => {
  try {
    const { data, status } = await api.createUtility(utility);
    if (status === 200) {
      dispatch({ type: type.CREATE_UTILITY, payload: data });
      success("Saved");
    }
  } catch (error) {
    failure(error.message);
  }
};

export const updateUtility = (id, utility) => async (dispatch) => {
  try {
    const { data, status } = await api.updateUtility(id, utility);
    if (status === 200) {
      dispatch({ type: type.UPDATE_UTILITY, payload: data });
      success("Updated");
    }
  } catch (error) {
    failure(error.message);
  }
};

export const deleteUtility = (id) => async (dispatch) => {
  try {
    const { status } = await api.deleteUtility(id);
    dispatch({ type: type.DELETE_UTILITY, payload: id });
    if (status === 200) {
      dispatch({ type: type.DELETE_UTILITY, payload: id });
      success("Deleted");
    }
  } catch (error) {
    failure(error.message);
  }
};

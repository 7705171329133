import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CardFlex } from "../../../components/Card/Card.style";
import axios from "axios";
import {
  Button,
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Divider,
  Autocomplete,
} from "@mui/material";
import {
  createProject,
  updateProject,
} from "../../../../redux/actions/project";
import { useDispatch, useSelector } from "react-redux";
import ViewFields from "../../Services/Form/Field/ViewFields";
import { getCompanies } from "../../../../redux/actions/company";

const initialData = {
  company: "",
  workType: "",
  address: "",
  service: "",
};

let projectInitialData = {};

let serviceID = 0;

const Form = ({ id, data, handleClose, open }) => {
  const dispatch = useDispatch();
  const services = useSelector((state) => state.serviceReducer);
  const companies = useSelector((state) => state.companyReducer);
  const workTypes = useSelector((state) => state.workTypeReducer);
  const optionSets = useSelector((state) => state.optionSetReducer);

  const [project, setProject] = useState(initialData);
  const [fields, setFields] = useState([]);
  const [workTypesToRender, setWorkTypesToRender] = useState([]);
  const [companiesToRender, setCompaniesToRender] = useState([]);
  const [projectData, setProjectData] = useState(projectInitialData);

  useEffect(() => {
    if (open) dispatch(getCompanies());
  }, [dispatch]);

  const handleChange = async (e) => {
    let fields = [];
    let fieldsArr = [];
    if (e.target.name === "service") {
      // Setting dynamic fields for project of selected service
      let i = services.findIndex((service) => service._id === serviceID);
      fields.push(
        services[i]?.fields.map((field) => {
          if (field.type === "select") {
            fieldsArr.push(fetchModule(field.module, field?.filter));
            return {
              ...field,
              options: [],
            };
          } else {
            return field;
          }
        })
      );
      const allOptions = await Promise.all(fieldsArr);
      var optionsArrCount = 0;
      fields[0].map((field) => {
        if (field.type === "select") {
          field.options = allOptions[optionsArrCount];
          optionsArrCount++;
        }
        return field;
      });
      setFields(fields[0]);
      // Fields all set
      const selectedServiceMongoId = services[i]?._id;
      // Set work types to only those which are related to selected service
      let selectedWorkTypes = workTypes.filter(({ services }) =>
        services.some((id) => id === selectedServiceMongoId)
      );
      setWorkTypesToRender(selectedWorkTypes);
      // Work Types all set
      // Set companies to only those which are related to selected service
      let selectedCompanies = companies.filter(({ services }) =>
        services.some((id) => id === selectedServiceMongoId)
      );
      setCompaniesToRender(selectedCompanies);
    }
    setProject((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const fetchModule = async (module, filter = null) => {
    const optionSet = optionSets.find((os) => os.name === module);
    let options = [];
    if (optionSet === undefined) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/${module}/${filter}`
      );
      // const { data } = await axios.get(
      //   `https://api.pro-mapper.com/${module}s/${filter}`
      // );
      options = data;
    } else {
      options = optionSet.options;
    }
    return options;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (id) {
      dispatch(
        updateProject(id, {
          ...project,
          projectData,
          company: project?.company,
        })
      );
    } else {
      dispatch(
        createProject({
          ...project,
          projectData,
          company: project?.company?._id,
        })
      );
    }
    clearData();
  };

  const clearData = () => {
    setProject(initialData);
    setProjectData(projectInitialData);
    setFields([]);
    projectInitialData = {};
    handleClose();
  };

  const getOptionsForFields = async (i) => {
    let fields = [];
    let fieldsArr = [];
    fields.push(
      services[i]?.fields.map((field) => {
        if (field.type === "select") {
          fieldsArr.push(fetchModule(field.module, field?.filter));
          return {
            ...field,
            options: [],
          };
        } else {
          return field;
        }
      })
    );
    const allOptions = await Promise.all(fieldsArr);
    var optionsArrCount = 0;
    fields[0].map((field) => {
      if (field.type === "select") {
        field.options = allOptions[optionsArrCount];
        optionsArrCount++;
      }
      return field;
    });
    setFields(fields[0]);
  };

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      const { index, projectData, ...d } = data;
      const i = services.findIndex((service) => service._id === index);
      setProject(d);
      setFields(services[i]?.fields);
      setProjectData(projectData);

      // Work Types and companies to render
      const selectedServiceMongoId = services[i]?._id;
      // Set work types to only those which are related to selected service
      let selectedWorkTypes = workTypes.filter(({ services }) =>
        services.some((id) => id === selectedServiceMongoId)
      );
      setWorkTypesToRender(selectedWorkTypes);
      // Work Types all set
      // Set companies to only those which are related to selected service
      let selectedCompanies = companies.filter(({ services }) =>
        services.some((id) => id === selectedServiceMongoId)
      );
      selectedCompanies = selectedCompanies.map(function (obj) {
        return obj._id;
      });
      setCompaniesToRender(selectedCompanies);

      // Loading options for fields
      getOptionsForFields(i);
    }
  }, [data, services]);

  // useEffect(() => {
  //   projectInitialData = {};
  //   const i = services.findIndex((service) => service._id === serviceID);
  //   services[i]?.fields.map(({ name }) =>
  //     name ? (projectInitialData[[name]] = "") : ""
  //   );
  //   setProjectData(projectInitialData);
  // }, [project.service, services]);

  return (
    <Dialog open={open} onClose={clearData} fullWidth>
      <DialogTitle>{id ? "Update" : "Add"} Project</DialogTitle>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <DialogContent>
          <CardFlex direction="column">
            <FormControl size="small" fullWidth>
              <InputLabel>Service</InputLabel>
              <Select
                name="service"
                value={project.service}
                label="Service"
                onChange={handleChange}
                required
              >
                {services.map(({ _id, name }, id) => (
                  <MenuItem
                    key={_id}
                    value={_id}
                    onClick={() => (serviceID = _id)}
                  >
                    {name.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <CardFlex>
              <FormControl size="small" fullWidth>
                <InputLabel>Project Type</InputLabel>
                <Select
                  name="workType"
                  value={project.workType}
                  label="Project Type"
                  onChange={handleChange}
                  // required
                >
                  {workTypesToRender.map(({ _id, name }) => (
                    <MenuItem key={_id} value={name}>
                      {name?.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                value={project.address}
                name="address"
                onChange={handleChange}
                size="small"
                label="Address"
                required
                fullWidth
              />
            </CardFlex>
            <CardFlex>
              <Autocomplete
                options={companiesToRender}
                // getOptionLabel={(option) =>
                //   companies.find((company) => company._id === option)?.name
                // }
                getOptionLabel={(option) => {
                  return id
                    ? companies.find((company) => company._id === option)?.name
                    : option
                    ? option?.name
                    : "";
                }}
                value={project.company}
                onChange={(event, value) => {
                  console.log(value);
                  return setProject((prev) => ({
                    ...prev,
                    company: value,
                  }));
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField name="Company" {...params} label="Company" />
                )}
              />
            </CardFlex>
          </CardFlex>
        </DialogContent>
        <DialogContent>
          <Divider />
        </DialogContent>
        <DialogContent>
          <CardFlex direction="column">
            <ViewFields
              fields={fields}
              setFields={setFields}
              projectData={projectData}
              setProjectData={setProjectData}
            />
          </CardFlex>
        </DialogContent>
        <DialogActions>
          <Button onClick={clearData} variant="contained" autoFocus>
            cancel
          </Button>
          <Button variant="contained" type="submit">
            {id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Form;

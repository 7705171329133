import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Autocomplete, GoogleMap, MarkerF } from "@react-google-maps/api";
import { useGeolocated } from "react-geolocated";
import React, { Fragment, useEffect, useState } from "react";
import { createOffice, updateOffice } from "../../../../redux/api";
import { useDispatch } from "react-redux";
import { createOfficeInBrand } from "../../../../redux/api/office.api";
import { failure, success } from "../../../../utils/notification";

const AddOfficePopup = ({
  open,
  setOpen,
  equipmentBrand,
  setEquipmentBrand,
  data = {},
  editing,
}) => {
  const dispatch = useDispatch();
  const initialData = {
    name: "",
    phoneNo: "",
    address: "",
    coordinates: [],
    isHeadOffice: false,
  };
  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
  };
  const containerStyle = {
    width: "100%",
    height: "100%",
  };
  const [office, setOffice] = useState(initialData);
  const [autoComplete, setAutoComplete] = useState(null);

  const [map, setMap] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const [loading, setLoading] = useState(false);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
      userDecisionTimeout: 10000,
    });

  useEffect(() => {
    if (coords && isGeolocationEnabled) {
      const latlng = {
        lat: coords?.latitude,
        lng: coords?.longitude,
      };
      setMapCenter(latlng);
    }
  }, [coords, isGeolocationEnabled]);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) setOffice(data);
  }, [data]);

  const onLoadAutoComplete = (autoComplete) => {
    setAutoComplete(autoComplete);
  };

  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      const p = autoComplete.getPlace();
      const latlng = [
        p?.geometry?.location?.lng(),
        p?.geometry?.location?.lat(),
      ];
      setOffice({
        ...office,
        address: p.formatted_address,
        coordinates: latlng,
      });
      setMapCenter({
        lat: p?.geometry?.location?.lat(),
        lng: p?.geometry?.location?.lng(),
      });
      // infoWin?.close();
    }
  };

  const handleChange = (event) => {
    const { name, value, type } = event.target;
    if (type === "checkbox") {
      setOffice({ ...office, [name]: !office[name] });
      return;
    }
    setOffice({ ...office, [name]: value });
  };

  const handleSubmitOffice = async (event) => {
    event.preventDefault();
    if (data && Object.keys(data).length > 0) {
      const updatedOffice = await updateOffice(data?._id, office);
      setEquipmentBrand({
        ...equipmentBrand,
        offices: equipmentBrand?.offices?.map((o) =>
          o?._id === updatedOffice?._id ? updatedOffice : o
        ),
      });
    } else {
      if (equipmentBrand?._id) {
        await createOfficeInBrand(equipmentBrand?._id, office)
          .then((res) => {
            console.log("Success");
            setLoading(false);
            success("Office created successfully!");
            setEquipmentBrand({
              ...equipmentBrand,
              offices: [...equipmentBrand?.offices, res],
            });
          })
          .catch((err) => {
            failure(err?.response?.data?.message);
            setLoading(false);
          });
        setLoading(false);
      } else {
        await createOffice(office)
          .then((res) => {
            setLoading(false);
            success("Office created successfully!");
            setEquipmentBrand({
              ...equipmentBrand,
              offices: [...equipmentBrand?.offices, res],
            });
          })
          .catch((err) => {
            failure(err?.response?.data?.message);
            setLoading(false);
          });
        setLoading(false);
      }
    }
    setOffice(initialData);
    setOpen(false);
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Create Office
                    </Dialog.Title>
                  </div>
                </div>
                <form onSubmit={handleSubmitOffice}>
                  <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                    <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                      <div>
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Name
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                          value={office?.name}
                          onChange={handleChange}
                          placeholder="Name"
                        />
                      </div>

                      <div>
                        <label
                          htmlFor="phoneNo"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Phone Number
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <input
                          type="text"
                          name="phoneNo"
                          id="phoneNo"
                          className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                          value={office?.phoneNo}
                          onChange={handleChange}
                          placeholder="Phone Number"
                        />
                      </div>

                      <div>
                        <label
                          htmlFor="address"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Address
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <Autocomplete
                          onLoad={onLoadAutoComplete}
                          onPlaceChanged={onPlaceChanged}
                        >
                          <input
                            type="text"
                            defaultValue={office?.address}
                            onChange={(e) =>
                              setOffice({
                                ...office,
                                address: e.target.value,
                              })
                            }
                            placeholder="Address"
                            className="bg-gray-50 block w-full shadow-sm focus:ring-primary focus:border-primary sm:text-sm border-gray-300 rounded-md"
                            required
                          />
                        </Autocomplete>
                      </div>

                      <div>
                        <label
                          htmlFor="isHeadOffice"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Is Head Office
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <input
                          type="checkbox"
                          name="isHeadOffice"
                          id="isHeadOffice"
                          className="block shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                          checked={office?.isHeadOffice}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="h-full">
                      {/* Show Google Map here */}
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={mapCenter}
                        onLoad={(map) => setMap(map)}
                        options={mapOptions}
                        zoom={14}
                      >
                        <MarkerF
                          position={{
                            lat: office?.coordinates[1],
                            lng: office?.coordinates[0],
                          }}
                        />
                      </GoogleMap>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      {data && Object.keys(data).length > 0
                        ? "Update"
                        : "Create"}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddOfficePopup;
